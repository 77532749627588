import React, { useEffect, useRef, useState } from 'react';
import { useBase } from '../../../Layout/baseContext/BaseContext';
import UITable from './UITable';
import { Tour } from 'antd';

const UIMapping = () => {
  const { uiMappingData } = useBase();
  const headers = uiMappingData && Object.keys(uiMappingData[0]);
  const editItemRef = useRef(null);
  const editLocationRef = useRef(null);
  const editCustomerRef = useRef(null);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    // Check if showGuide is true in localStorage
    const showGuide = JSON.parse(localStorage.getItem('showGuide'));
    if (showGuide) {
        setIsTourOpen(true); // Start the tour if true
    }
}, []);
  // Define configurations for each table
  const tables = [
    { tableName: "Items", tableData:headers && headers.filter(header => header.startsWith('I')) },
    { tableName: "Locations", tableData: headers && headers.filter(header => header.startsWith('L')) },
    { tableName: "Customers", tableData: headers && headers.filter(header => header.startsWith('C')) },
  ];
  const tourSteps = [
    {
        title: "Edit item mappings",
        description: "Edit the mapping of your items",
        target: () => editItemRef.current,
    },
    {
        title: "Edit location mappings",
        description: "Edit the mapping of your locations",
        target: () => editLocationRef.current,
    },
    {
        title: "Edit customer Mappings",
        description: "Edit the mapping of your customers",
        target: () => editCustomerRef.current,
    },
    {
      title: "Select Upload data tab",
      description: "Go to Upload data and upload you master and sales data"
  }
];

  return (
    <div className="flex flex-col h-full w-full text-xs text-bodyTextBlue overflow-y-scroll">
        <Tour
                open={isTourOpen}
                onClose={() => setIsTourOpen(false)}
                steps={tourSteps}
                locale={{ close: "Close", }} // Change "Finish" to "Close"
            /> 
      <div className="grid grid-cols-3 gap-4 w-full">
        {tables && tables.length !== 0 && tables.map(({ tableName, tableData }) => (
          <UITable key={tableName}
          editRef={tableName === 'Items' ? editItemRef : tableName === 'Locations' ? editLocationRef : editCustomerRef}
          setIsTourOpen={setIsTourOpen}
           tableName={tableName}
            tableData={tableData} />
        ))}
      </div>
    </div>
  );
};

export default UIMapping;
