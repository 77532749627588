import React, { useState } from 'react'
import UploadTab from './components/uploadData/UploadTab'
import { Layout } from 'antd'
import HeaderComponent from '../Layout/Header/HeaderComponent'
import { Content } from 'rsuite'
import { useUpload } from '../Layout/baseContext/uploadContext/UploadContext'
import ParamsTab from './components/toolParams/ParamsTab'
import UIMapping from './components/uimapping/UIMapping'
import DemandScan from './components/demandscan/DemandScan'


const DataSection = ({
  locationFilter,
  productFilter,
  customerFilter,
  uiMappingData,
  filterString,
  setFilterString,
}) => {
  const { selectedArea, setSelectedArea } = useUpload()



  const getDemandReport = () => {
    // Check if "demandReport" exists in localStorage
    const demandReport = localStorage.getItem("demandReport");

    // If it exists, parse it (assuming it's JSON) or return as is
    if (demandReport) {
      try {
        return JSON.parse(demandReport); // Parse if it's a JSON string
      } catch (error) {
        console.error("Error parsing demandReport:", error);
        return demandReport; // Return as string if parsing fails
      }
    }

    // If not present, return null or a default value
    return null;
  };

  // Usage example:
  const demandReport = getDemandReport();
  if (demandReport) {
    // console.log("Demand Report:", demandReport);
  } else {
    // console.log("No demand report found in localStorage.");
  }

  return (
    <Layout className="h-full w-full overflow-hidden" style={{ background: "#F9F9FC" }}>
      <header
        className="bg-[#f9f9fc] w-full h-full z-30"
      >
        {
          // Object.keys(locationFilter).length > 0 && Object.keys(productFilter).length > 0 && Object.keys(customerFilter).length > 0
          //   ?
          <HeaderComponent selectedArea={selectedArea} uiMappingData={uiMappingData} filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} setSelectedArea={setSelectedArea} />
          // : null
        }

      </header>
      <Content
        className="content w-full h-full "
      >

        {
          selectedArea === "uploadTab" &&

          <UploadTab />
        }
        {
          selectedArea === "parameter" &&

          <ParamsTab />
        }
        {
          selectedArea === "uiMapping" &&

          <UIMapping />
        }
        {
          selectedArea === "demandScan" && demandReport !== null &&
          <DemandScan demandReport={demandReport} />
        }
      </Content>
    </Layout>
  )
}

export default DataSection