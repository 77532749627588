import React, { useState, useEffect } from 'react';
import { Input, Select } from 'antd';

const { Option } = Select;

const DFUForm = ({ formValues, setFormValues }) => {
    const [eventList, setEventList] = useState([]);
    const [classifications, setClassifications] = useState([]);

     // Load eventList from localStorage
  useEffect(() => {
    const storedEventList = localStorage.getItem('eventList');
    if (storedEventList) {
      setEventList(JSON.parse(storedEventList));
    }
  }, []);



  // Handle input changes
  const handleChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // Update classifications when eventCategory changes
    if (field === 'eventCategory') {
      const selectedCategory = eventList.find(
        (event) => event.EVENT_CATEGORY === value
      );
      setClassifications(selectedCategory ? selectedCategory.CLASSIFICATION : []);
      // Reset classification value if the category changes
      setFormValues((prevState) => ({
        ...prevState,
        classification: '',
      }));
    }
  };

    return (
        <div className='grid grid-cols-2 gap-2 text-bodyTextBlue'>
            {/* Changed Quantity Input (numeric) */}
            <div className="flex flex-col">
                <label className='mb-1'>Changed Quantity*</label>
                <Input
                    placeholder="Changed Quantity"
                    type="number"
                    value={formValues.changedQuantity}
                    onChange={(e) => handleChange('changedQuantity', e.target.value)}
                    required
                />
            </div>

            {/* Event Category Dropdown */}
            <div className="flex flex-col">
                <label className='mb-1'>Select category</label>
                <Select
                    placeholder="Select category"
                    value={formValues.eventCategory}
                    onChange={(value) => handleChange('eventCategory', value)}
                >
                    {eventList
                        .sort((a, b) => (a.EVENT_CATEGORY === "" ? -1 : b.EVENT_CATEGORY === "" ? 1 : 0))
                        .map((event) => (
                            <Option key={event.EVENT_CATEGORY || "empty"} value={event.EVENT_CATEGORY}>
                                {event.EVENT_CATEGORY || "None"}
                            </Option>
                        ))}
                </Select>
            </div>

            {/* Classification Input */}
            <div className="flex flex-col">
                <label className='mb-1'>Select classification</label>
                <Select
                    placeholder="Select classification"
                    value={formValues.classification}
                    onChange={(value) => handleChange('classification', value)}
                >
                    {classifications.map((classification) => (
                        <Option key={classification} value={classification}>
                            {classification}
                        </Option>
                    ))}
                </Select>
            </div>

            {/* Event Name Input */}
            <div className="flex flex-col">
                <label className='mb-1'>Event Name</label>
                <Input
                    placeholder="Event Name"
                    value={formValues.eventName}
                    onChange={(e) => handleChange('eventName', e.target.value)}
                />
            </div>
        </div>
    );
};

export default DFUForm;
