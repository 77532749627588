import React from 'react';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2'; // Use Line instead of Bar
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import { useBase } from '../../Layout/baseContext/BaseContext';

const PostGraph = () => {
  const { currentPostGraph, selectedPost } = usePost();
  const { tdateVar } = useBase(); // tdateVar has the threshold date

  // Convert tdateVar to a Date object
  const thresholdDate = new Date(tdateVar);
  const oneYearLater = new Date(thresholdDate);
  oneYearLater.setFullYear(thresholdDate.getFullYear() + 1);

  // Filter data based on tdateVar and one year range
  const filteredData =
    currentPostGraph &&
    currentPostGraph.filter((entry) => {
      const entryDate = new Date(entry[selectedPost.TIME_LEVEL]);
      return entryDate >= thresholdDate && entryDate <= oneYearLater;
    });

  // Prepare data for the graph
  const labels = filteredData && filteredData.map((entry) => entry[selectedPost.TIME_LEVEL]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Engine',
        data: filteredData && filteredData.map((entry) => entry.FQTY_ENGINE || 0),
        borderColor: '#4489e2',
        backgroundColor: '#4489e2', // Slightly transparent fill
        fill: false, // Fill the area under the line
        tension: 0.4,
      },
      {
        label: 'Planner',
        data: filteredData && filteredData.map((entry) => entry.FQTY_USER || 0),
        borderColor: '#ed7d31',
        backgroundColor: '#ed7d31',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Budget',
        data: filteredData && filteredData.map((entry) => entry.FQTY_BUDGET || 0),
        borderColor: '#34a853',
        backgroundColor: '#34a853',
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Display the legend
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 1,
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return Intl.NumberFormat('en-US', {
              notation: 'compact',
              maximumFractionDigits: 1,
            }).format(value);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col bg-white items-center w-full h-[200px]">
      <div className="flex flex-row text-xs items-center justify-end w-full -mb-1 ">
        <div className="flex items-center gap-4">
          <div className="flex flex-row items-center gap-1   justify-between">
            <div className="h-3 w-9 bg-[#4489e2] rounded-full" />
            <p>Engine</p>
          </div>

          <div className="flex flex-row items-center gap-1 justify-between">
            <div className="h-3 w-9 bg-[#ed7d31] rounded-full" />
            <p>Planner</p>
          </div>

          <div className="flex flex-row items-center gap-1 justify-between">
            <div className="h-3 w-9 bg-[#34a853] rounded-full" />
            <p>Budget</p>
          </div>
        </div>
      </div>
      <Line data={data} options={options} /> {/* Use Line component */}
    </div>
  );
};

export default PostGraph;


