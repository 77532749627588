import React from 'react';
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext';
import { useBase } from '../../../Layout/baseContext/BaseContext';

const DemandTable = () => {
    const { tableData, tableHeaders,tableHeadValue, activeDemandTab } = useUpload();
    const { uiMappingData } = useBase();

    return (
        <div className='w-full flex flex-col h-full '>
            {tableData.length > 0 ? (
                <div className='mt-4 flex flex-col h-full w-full '>
                    {/* Header */}
                    <header className='flex w-[calc(100%-5px)]'>
                        <p className='px-4 py-2 bg-slate-100 border w-full font-semibold text-sm overflow-hidden'>
                            {activeDemandTab === 'Item segment' && uiMappingData[0]['ITEM']}
                            {activeDemandTab === 'Location segment' && uiMappingData[0]['LOCATION']}
                            {activeDemandTab === 'Customer segment' && uiMappingData[0]['CUSTOMER']}
                        </p>
                        {tableHeadValue.map((key) => (
                            <p key={key} className='px-4 py-2 bg-slate-100 border w-full font-semibold text-sm overflow-hidden'>
                                {key}
                            </p>
                        ))}
                    </header>

                    {/* Table Rows */}
                    <div className='relative h-[70vh] w-full'>
                        <div className='absolute overflow-y-scroll scroll h-full w-full border'>
                            <div className='flex flex-col h-full w-full'>
                                {tableData.map((data, index) => {
                                    return (
                                        <div key={index} className='flex w-full'>
                                            <p className='px-4 py-2 bg-white border w-full overflow-hidden'>
                                                {activeDemandTab === 'Item segment' && data['ITEM']}
                                                {activeDemandTab === 'Location segment' && data['LOCATION']}
                                                {activeDemandTab === 'Customer segment' && data['CUSTOMER']}
                                            </p>
                                            {tableHeaders.map((header) => {
                                                return (
                                                    <p key={header} className='px-4 py-2 bg-white border w-full overflow-hidden'>
                                                        {data[header] || ''}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>No data available for the selected label</p>
            )}
        </div>
    );
};

export default DemandTable;
