import React from 'react'
import PostList from '../components/PostList'
import SelectedPost from '../components/SelectedPost'
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import Discussion from './Discussion';
import { TailSpin } from 'react-loader-spinner';
import DiscussionThread from '../components/DiscussionThread';


const Posts = () => {
  // const [discussion, setDiscussion] = useState(false);
  const { loading, discussion, setDiscussion, selectedPost } = usePost();


  return (
    <div className=' h-full w-full bg-background'>
      <div className='flex w-full h-full'>
        {
          loading ?
            (<div className='h-full w-full flex justify-center items-center'><TailSpin
              visible={true}
              height="40"
              width="40"
              color="#2B3674"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            /></div>)
            :
            (<div className="w-full h-full overflow-x-hidden ">
              {
                false ?
                  (
                    <div className=' pb-[2px] h-full '>
                      {/* {
                    loading ? 
                    (<div>loading</div>)
                    :
                    ( <Discussion setDiscussion={setDiscussion} />)
                  } */}
                      <SelectedPost />
                    </div>
                  )
                  :
                  (
                    <div className={`${discussion ? "" : ""} w-full flex h-full `}>

                      <div className={`${discussion ? "w-[0rem]" : " w-[22%] block"} transition-all duration-200 ease-in-out  pb-[2px] shrink-0 h-full `}>
                        <PostList />
                      </div>
                      <div className={`w-[calc(100%-22%)] transition-all duration-200 ease-in-out pl-2  flex flex-col pb-[2px] h-full`}>
                        
                        {
                          selectedPost && <SelectedPost />

                        }
                      </div>
                      <div className={`${discussion ? " w-[22%] block pl-2" : " w-[0rem]"} transition-all duration-200 ease-in-out  pb-[2px] shrink-0 h-full `}>
                        <DiscussionThread postDiscussion={selectedPost} />
                      </div>
                    </div>
                  )
              }

            </div>)
        }

      </div>
    </div>

  )
}

export default Posts