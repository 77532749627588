import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../../../../api';
import { useBase } from '../BaseContext';

const PortfolioContext = createContext();

const LOCAL_STORAGE_KEY = "portfolioData";
const TODAY_DATE = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD

export const PortfolioProvider = ({ children, locationFilters, productFilters, customerFilters }) => {

  const [data, setData] = useState(null);
  const [products, setProducts] = useState(productFilters);
  const [customers, setCustomers] = useState(customerFilters);
  const [locations, setLocations] = useState(locationFilters);
  const [loading, setLoading] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const [selectedChip, setSelectedChip] = useState("products");
  const [disContinueForm, setDisContinueForm] = useState(false);
  const [oldTransitionForm, setOldTransitionForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState("new");
  const {selectedArea} = useBase()

  const fetchData = async () => {
    try {
      setLoading(true);
  
      // Make API calls concurrently using Promise.all
      const [productResponse, customerResponse, locationResponse] = await Promise.all([
        api.get("/api/products/?fields=ITEM,SOURCE"),
        api.get("/api/customers/?fields=CUSTOMER,SOURCE"),
        api.get("/api/locations/?fields=LOCATION,SOURCE"),
      ]);
  
      const productData = productResponse.data;
      const customerData = customerResponse.data;
      const locationData = locationResponse.data;
  
      // Store fetched data in localStorage with the current date
      const portfolioData = {
        date: TODAY_DATE,
        products: productData,
        customers: customerData,
        locations: locationData,
      };
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(portfolioData));
  
      // Update state
      setProducts(productData);
      setCustomers(customerData);
      setLocations(locationData);
      setData(productData); // Set default data to products
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  

  useEffect(() => {

    if (selectedArea === 'portfolio') {

      // Check for stored data in localStorage
      const storedData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

      if (storedData && storedData.date === TODAY_DATE) {
        // If data is valid for today, use it
        setProducts(storedData.products);
        setCustomers(storedData.customers);
        setLocations(storedData.locations);
        setData(storedData.products); // Default to products
      } else {
        // Fetch fresh data if no valid data in localStorage
        fetchData();
      }
    }
  }, [locationFilters, productFilters, customerFilters,selectedArea]);

  const dataChanger = (val) => {
    if (val === 'products') {
      setData(products);
      setSelectedListItem(null);
    } else if (val === 'customers') {
      setData(customers);
      setSelectedListItem(null);
    } else if (val === 'locations') {
      setData(locations);
      setSelectedListItem(null);
    }
  };

  return (
    <PortfolioContext.Provider value={{
      loading,
      data,
      selectedChip,
      setSelectedChip,
      selectedTab,
      setSelectedTab,
      dataChanger,
      selectedListItem,
      setSelectedListItem,
      products,
      customers,
      locations,
      disContinueForm,
      setDisContinueForm,
      oldTransitionForm,
      setOldTransitionForm,
      locationFilters,
      productFilters,
      customerFilters
    }}>
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolio = () => useContext(PortfolioContext);
