import React from 'react'
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext'
import { useBase } from '../Layout/baseContext/BaseContext'

const TopRightCorner = ({
    rowIndex, colIndex,isSplit, qtyType,postID,fdate
}) => {
    const {setContextMenu,setShowAddComment} = usePlan()

    const {getCellComments} = useBase()

    const topRightClick = () => {
        setContextMenu({ visible: true, rowIndex, colIndex,isSplit, qtyType })
        setShowAddComment(true)
        getCellComments(postID,fdate)
    }

  return (
    <div
    onClick={()=>topRightClick()}
     className='absolute top-0 right-0 w-1 h-1 triangle-topright transition-all duration-300 ease-in-out cursor-pointer'>
    </div>
  )
}

export default TopRightCorner