import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import api from "../../../../api";
import { useBase } from "../BaseContext";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const [activities, setActivities] = useState([]);
    const [transitionProfiles, setTransitionProfiles] = useState([]);
    const [discontinuedProfiles, setDiscontinuedProfiles] = useState([]);
    const [newProfiles, setNewProfiles] = useState([]);
    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState([]);
    const [sortedActivities, setSortedActivities] = useState([]); // New state for sorted activities
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSeeAll, setShowSeeAll] = useState("");
    const {selectedMenu} = useBase()

    const fetchActivities = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem("access");
            const headers = {
                Authorization: `Bearer ${token}`
            };
    
            // Fetch data from all APIs
            const [
                activitiesResponse,
                transitionProfilesResponse,
                discontinuedProfilesResponse,
                newProfilesResponse,
                postsResponse,
                commentsResponse
            ] = await Promise.all([
                api.get('/api/activities/', { headers }),
                api.get('/api/u1_transition_profile/', { headers }),
                api.get('/api/u1_discontinued_profile/', { headers }),
                api.get('/api/u1_new_profile/', { headers }),
                api.get('/api/posts/', { headers }),
                api.get('/api/comments/', { headers })
            ]);
    
            setActivities(activitiesResponse.data);
            setTransitionProfiles(transitionProfilesResponse.data);
            setDiscontinuedProfiles(discontinuedProfilesResponse.data);
            setNewProfiles(newProfilesResponse.data);
            setPosts(postsResponse.data);
            setComments(commentsResponse.data);
    
            // Log the activities response for debugging
    
            // Create a lookup map for activities data
            const activitiesLookup = activitiesResponse.data.reduce((acc, activity) => {
                acc[activity.ACTIVITY_ID] = {
                    updatedAt: activity.ACTIVITY_UPDATED_AT, // Adjust field names if needed
                    activityType: activity.ACTIVITY_TYPE // Adjust field names if needed
                };
                return acc;
            }, {});
    
            // Extract activity IDs from the activities response
            const activityIds = new Set(activitiesResponse.data.map(activity => activity.ACTIVITY_ID));
    
            // Collect activities from other responses that match the activity IDs
            let activitiesFromOtherResponses = [];
            const allResponses = [
                transitionProfilesResponse.data,
                discontinuedProfilesResponse.data,
                newProfilesResponse.data,
                postsResponse.data,
                commentsResponse.data
            ];
    
            allResponses.forEach(response => {
                response.forEach(item => {
                    if (activityIds.has(item.ACTIVITY_ID)) {
                        const activityInfo = activitiesLookup[item.ACTIVITY_ID];
                        if (activityInfo) {
                            activitiesFromOtherResponses.push({
                                ...item,
                                updatedAt: activityInfo.updatedAt || 'N/A',
                                activityType: activityInfo.activityType || 'N/A'
                            });
                        }
                    }
                });
            });
    
            // Sort activities by 'updatedAt' in descending order
            activitiesFromOtherResponses.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            // Set the sorted activities
            setSortedActivities(activitiesFromOtherResponses);
    
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(selectedMenu === 'dashboard'){
            fetchActivities();
        }
    }, [selectedMenu]);

    return (
        <DashboardContext.Provider value={{
             activities, transitionProfiles,
              discontinuedProfiles, newProfiles,
               posts, comments, sortedActivities,
                loading, error, fetchActivities,
                showSeeAll, setShowSeeAll
                 }}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboard = () => useContext(DashboardContext);
