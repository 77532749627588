import { Button, message } from "antd";
import React, { useState } from "react";
import { useBase } from "./Layout/baseContext/BaseContext";
import { usePlan } from "./Layout/baseContext/planningContext/PlanningContext";

const Overlay = ({

  onClose, fields, setFields,
  locationFilters,
  productFilters,
  customerFilters,
  uiMappingData
}) => {

  const { selectedMenu, uncheckAll, setNext, setPage, setIsFetchingPlan, expandCheckBoxListArray, setExpandCheckBoxListArray, fullExpandCheckBoxListArray } = useBase();
  const { showAverage, showSum, setShowAverage, setShowSum } = usePlan();



  const locationLength = Object.keys(locationFilters[0]).length
  const customerLength = Object.keys(customerFilters[0]).length
  const productLength = Object.keys(productFilters[0]).length
  const [localFields, setLocalFields] = useState(fields)
  const productList = [];
  const customerList = [];
  const locationList = [];

  for (let i = 0; i < productLength; i++) {
    productList.push(Object.keys(productFilters[0])[i])
  }
  for (let i = 0; i < customerLength; i++) {
    customerList.push(Object.keys(customerFilters[0])[i])
  }
  for (let i = 0; i < locationLength; i++) {
    locationList.push(Object.keys(locationFilters[0])[i])
  }




  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setLocalFields((prevState) => [...prevState, value]);

    } else {
      setLocalFields(localFields.filter((item) => item !== value));
    }
  };

  const handleExpandCheckboxChange = (item) => {
    setExpandCheckBoxListArray((prev) => {
      if (prev.includes(item)) {
        // Remove item if it's already in the array
        return prev.filter((i) => i !== item);
      } else {
        // Add item if it's not in the array
        return [...prev, item];
      }
    });
  };


  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center  bg-opacity-50 z-50">
      <div className={`bg-slate-200/70  ${selectedMenu === 'details' ? "w-3/5" : "w-2/5"} backdrop-blur-xl p-6  border-2 border-slate-300 rounded-lg shadow-[rgba(203,213,225)_0px_0px_30px]`}>
        <h2 className="text-xl font-bold mb-4">Edit Options</h2>
        <div className={`grid  ${selectedMenu === 'details' ? "grid-cols-4" : "grid-cols-2"} gap-4  h-[300px]`}>
          <div className=" h-full flex flex-col   w-full ">
            <h3 className="font-semibold mb-2 text-base pl-1">Products</h3>
            <div className="relative h-full w-full ">
              <div className="absolute flex flex-col h-full w-full ">
                <div className="scroll overflow-x-hidden  overflow-y-scroll flex pr-2 flex-col h-full w-full">
                  {
                    productList.map((item, index) => (
                      <div key={index} className="w-full">
                        {/* Add a line separator before the first ISEGMENT */}
                        {item.startsWith("ISEGMENT") && !productList[index - 1]?.startsWith("ISEGMENT") && (
                          <hr className="my-2 border-t border-gray-300" />
                        )}
                        <div className="flex flex-row items-center hover:bg-slate-50 rounded-md">
                          <input
                            id={item}
                            type="checkbox"
                            value={item}
                            checked={localFields.includes(item)}
                            onChange={handleCheckboxChange}
                            className="form-checkbox text-indigo-600"
                          />
                          <label htmlFor={item} className="inline-flex items-center cursor-pointer w-full py-1 rounded-md">
                            <span className="ml-1">{uiMappingData[0][item]}</span>
                          </label>
                        </div>
                      </div>
                    ))
                  }

                </div>
              </div>
            </div>
          </div>
          <div className=" h-full flex flex-col   w-full">
            <h3 className="font-semibold mb-2 text-base pl-1">Location</h3>
            <div className="relative h-full w-full ">
              <div className="absolute flex flex-col h-full w-full ">
                <div className="scroll overflow-x-hidden  overflow-y-scroll pr-2 flex flex-col h-full w-full">
                  {
                    locationList.map((item, index) => (
                      <div key={index} className="w-full">
                        {/* Add a line separator before the first ISEGMENT */}
                        {item.startsWith("LSEGMENT") && !locationList[index - 1]?.startsWith("LSEGMENT") && (
                          <hr className="my-2 border-t border-gray-300" />
                        )}
                        <div key={index} className="w-full flex flex-row items-center hover:bg-slate-50  rounded-md">
                          <input
                            id={item}
                            type="checkbox"
                            value={item}
                            checked={localFields.includes(item)}
                            onChange={handleCheckboxChange}
                            className="form-checkbox text-indigo-600"
                          />
                          <label htmlFor={item} className="inline-flex items-center cursor-pointer  w-full  py-1 rounded-md">
                            <span className="ml-1">{uiMappingData[0][item]}</span>
                          </label>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          {
            selectedMenu === 'details' &&


            <div className=" h-full flex flex-col   w-full">
              <h3 className="font-semibold mb-2 text-base pl-1">Customer</h3>
              <div className="relative h-full w-full ">
                <div className="absolute flex flex-col h-full w-full ">
                  <div className="scroll overflow-x-hidden pr-2 overflow-y-scroll flex flex-col h-full w-full">
                    {
                      customerList.map((item, index) => (
                        <div key={index} className="w-full">
                          {/* Add a line separator before the first ISEGMENT */}
                          {item.startsWith("CSEGMENT") && !customerList[index - 1]?.startsWith("CSEGMENT") && (
                            <hr className="my-2 border-t border-gray-300" />
                          )}
                          <div key={index} className="w-full flex flex-row items-center hover:bg-slate-50  rounded-md">
                            <input
                              id={item}
                              type="checkbox"
                              value={item}
                              checked={localFields.includes(item)}
                              onChange={handleCheckboxChange}
                              className="form-checkbox text-indigo-600"
                            />
                            <label htmlFor={item} className="inline-flex items-center cursor-pointer  w-full py-1 rounded-md">
                              <span className="ml-1">{uiMappingData[0][item]}</span>
                            </label>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          <div className=" h-full flex flex-col   w-full">
            <h3 className="font-semibold mb-2 text-base pl-1">Measures</h3>
            <div className="relative h-full w-full ">
              <div className="absolute flex flex-col h-full w-full ">
                <div className="scroll overflow-x-hidden pr-2 overflow-y-scroll flex flex-col h-full w-full">

                  <div className="w-full flex flex-row items-center hover:bg-slate-50  rounded-md">
                    <input
                      id="avg"
                      type="checkbox"
                      checked={showAverage}
                      onChange={(e) => setShowAverage(e.target.checked)}  // Update showAverage state
                      className="form-checkbox text-indigo-600"
                    />
                    <label htmlFor={"avg"} className="inline-flex items-center cursor-pointer  w-full py-1 rounded-md">
                      <span className="ml-1">Average</span>
                    </label>
                  </div>
                  <div className="w-full flex flex-row items-center hover:bg-slate-50  rounded-md">
                    <input
                      id='sum'
                      type="checkbox"
                      checked={showSum}
                      onChange={(e) => setShowSum(e.target.checked)}  // Update showAverage state

                      className="form-checkbox text-indigo-600"
                    />
                    <label htmlFor={"sum"} className="inline-flex items-center cursor-pointer  w-full py-1 rounded-md">
                      <span className="ml-1">Sum</span>
                    </label>
                  </div>
                  {fullExpandCheckBoxListArray.map((item) => (
                    <div
                      key={item}
                      className="w-full flex flex-row items-center hover:bg-slate-50 rounded-md"
                    >
                      <input
                        id={item}
                        type="checkbox"
                        className="form-checkbox text-indigo-600"
                        checked={expandCheckBoxListArray.includes(item)}
                        onChange={() => handleExpandCheckboxChange(item)}
                      />
                      <label
                        htmlFor={item}
                        className="inline-flex items-center cursor-pointer w-full py-1 rounded-md"
                      >
                        <span className="ml-1">{item}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="mt-6 flex flex-row justify-between">
          <div>
            <Button onClick={() => { setLocalFields([]) }} type="" className="border">Delete All</Button>
          </div>
          <div className="flex flex-row gap-2">

            <Button
              onClick={onClose}
              className=""

            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (JSON.stringify(fields) !== JSON.stringify(localFields)) {
                  setIsFetchingPlan(false)
                  uncheckAll();
                  setNext(true);
                  setPage(2);
                  setFields(localFields);
                }
                onClose();

              }}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
