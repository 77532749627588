import React, { useEffect, useState } from 'react'
import methodsForData from "../data/methodFor.json"
import methodHeaders from "../data/methodTableHeaders.json"
import tableData from "../data/methodTableData.json"
import CurvedRadio from './CurvedRadio'
import FilterButton from './filterButton'
import { FilterOutlined,DownloadOutlined,UploadOutlined } from '@ant-design/icons'

const Methods = ({locationFilters,customerFilters,productFilters}) => {

  const [methodsFor,setMethodsfor]=useState([]);
  const [initialValue,setInitialValue]=useState("Horizon Ensemble");
  const [selectedValue,setSelectedValue]=useState("Horizon Ensemble");
  
  useEffect(()=>{
    setMethodsfor([]);
    methodsForData.map((item)=>{
        setMethodsfor(prevState=>[...prevState,item])
    })
  },[]);

  
  return (
    <div className='flex flex-col space-y-4 text-xs relative'>
        <div className='flex flex-row w-full justify-between'>
            <div className="flex flex-row items-center space-x-2 text-base text-black">
                <p className='text-sm '>Showing methods for</p>
                <select className='px-3 py-1 text-xs bg-white rounded-full shadow-sm border border-gray-200 text-blue-600'>
                    {methodsFor.length!==0? methodsFor.map((item)=>{
                        return <option value={item.key} >{item.name}</option>
                    }):null}
                </select>
                <p className='text-sm '>Select Type</p>
                <select className='px-3 py-1 text-xs bg-white rounded-full shadow-sm border border-gray-200 text-blue-600'>
                    <option value={"monthly"}>Monthly</option>
                    <option value={"weekly"}>Weekly</option>
                    <option value={"yearly"}>Yearly</option>
                </select>
            </div>
            {/* <div className='flex flex-row items-center space-x-4'>
                      <FilterButton locationFilters={locationFilters} productFilters={productFilters} customerFilters={customerFilters}>Filter <FilterOutlined/></FilterButton>
                      <div className="flex flex-row space-x-2 items-center">
                          <DownloadOutlined/>
                          <p className='text-gray-700'>Download</p>
                      </div>
                      <div className="flex flex-row space-x-2 items-center">
                          <UploadOutlined/>
                          <p className='text-gray-700'>Upload</p>
                      </div>
            </div> */}
            {
                initialValue!==selectedValue?
                <button className='bg-blue-600 text-white rounded-lg px-2 py-1' onClick={()=>setInitialValue(selectedValue)}>Change Default Method</button>
                :null
            }
            </div>
        <table className='border p-2 border-gray-300'>
            <thead>
                <tr className='py-2 px-1 bg-slate-50'>
                    <th className='border'></th>
                    <th className='font-semibold border text-[#1e1e1e]'>Method Name</th>
                    {methodHeaders.map((header)=>{
                        if(header.key==="monthly"){
                            
                            return header.headers.map((item)=>{
                                return (
                                    <th className='font-semibold text-[#1e1e1e] py-1 border'>{item}</th>
                                )
                            })
                        }
                    })}
                </tr>
            </thead>
            <tbody>
                {tableData.map((item,index)=>{
                    if(item.type==="monthly"){
                        return(
                            
                                item.data.map((x)=>{
                                    return (
                                     <tr onClick={()=>setSelectedValue(x.forecastMethod)} className='cursor-pointer bg-white'>
                                        <td>
                                            {/*<input type={"radio"} value={x.forecastMethod} checked={selectedValue===x.forecastMethod} className='form-radio h-4 w-4 rounded-lg text-indigo-600 transition duration-150 ease-in-out' onChange={handleOptionChange} ></input>*/}
                                            <CurvedRadio label={""} checked={selectedValue===x.forecastMethod} onChange={()=>setSelectedValue(x.forecastMethod)}/>
                                        </td>
                                        <td className={`px-2 ${x.forecastMethod===selectedValue?`text-blue-600`:``} ${x.forecastMethod===initialValue?`text-blue-500`:``}`}>{x.forecastMethod}</td>
                                        {x.data.map((item)=>{
                                            return (
                                                <td className={`px-2 ${x.forecastMethod===selectedValue?`text-blue-600`:``} ${x.forecastMethod===initialValue?`text-blue-500`:``}`}>{item.difference}%</td>
                                            )
                                        })}
                                    </tr>
                                    )
                                })
                            
                        )
                    }
                })}
            </tbody>
        </table>

    </div>
  )
}

export default Methods