import React, { useEffect, useRef, useState } from 'react'
import { ExpandOutlined } from '@ant-design/icons';
import { FaSortUp } from "react-icons/fa6";
import { IoMdContract } from "react-icons/io";
import dayjs from 'dayjs';
import { Button, DatePicker, Select } from 'antd';
import 'ldrs/ring'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { useDownloadExcel } from 'react-export-table-to-excel';
import { FiDownload, FiUpload } from "react-icons/fi";
import { TailSpin } from 'react-loader-spinner';
import { Dropdown, message } from 'antd';
import { BsThreeDots } from "react-icons/bs";
import { TbTablePlus } from "react-icons/tb";
import { MdOutlineExpandCircleDown } from "react-icons/md";
import { FaSortDown } from "react-icons/fa6";
import { CgRemoveR } from "react-icons/cg";
import { FilterOutlined, } from "@ant-design/icons/lib/icons";
import { PiArrowsInSimple } from "react-icons/pi";
import { TbArrowNarrowUp } from "react-icons/tb";
import { logDOM } from '@testing-library/react';
import { GrMultiple } from "react-icons/gr";
import { TiFlashOutline } from "react-icons/ti";
import { LuInfo } from "react-icons/lu";
import { FaRegCommentDots } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useBase } from '../../../Layout/baseContext/BaseContext';
import { usePlan } from '../../../Layout/baseContext/planningContext/PlanningContext';
import EditOverlay from '../../../PlanningOverview/EditOverlay';
import Overlay from '../../../checkboxOverlay';
import FilterInATTPopUp from '../../../PlanningOverview/FilterInATTPopUp';
import ExpandCheckboxList from '../../../PlanningOverview/ExpandCheckboxList';
import CommonExpandedRow from '../../../PlanningOverview/CommonExpandedRow';
import CommonEditableRow from '../../../PlanningOverview/CommonEditableRow';
import TopRightCorner from '../../../PlanningOverview/TopRightCorner';
import CellRightClickMenu from '../../../PlanningOverview/CellRightClickMenu';
import CombinedGraph from '../../../PlanningOverview/CombinedGraph';
import { useSupply } from '../../../Layout/baseContext/supplyContext/supplyContext';
import SupplyGraph from './SupplyGraph';
import SupplyGraphEditPopUp from '../SupplyGraphEditPopUp';


const { Option } = Select;


const SupplyView = () => {

    const { tdateVar, filterString, uiMappingData, getSidePanelData } = useBase()


    const { supFields, setsupFields, supplyData, trasnposedData, setTransposedData, checkIndexes, setCheckIndexes,
        checkboxCount, setCheckboxCount,
        supplyIntervals,
        uncheckAll,
        selectedItems, setSelectedItems,
        populateGraphDisplayData,
        callingAPI, setcallingAPI,
        page, setPage,
        supplyGraphData,
        graphDisplayData, setGraphDisplayData,
        quickFilters, setQuickFilters,
        columnsShown, setColumnShown,
        contextMenu, setContextMenu,
        handleOpenMenu,
        locationFilter, setLocationFilter,
        customerFilter, setCustomerFilter,
        productFilter, setProductFilter,
        checkCommentsData, setCheckCommentsData,
        inATTFilterPopUp, setinATTFilterPopUp,
        filterATT, setFilterATT,
        totalSqty, setTotalSqty,
        totalFqty, setTotalFqty,
        supplyLoading,supplyGraphLoading,
        getSupplyData, getSupplyGraphData, getSupplyIntervals,
        graphEditPopUp, setGraphEditPopUp,
        supAdditionalFields, setSupAdditionalFields,
        startDate, setStartDate, endDate, setEndDate,
    } = useSupply()








    const [checkBoxOverLay, setCheckBoxOverlay] = useState(false);
    const [overlayFlag, setOverlayFlag] = useState(false)


    // const [trasnposedData, setTransposedData] = useState(new Map());
    const [unSortedTransposedData, setUnSortedTransposedData] = useState(new Map())
    const [detailedProposal, setDetailedProposal] = useState(false)
    const [sortedIndex, setSortedIndex] = useState(null);
    const [changes, setChanges] = useState([]);
    const [salesChanges, setSalesChanges] = useState([])
    const [eventChanges, setEventChanges] = useState([])
    const [marketChanges, setMarketChanges] = useState([])
    const [promotionChanges, setPromotionChanges] = useState([])
    const [consensusChanges, setConsensusChanges] = useState([])
    const [budgetChanges, setBudgetChanges] = useState([])
    const [newChanges, setNewChanges] = useState([])
    const [disChanges, setDisChanges] = useState([])
    const tableRef = useRef(null);
    const tempTableRef = useRef(null);
    const tdRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [allSelected, setAllSelected] = useState(true)
    const [showZeroData, setShowZeroData] = useState(false)
    const [selectedItem, setSelectedItem] = useState(supFields.item)
    const [selectedLocation, setSelectedLocation] = useState(supFields.location)
    const [selectedCustomer, setSelectedCustomer] = useState(supFields.category)
    const divRef = useRef(null);
    const [expandRow, setExpandRow] = useState(null);

    const [Expandchecklist, setExpandchecklist] = useState({
        "HOLDING_COST": false,
        "POERQTY": false,
        "TOTAL_COST": false,
        "PROFIT": false,
        "REVENUE": false,
        "LOST_SALES_COST": false,
        "PRODUCTION_COST": false,
        "PRQTY": false,
        "BOH": false,
        "EOH": false,
        "FULLFILLED_DEMAND": false,
        "UNFULLFILLED_DEMAND": false,
    })

    const [expandAllRows, setExpandAllRows] = useState(false)
    const [sortString, setsortString] = useState("")
    const [showMultiCellConfigIndex, setShowMultiCellConfigIndex] = useState(null)

    function filterMap(transposeMap) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const filteredMap = new Map();

        for (const [key, value] of transposeMap.entries()) {
            let includeEntry = false;

            for (const [date, data] of Object.entries(value)) {
                const currentDate = new Date(date);

                if (currentDate >= start && currentDate <= end) {
                    if (data.FQTY_ENGINE !== 0 && data.FQTY_FQTY_ENGINE !== null) {
                        includeEntry = true;
                        break;
                    }
                }
            }

            if (includeEntry) {
                filteredMap.set(key, value);
            }
        }
        return filteredMap;
    }


    const [intervalFiltered, setintervalFiltered] = useState([])


    const checkForMidDate = (item, tdateVar) => {
        if (supFields['interval'] === "FYEAR" && new Date(item).getFullYear() === new Date(tdateVar).getFullYear()) {
            return true;
        }
        if (supFields['interval'] === "FMONTH") {
            const itemDate = new Date(item);
            const tdate = new Date(tdateVar);
            if (itemDate.getFullYear() === tdate.getFullYear() && itemDate.getMonth() === tdate.getMonth()) {
                return true;
            }
        }
        if (supFields['interval'] === "FWEEK" && item === tdateVar) {
            return true;
        }
        return false;
    };

    const generateTransposeData = () => {

        if (supplyData !== null && supplyIntervals.length !== 0 && tdateVar !== null) {
            const transposeMap = new Map();

            // Filter interval list based on startDate and endDate
            const intervalListFiltered = supplyIntervals.filter(dateString => {
                const date = new Date(dateString[supFields.interval]);
                return date >= new Date(startDate) && date <= new Date(endDate);
            });
            setintervalFiltered(intervalListFiltered);
            supplyData && supplyData.forEach((data, index) => {
                var key = "";

                if (supAdditionalFields.length === 0) {
                    key = `${data[supFields['item']]}=${data[supFields['location']]}`;
                } else {
                    for (var i = 0; i < supAdditionalFields.length; i++) {
                        if (i > 0) {
                            key += "=";
                        }
                        key += `${data[supAdditionalFields[i]]}`;
                    }
                }

                if (!transposeMap.has(key)) {
                    transposeMap.set(key, {});
                }

                const itemMap = transposeMap.get(key);

                intervalListFiltered.forEach((interval) => {
                    if (!itemMap[interval[supFields.interval]]) {
                        itemMap[interval[supFields.interval]] = {
                            [supFields['base_1']]: 0,
                            [supFields['base_2']]: 0,
                            [supFields['base_3']]: 0,
                            [supFields['base_4']]: 0,
                            [supFields['base_5']]: 0,
                            [supFields['base_6']]: 0,
                            [supFields['base_7']]: 0,
                            [supFields['base_8']]: 0,
                            [supFields['base_9']]: 0,
                            [supFields['base_10']]: 0,
                            [supFields['base_11']]: 0,
                            [supFields['base_12']]: 0,
                            [supFields['base_13']]: 0
                        };
                    }
                });

                itemMap[data[supFields.interval]] = {
                    [supFields['base_1']]: data[supFields['base_1']],
                    [supFields['base_2']]: data[supFields['base_2']],
                    [supFields['base_3']]: data[supFields['base_3']],
                    [supFields['base_4']]: data[supFields['base_4']],
                    [supFields['base_5']]: data[supFields['base_5']],
                    [supFields['base_6']]: data[supFields['base_6']],
                    [supFields['base_7']]: data[supFields['base_7']],
                    [supFields['base_8']]: data[supFields['base_8']],
                    [supFields['base_9']]: data[supFields['base_9']],
                    [supFields['base_10']]: data[supFields['base_10']],
                    [supFields['base_11']]: data[supFields['base_11']],
                    [supFields['base_12']]: data[supFields['base_12']],
                    [supFields['base_13']]: data[supFields['base_13']],
                };
            });

            transposeMap.forEach((itemMap) => {
                Object.keys(itemMap).forEach(interval => {
                    if (!intervalListFiltered.some(filteredInterval => filteredInterval[supFields.interval] === interval)) {
                        delete itemMap[interval];
                    }
                });
            });

            const sorted = transposeMap; // Assuming sortMapBySumAvg isn't needed here
            // const mapWithoutZeroes = filterMap(sorted);
            // if (showZeroData) {
            setTransposedData(sorted);
            //     setUnSortedTransposedData(sorted);
            // } else {
            //     setTransposedData(mapWithoutZeroes);
            //     setUnSortedTransposedData(mapWithoutZeroes);
            // }

            return transposeMap;
        }
        return null;
    };





    useEffect(() => {
        generateTransposeData();
    }, [showZeroData])






    const addCheckIndex = (newIndex) => {
        setCheckIndexes((prev) => {
            let newTop = 2; // Default value for the first top

            if (prev.length > 0) {
                // Calculate new top based on the last entry
                const lastTop = prev[prev.length - 1].top;
                newTop = lastTop + 2;
            }

            // Create the new entry
            const newEntry = {
                index: newIndex,
                top: newTop,
            };

            // Create a new array with the new entry and sort it by `index`
            const updatedArray = [...prev, newEntry].sort((a, b) => a.index - b.index);
            // Adjust the `top` values after sorting
            return updatedArray.map((item, i) => ({
                ...item,
                top: 2 + i * 2, // Recalculate top values after sorting
            }));
        });
    };
    const removeCheckIndex = (removeIndex) => {
        setCheckIndexes((prev) => {
            // Filter out the item with the specified index
            const updatedArray = prev.filter(item => item.index !== removeIndex);

            // Recalculate the `top` values for the remaining items
            return updatedArray.map((item, i) => {
                return {
                    ...item,
                    top: 2 + i * 2, // Recalculate the top value based on position
                };
            });
        });
    };

    const isIndexPresent = (searchIndex) => {
        return checkIndexes.find(item => item.index === searchIndex) !== undefined;
    };

    const getTopForIndex = (rowIndex) => {
        const foundItem = checkIndexes.find(item => item.index === rowIndex);
        return foundItem ? foundItem.top : null;
    };











    const { onDownload } = useDownloadExcel({
        currentTableRef: tempTableRef.current,
        filename: 'Users table',
        sheet: 'Users'
    })




    useEffect(() => {

        if (tdRef.current && sortOrder === null) {
            // tdRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
            scrollToOffsetCenter()
        }
    }, [supplyData, trasnposedData]);


    const [columnWidths, setColumnWidths] = useState({
        col1: 180,
        col2: 180,
        col3: 180,
    });
    const [dynamicColumnWidths, setdynamicColumnWidths] = useState({});
    const scrollToOffsetCenter = () => {
        if (tdRef.current && divRef.current) {
            const container = divRef.current;

            const tdElement = tdRef.current;
            let fromLeft = 0;
            // Calculate the current offset from the left and how much to scroll
            const targetPosition = tdElement.offsetLeft; // Position of tdRef from left of container
            if (supAdditionalFields.length === 0) {
                fromLeft = columnWidths.col1 + columnWidths.col2 + columnWidths.col3
            }
            else {
                fromLeft = Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 0)
            }


            const scrollPosition = targetPosition - fromLeft - 90; // We want it 630px from the left  
            // Smoothly scroll to the calculated position
            container.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
            });
        }
    };

    // useEffect(() => {
    //     if (allSelected === true) {
    //         setSelectedItems([]);
    //     }
    // }, [allSelected])
    useEffect(() => {
        uncheckAll();
    }, [supFields])


    useEffect(() => {
        if (supplyGraphData !== null && startDate !== null && endDate !== null && selectedItems.length === 0)
            // checkedGraphData();

            populateGraphDisplayData(supplyGraphData);
    }, [supplyGraphData, startDate, endDate])
    // useEffect(() => {
    //     if (supplyIntervals.length !== 0 && endDate === null) {
    //         console.log("----------interval list",supplyIntervals);
    //         console.log("----------startDate",startDate);
    //         setEndDate(supplyIntervals[supplyIntervals.length - 1][supFields["interval"]])

    //     }
    // }, [supplyIntervals])
    useEffect(() => {
        if (supplyData !== null && supplyIntervals.length !== 0 && startDate !== null && endDate !== null && tdateVar !== null) {
            setSortedIndex(-1);
            setSortOrder(null)
            generateTransposeData();
            setorderOfSortForSalesForecast("")
            // emptyAll();
            // setflag(0)
            // setCheckboxCount(-1)
            // setSelectedItems([]);

        }
    }, [supplyData, supplyIntervals, supAdditionalFields, startDate, endDate, tdateVar])

    useEffect(() => {
        setPage(2);
    }, [supFields, filterString])

    function getSummedData(selectedItems, dataMap) {
        const summedData = {};
    
        selectedItems.forEach((item) => {
            const itemData = dataMap.get(item);
    
            if (itemData) {
                for (const [date, values] of Object.entries(itemData)) {
                    if (!summedData[date]) {
                        // Initialize if date entry doesn't exist yet
                        summedData[date] = { ...values }; // Clone the first values object
                    } else {
                        // Dynamically sum all fields in values
                        for (const [key, value] of Object.entries(values)) {
                            if (typeof value === 'number') {
                                summedData[date][key] = (summedData[date][key] || 0) + value;
                            }
                        }
                    }
                }
            }
        });
        return summedData;
    }
    

    // Function to extract and set separate datasets
    function processAndSetGraphData(result, labels) {
        // Initialize datasets for SQTY, FQTY, FQTY_BUDGET, and FQTY_USER
        const newGraphData = {
            data1: [],
            data2: [],
            data3: [],
            data4: [],
            data5: [],
            data6: [],
            data7: [],
            data8: [],
            data9: [],
            data10: [],
            data11: [],
            data12: [],
            data13: [],
            dates: labels,
          };
        labels.forEach((label) => {
            
            const entry = result[label];
            // // Push values or default to 0/null
            // SQTYData.push(entry?.SQTY || 0);
            // FQTYData.push(entry?.FQTY || 0);
            // FQTY_BUDGETData.push(entry?.FQTY_BUDGET || 0);
            // FQTY_USERData.push(entry?.FQTY_USER || 0);

            newGraphData.data1.push(entry[supFields['base_1']]  || 0);
            newGraphData.data2.push(entry[supFields['base_2']]  || 0);
            newGraphData.data3.push(entry[supFields['base_3']]  || 0);
            newGraphData.data4.push(entry[supFields['base_4']]  || 0);
            newGraphData.data5.push(entry[supFields['base_5']]  || 0);
            newGraphData.data6.push(entry[supFields['base_6']]  || 0);
            newGraphData.data7.push(entry[supFields['base_7']]  || 0);
            newGraphData.data8.push(entry[supFields['base_8']]  || 0);
            newGraphData.data9.push(entry[supFields['base_9']]  || 0);
            newGraphData.data10.push(entry[supFields['base_10']]  || 0);
            newGraphData.data11.push(entry[supFields['base_11']]  || 0);
            newGraphData.data12.push(entry[supFields['base_12']]  || 0);
            newGraphData.data13.push(entry[supFields['base_13']]  || 0);
        });

        // Now set each dataset using the provided functions
        setGraphDisplayData(newGraphData);

       

    }



    useEffect(() => {
        if (selectedItems.length !== 0) {
            var var_new_string = ""
            var var_item_arr = `&${supFields.item}=`;
            var var_location_arr = `&${supFields.location}=`;
            var var_customer_arr = `&${supFields.category}=`;
            selectedItems.map((item, index) => {
                const data = item.split("=")
                if (index !== selectedItems.length - 1) {
                    var_item_arr += data[0] + ","
                    var_location_arr += data[1] + ","
                    var_customer_arr += data[2] + ","
                }
                else {
                    var_item_arr += data[0]
                    var_location_arr += data[1]
                    var_customer_arr += data[2]
                }
            })
            var_new_string += var_item_arr + "&" + var_location_arr + "&" + var_customer_arr

            setAllSelected(false)

            const result = getSummedData(selectedItems, trasnposedData);
          
            // populateGraphDisplayData({results : [result]})
            processAndSetGraphData(result, Object.keys(result));
        }
        else {
            populateGraphDisplayData(supplyGraphData)
        }
    }
        , [selectedItems, trasnposedData])

    const onClick = ({ key }) => {
        if (key === '1') {
            setShowZeroData(false);
            setSortedIndex(-1);
            message.info('Showing data without 0s');
        } else if (key === '2') {
            setShowZeroData(true);
            setSortedIndex(-1);
            message.info('Showing data with 0s');
        }
    };
    const items = [
        {
            label: 'Show Data without 0s',
            key: '1',
        },
        {
            label: 'Show Data with 0s',
            key: '2',
        }
    ];
    const [sortOrder, setSortOrder] = useState(null)
    const [keySorting, setKeySorting] = useState(false);

    function sortData(data, index) {
        uncheckAll();
        setKeySorting(true);
        setsortString("")

        let newSortOrder;

        // If the index changes, start with descending order
        if (index !== sortedIndex) {
            newSortOrder = false; // Start with descending order for the new column
        } else {
            // Determine the new sort order based on the current state
            if (sortOrder === null) {
                newSortOrder = false; // First double-click on the same column sorts in descending order
            } else if (sortOrder === false) {
                newSortOrder = true; // Second double-click sorts in ascending order
            } else {
                setTransposedData(unSortedTransposedData); // Third double-click restores original order
                setSortOrder(false);
                setSortedIndex(-1);
                setKeySorting(false);
                return;
            }
        }

        setSortOrder(newSortOrder);
        setSortedIndex(index);

        const sortedData = new Map(
            [...data.entries()].sort((a, b) => {
                const aValue = a[0].split('=')[index];
                const bValue = b[0].split('=')[index];

                if (!isNaN(aValue) && !isNaN(bValue)) {
                    return newSortOrder ? aValue - bValue : bValue - aValue;
                } else {
                    return newSortOrder ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }
            })
        );

        setTransposedData(sortedData);
    }



    function sortDataByDate(date, dataMap, index, asc) {
        uncheckAll();
        setKeySorting(false)
        setsortString("")
        if (sortOrder === asc && index === sortedIndex && !keySorting) {
            setTransposedData(unSortedTransposedData)
            setSortOrder(false);
            setSortedIndex(-1)
        }
        else {
            setSortOrder(asc)
            setSortedIndex(index);
            const dataArray = Array.from(dataMap.entries());

            dataArray.sort((a, b) => {
                const aData = a[1][date];
                const bData = b[1][date];

                let comparison = 0;
                if (aData.FQTY_USER !== null && bData.FQTY_USER !== null) {
                    comparison = aData.FQTY_USER - bData.FQTY_USER;
                } else if (aData.FQTY_USER !== null) {
                    comparison = -1;
                } else if (bData.FQTY_USER !== null) {
                    comparison = 1;
                } else {
                    comparison = aData.SQTY - bData.SQTY;
                }

                return asc ? comparison : -comparison;
            });

            const sortedMap = new Map(dataArray);
            if (showZeroData) {
                setTransposedData(sortedMap)
            }
            else {
                const sortedWithoutZeroes = filterMap(sortedMap)
                setTransposedData(sortedWithoutZeroes)
            }


        }
    }





    const [cwidth, setcwidth] = useState(-1)


    const handleScroll = () => {
        if (divRef.current && !callingAPI) {
            const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = divRef.current;
            setcwidth(scrollLeft)
            if ((scrollTop + clientHeight >= scrollHeight - 1) && scrollLeft === cwidth) {
                setPage((prevPage) => prevPage + 1);

            }
        }
    };


    useEffect(() => {
        const divElement = divRef.current;
        if (divElement) {
            divElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener('scroll', handleScroll);
            }
        };
    });

    var midIndex = 0;







    const col1Ref = useRef(null);
    const col2Ref = useRef(null);
    const col3Ref = useRef(null);

    const handleMouseDown = (e, colKey, colRef) => {
        const startX = e.clientX;

        const startWidth = colRef.current.offsetWidth;

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            if (newWidth > 50) {

                setColumnWidths((prevWidths) => {
                    // Spread the current state to a new object

                    const updatedWidths = { ...prevWidths, [colKey]: newWidth };
                    // Return the new state object
                    return updatedWidths;
                });
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };



    const columnRefs = useRef({});

    useEffect(() => {
        // Update dynamic column widths when supAdditionalFields is updated
        const newDynamicColumnWidths = supAdditionalFields.reduce((widths, col, index) => {
            widths[`col${index}`] = 180; // Default width of 180
            return widths;
        }, {});

        setdynamicColumnWidths(newDynamicColumnWidths);

        // Update refs when supAdditionalFields is updated
        columnRefs.current = supAdditionalFields.reduce((refs, col, index) => {
            refs[`col${index}`] = React.createRef();
            return refs;
        }, {});
    }, [supAdditionalFields]);



    const handleMouseDownDynamic = (e, colKey) => {
        const colRef = columnRefs.current[colKey];
        const startX = e.clientX;
        const startWidth = colRef.current.offsetWidth;

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            if (newWidth > 50) { // Ensure minimum width of 50px
                setdynamicColumnWidths((prevWidths) => {
                    const updatedWidths = { ...prevWidths, [colKey]: newWidth }
                    return updatedWidths
                })
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };
    const checkboxRefs = useRef([]); // Array to store refs for each checkbox

    const handleKeyDown = (e, index) => {
        const currentItem = [...trasnposedData.keys()][index];

        if (e.key === 'ArrowDown') {
            if (index < trasnposedData.size - 1) {
                // Uncheck the current checkbox and update indices
                setSelectedItems(prevState => {
                    removeCheckIndex(index); // Remove current index from checkIndexes
                    return prevState.filter(item => item !== currentItem);
                });

                const nextItem = [...trasnposedData.keys()][index + 1];
                // Check the next checkbox and update indices
                setSelectedItems(prevState => {
                    addCheckIndex(index + 1); // Add next index to checkIndexes
                    return [...prevState, nextItem];
                });

                // Move focus to the next checkbox
                checkboxRefs.current[index + 1].focus();
            }
        } else if (e.key === 'ArrowUp') {
            if (index > 0) {
                // Uncheck the current checkbox and update indices
                setSelectedItems(prevState => {
                    removeCheckIndex(index); // Remove current index from checkIndexes
                    return prevState.filter(item => item !== currentItem);
                });

                const prevItem = [...trasnposedData.keys()][index - 1];
                // Check the previous checkbox and update indices
                setSelectedItems(prevState => {
                    addCheckIndex(index - 1); // Add previous index to checkIndexes
                    return [...prevState, prevItem];
                });

                // Move focus to the previous checkbox
                checkboxRefs.current[index - 1].focus();
            }
        }
    };






    const isDateInRange = (date, start, end) => {
        return new Date(date) >= new Date(start) && new Date(date) <= new Date(end);
    };

    // Step 2: Function to calculate sum and average of SQTY between dates
    const calculateSumAndAvg = (data, type, isSum) => {
        let sum = 0;
        let count = 0;

        if (type === 'sales') {
            Object.keys(data).forEach((date) => {
                if (isDateInRange(date, startDate, tdateVar)) {
                    const sqty = data[date].SQTY;
                    if (sqty !== null) {
                        sum += sqty;
                        count += 1;
                    }
                }
            });
        }

        if (type === 'forecast') {
            Object.keys(data).forEach((date) => {
                if (isDateInRange(date, tdateVar, endDate)) {
                    const fqty = data[date].FQTY;
                    if (fqty !== null) {
                        sum += fqty;
                        count += 1;
                    }
                }
            });
        }


        const avg = count > 0 ? sum / count : 0;

        if (isSum) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
            }).format(sum);
        }
        else {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
            }).format(avg);
        }
    };

    // Helper function to check if a change matches the current details and interval
    const doesChangeMatch = (change, details, interval, supAdditionalFields) => {
        if (supAdditionalFields.length > 0) {
            // Check all dynamic supFields from supAdditionalFields
            return supAdditionalFields.every((field, idx) => change[field] === details[idx]) && change["interval"] === interval;
        } else {
            // Check for hardcoded supFields when supAdditionalFields is empty
            return change[selectedItem] === details[0] &&
                change[selectedLocation] === details[1] &&
                change[selectedCustomer] === details[2] &&
                change["interval"] === interval;
        }
    };

    // Helper function to create a new entry for the changes array
    const createNewEntry = (value, details, interval, supAdditionalFields, keyValue, prevValue) => {
        let newEntry;

        const validKeys = [
            "FQTY_USER",
            "FQTY_SALES",
            "FQTY_CONSENSUS",
            "FQTY_MARKET",
            "FQTY_PROMOTION",
            "FQTY_EVENT",
            "FQTY_BUDGET",
            "FQTY_NEW",
            "FQTY_DISCONTINUED",
        ];

        if (validKeys.includes(keyValue)) {
            newEntry = {
                [keyValue]: parseInt(value),
                previousValue: prevValue,
                interval: interval,
            };
        }





        if (supAdditionalFields.length > 0) {
            supAdditionalFields.forEach((field, idx) => {
                newEntry[field] = details[idx];
            });
        } else {
            newEntry[selectedItem] = details[0];
            newEntry[selectedLocation] = details[1];
            newEntry[selectedCustomer] = details[2];
        }

        return newEntry;
    };


    const checkAllChangesArrayAreEmpty = (excludedArrayRef) => {
        // Get all state arrays
        const allArrays = {
            changes,
            salesChanges,
            eventChanges,
            marketChanges,
            promotionChanges,
            consensusChanges,
            budgetChanges,
            newChanges,
            disChanges
        };

        // Check if all other arrays are empty
        const isEmpty = Object.values(allArrays).every((array) => {
            if (array === excludedArrayRef) {
                return true; // Skip the excluded array
            }
            return array.length === 0; // Ensure all others are empty
        });

        if (!isEmpty) {
            message.info("Undo other changes to modify data here");
        }

        return isEmpty;
    };



    const [orderOfSortForSalesForecast, setorderOfSortForSalesForecast] = useState("")
    function sortMapBySumAvg(mapData, sortType, sortingOf) {
        // Helper function to calculate sum or average

        // uncheckAll();
        setKeySorting(false)
        setSortOrder(null);
        setSortedIndex(-1)
        var sortOrderOfNumbers = "";
        const calculateValue = (data, field) => {
            let total = 0;
            let count = 0;

            setsortString(`${sortType}-${sortingOf}`)
            if (sortString === `${sortType}-${sortingOf}` && (orderOfSortForSalesForecast === "ascending" || orderOfSortForSalesForecast === "")) {
                sortOrderOfNumbers = "descending";
                setorderOfSortForSalesForecast("descending")

            }
            else if (sortString === `${sortType}-${sortingOf}` && (orderOfSortForSalesForecast === "descending")) {
                sortOrderOfNumbers = "ascending";
                setorderOfSortForSalesForecast("ascending")

            }
            else if (sortString !== `${sortType}-${sortingOf}`) {
                sortOrderOfNumbers = "descending"
                setorderOfSortForSalesForecast("descending")
            }
            for (const date in data) {
                if (data[date][field] !== null) {
                    total += data[date][field];
                    count++;
                }
            }

            if (sortType === 'average' && count > 0) {
                return total / count;
            }

            return total;
        };

        // Determine which field to use for sorting (SQTY for sales or FQTY_USER for forecast)
        const field = sortingOf === 'sales' ? 'SQTY' : 'FQTY_USER';

        // Convert map into an array for sorting
        const sortedArray = Array.from(mapData).sort((a, b) => {
            const valueA = calculateValue(a[1], field);
            const valueB = calculateValue(b[1], field);

            if (sortOrderOfNumbers === 'ascending') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });


        setTransposedData(new Map(sortedArray))
        return new Map(sortedArray);
    }
    const { planningTableLoading } = useBase()

    const [colSpanNumber, setColSpanNumber] = useState(0);




    const handleUndo = () => {
        const stateMapping = [
            { state: changes, setState: setChanges },
            { state: salesChanges, setState: setSalesChanges },
            { state: eventChanges, setState: setEventChanges },
            { state: marketChanges, setState: setMarketChanges },
            { state: promotionChanges, setState: setPromotionChanges },
            { state: consensusChanges, setState: setConsensusChanges },
            { state: newChanges, setState: setNewChanges },
            { state: disChanges, setState: setDisChanges },
            { state: budgetChanges, setState: setBudgetChanges },
        ];

        stateMapping.forEach(({ state, setState }) => {
            if (state.length !== 0) {
                setState(prevArray => prevArray.slice(0, -1));
            }
        });
    };

    const dropdownRefs = useRef([]);
    useEffect(() => {

        const handleClickOutside = (event) => {
            // Check if the click is outside of any of the dropdowns
            const isOutside = dropdownRefs.current.every(ref => ref && !ref.contains(event.target));
            if (isOutside) {
                setShowMultiCellConfigIndex(null); // Close the dropdown
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowMultiCellConfigIndex]);


    // useEffect(() => {
    //     setSelectedAdjType(null)
    //     setBucketLength(2)
    // }, [showMultiCellConfigIndex])

    const handleSelectChange = (value) => {
        const referenceDate = dayjs(tdateVar);
        setQuickFilters(value);

        switch (supFields['interval']) {
            case "FMONTH":
                if (value === "3m") {
                    setStartDate(referenceDate.subtract(3, 'month'));
                    setEndDate(referenceDate.add(3, 'month'));
                } else if (value === "6m") {
                    setStartDate(referenceDate.subtract(6, 'month'));
                    setEndDate(referenceDate.add(6, 'month'));
                } else if (value === "1y") {
                    setStartDate(referenceDate.subtract(1, 'year'));
                    setEndDate(referenceDate.add(1, 'year'));
                }
                break;

            case "FWEEK":
                if (value === "6w") {
                    setStartDate(referenceDate.subtract(6, 'week'));
                    setEndDate(referenceDate.add(6, 'week'));
                } else if (value === "12w") {
                    setStartDate(referenceDate.subtract(12, 'week'));
                    setEndDate(referenceDate.add(12, 'week'));
                } else if (value === "30w") {
                    setStartDate(referenceDate.subtract(30, 'week'));
                    setEndDate(referenceDate.add(30, 'week'));
                }
                break;

            case "FYEAR":
                if (value === "1y") {
                    setStartDate(referenceDate.subtract(1, 'year'));
                    setEndDate(referenceDate.add(1, 'year'));
                } else if (value === "3y") {
                    setStartDate(referenceDate.subtract(3, 'year'));
                    setEndDate(referenceDate.add(3, 'year'));
                } else if (value === "5y") {
                    setStartDate(referenceDate.subtract(5, 'year'));
                    setEndDate(referenceDate.add(5, 'year'));
                }
                break;

            default:
                break;
        }
    };

    const getOptions = () => {
        switch (supFields['interval']) {
            case "FMONTH":
                return [
                    { label: "3+3 months", value: "3m" },
                    { label: "6+6 months", value: "6m" },
                    { label: "12+12 months", value: "1y" },
                ];
            case "FWEEK":
                return [
                    { label: "6+6 weeks", value: "6w" },
                    { label: "12+12 weeks", value: "12w" },
                    { label: "30+30 weeks", value: "30w" },
                ];
            case "FYEAR":
                return [
                    { label: "1+1 year", value: "1y" },
                    { label: "3+3 years", value: "3y" },
                    { label: "5+5 years", value: "5y" },
                ];
            default:
                return [];
        }
    };
    const getOptionsUnits = () => {

        return [
            { label: "Units", value: "units" },
            { label: "USD", value: "usd" },
            { label: "EUR", value: "eur" },
            { label: "INR", value: "inr" },
        ];

    };
    function findDetails(details) {
        // Iterate through each object in checkCommentsData

        if (checkCommentsData) {
            for (const data of checkCommentsData) {
                // Check if all elements of details are in Column_Values
                const columnValues = data.Column_Values || [];

                const filteredColumnValues = columnValues.filter((value) => value !== null);
                const allMatch = filteredColumnValues.every(value => details.includes(value));

                // If all details are found, return an object with POST_ID and FDATES
                if (allMatch) {
                    return {
                        POST_ID: data.POST_ID,
                        FDATES: data.FDATES || []
                    };
                }
            }
        }
        // Return null if no match is found
        return null;
    }




    return (
        <div className='flex flex-col space-y-2 w-full  h-full relative overflow-hidden'>

            {
                supplyLoading && supplyGraphLoading ?
                    (<div className='h-full flex justify-center items-center'><TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div>)
                    :
                    <>
                        <div className='flex flex-row w-full justify-between'>
                            <div className="flex flex-row items-center text-sm w-full gap-2 pl-4 text-[#646666]">

                                <p >Data showing from</p>
                                <DatePicker

                                    className="px-2 py-1 !text-xs custom-date-picker  bg-white rounded-full shadow-sm border border-gray-200 text-blue-600 max-w-28"
                                    value={startDate ? dayjs(startDate) : null}
                                    onChange={(date, dateString) => {
                                        setStartDate(dateString);
                                        setQuickFilters(null); // Clear the Select value
                                    }}
                                />
                                <p>to</p>
                                <DatePicker
                                    className="px-2 py-1 text-xs custom-date-picker  bg-white rounded-full shadow-sm border border-gray-200 text-blue-600 max-w-28"
                                    value={endDate ? dayjs(endDate) : null}
                                    onChange={(date, dateString) => {
                                        setEndDate(dateString);
                                        setQuickFilters(null); // Clear the Select value
                                    }}
                                />
                                <p>in</p>
                                <Select
                                    className="interval-selector rounded-full max-w-fit !text-blue-500"
                                    placeholder="Select Interval"
                                    value={supFields.interval} // Controlled value
                                    onChange={(value) => {
                                        setsupFields((prevState) => ({
                                            ...prevState,
                                            interval: value, // Update the interval field
                                        }));
                                    }}
                                    dropdownStyle={{ fontSize: '10px', width: "150px" }} // Optional: Customize dropdown width
                                >
                                    <Option value="FMONTH">Monthly</Option>
                                    <Option value="FWEEK">Weekly</Option>
                                    <Option value="FYEAR">Yearly</Option>
                                </Select>

                                <p>using a </p>
                                <Select
                                    className="interval-selector rounded-full max-w-fit !text-blue-500"
                                    placeholder="Custom"
                                    value={quickFilters} // Controlled value
                                    onChange={handleSelectChange}
                                    style={{ width: "100%" }}
                                    dropdownStyle={{ width: "150px" }}
                                >
                                    {getOptions().map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                                <p>view in</p>
                                <Select
                                    className="unit-selector interval-selector rounded-full max-w-20 !text-blue-500"
                                    defaultValue="units"
                                    // onChange={handleSelectChange}
                                    style={{ width: "100%" }}
                                >
                                    {getOptionsUnits().map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='flex flex-row items-center space-x-4'>


                                <div onClick={onDownload} className="flex flex-row  items-center border border-slate-300 hover:bg-slate-100 p-2 rounded-full cursor-pointer text-slate-400 font-bold text-lg" data-tooltip-id="my-tooltip" data-tooltip-content="Download">
                                    <FiDownload />
                                </div>
                                <div className="flex flex-row  items-center  p-2 border border-slate-300  hover:bg-slate-100 rounded-full cursor-pointer text-slate-400 text-lg font-bold" data-tooltip-id="my-tooltip" data-tooltip-content="Upload">
                                    <FiUpload />
                                </div>
                                <div className="flex flex-row  items-center  p-2 border border-slate-300  hover:bg-slate-100 rounded-full cursor-pointer text-slate-400 text-lg font-bold">
                                    <Dropdown
                                        menu={{
                                            items,
                                            onClick,
                                        }}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>

                                            <BsThreeDots />
                                        </a>
                                    </Dropdown>
                                </div>

                            </div>

                        </div>
                        {
                            !isExpanded ?
                                <div className='flex flex-row  items-center p-6 bg-white rounded-lg w-full shadow-sm'>
                                    {
                                        // <CombinedGraph tdateVar={tdateVar} supFields={supFields} lineData1={lineData1} lineData2={lineData2} barData1={barData1} barData2={barData2} duration={supFields["interval"]} />
                                        <SupplyGraph graphDisplayData={graphDisplayData} fields={supFields} />
                                    }
                                </div> : null
                        }

                        {supplyLoading ?
                            (<div className='h-full flex justify-center items-center'><TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#2B3674"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /></div>)
                            :
                            <div ref={divRef} className={`w-full ${isExpanded ? 'max-h-full' : 'h-full'} overflow-scroll  border relative scroll   shadow-class`}>
                                {(
                                    <>

                                        <table ref={tableRef} className='resizable-table w-full relative whitespace-nowrap text-xs table-auto shadow-class bg-white  transition-all ease-in-out duration-500 '>
                                            <thead className='w-full sticky top-0 border-b shadow-sm shadow-class bg-white z-50 ' >
                                                <tr className='font-bold  bg-slate-100'>
                                                    {/* <td className='sticky bg-slate-100 shadow-col left-0 z-40 min-w-[30px]  text-center text-base text-blue-500  no-download-col'>
                                                    <button className='w-full h-full' onClick={() => setCheckBoxOverlay(true)}><TbTablePlus /></button>
                                                </td> */}
                                                    <td className='sticky bg-slate-100 shadow-col left-0 min-w-[90px]   z-50 text-center text-base '>
                                                        <div className='flex flex-row items-center justify-between px-3'>
                                                            <div className='flex items-center'>
                                                                <button className='w-full h-full hover:text-blue-400' onClick={() => setCheckBoxOverlay(true)}><TbTablePlus /></button>
                                                            </div>
                                                            <div
                                                                className=" hover:text-blue-500 text-sm">
                                                                {
                                                                    !isExpanded ?
                                                                        <ExpandOutlined className='cursor-pointer'
                                                                            onClick={() => {
                                                                                setIsExpanded(true);
                                                                            }} />
                                                                        :
                                                                        <IoMdContract className='cursor-pointer'
                                                                            onClick={() => {
                                                                                setIsExpanded(false);
                                                                            }} />
                                                                }

                                                            </div>

                                                            <div onClick={() => uncheckAll()} className='hover:text-blue-500 cursor-pointer'>

                                                                <CgRemoveR />
                                                            </div>

                                                        </div>

                                                    </td>
                                                    {supAdditionalFields.length === 0 &&

                                                        <>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 0)}
                                                                ref={col1Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][supFields['item']].toString().length > 15 ? uiMappingData[0][supFields['item']] : ""}
                                                                style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px` }}
                                                                className={`sticky cursor-pointer ${sortedIndex === 0 && keySorting ? "bg-blue-100" : "bg-slate-100"}  shadow-col  left-[90px] z-50 overflow-hidden  rounded-tl-sm text-left  text-xs py-1 border-1 border-gray-200 `}>
                                                                <div className='flex flex-row gap-1 items-center px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(supFields['item']) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>

                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][supFields['item']]}
                                                                    </div>
                                                                    <div className='plan-select  cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none  appearance-none px-1 relative`}
                                                                            onChange={(e) => {
                                                                                setsupFields({ ...supFields, "item": e.target.value });
                                                                                setSelectedItem(e.target.value);
                                                                            }}
                                                                            value=""
                                                                        >
                                                                            <option value="" disabled hidden></option>
                                                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                                                if (item.toString().substring(0, 1) === "I" && uiMappingData[0][item] !== null) {
                                                                                    return (
                                                                                        <option key={item} value={item}>
                                                                                            {uiMappingData[0][item]}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </select>

                                                                    </div>

                                                                </div>
                                                                <div
                                                                    className="resize-handle"
                                                                    onMouseDown={(e) => handleMouseDown(e, 'col1', col1Ref)}
                                                                />

                                                            </td>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 1)}
                                                                ref={col2Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][supFields['location']].toString().length > 15 ? uiMappingData[0][supFields['location']] : ""}
                                                                style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                                className={`sticky  shadow-col cursor-pointer ${sortedIndex === 1 && keySorting ? "bg-blue-100" : "bg-slate-100"} overflow-hidden   z-50 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200 `}>
                                                                <div className='flex flex-row gap-1 items-center   px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(supFields['location']) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>

                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][supFields['location']]}
                                                                    </div>
                                                                    <div className='plan-select  cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none  appearance-none px-1 relative`}
                                                                            onChange={(e) => {
                                                                                setsupFields({ ...supFields, "location": e.target.value });
                                                                                setSelectedLocation(e.target.value)
                                                                            }}
                                                                            value={""}>
                                                                            <>
                                                                                <option value="" disabled hidden></option>
                                                                                {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                                                    if (item.toString().substring(0, 1) === "L" && uiMappingData[0][item] !== null) {
                                                                                        return (
                                                                                            <option value={item}>{uiMappingData[0][item]}</option>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </>
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div
                                                                    className="resize-handle"
                                                                    onMouseDown={(e) => handleMouseDown(e, 'col2', col2Ref)}
                                                                />
                                                            </td>

                                                        </>
                                                    }
                                                    {supAdditionalFields.length !== 0 && supAdditionalFields.map((item, index) => {
                                                        // Calculate the sum of all previous columns' widths
                                                        const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                            .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                            .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns


                                                        // Determine the field type
                                                        let fieldType = '';
                                                        let fieldKey = '';
                                                        if (item.toString()[0] === 'I') {
                                                            fieldType = 'item';
                                                            fieldKey = 'I';
                                                        } else if (item.toString()[0] === 'L') {
                                                            fieldType = 'location';
                                                            fieldKey = 'L';
                                                        } else if (item.toString()[0] === 'C') {
                                                            fieldType = 'category';
                                                            fieldKey = 'C';
                                                        }

                                                        // Function to handle change based on the field type
                                                        const handleChange = (e) => {
                                                            setsupFields({ ...supFields, [fieldType]: e.target.value });
                                                            const updatedColumns = columnsShown.map(data => data === item ? e.target.value : data);
                                                            setColumnShown(updatedColumns);
                                                            fieldType === 'item' ? setSelectedItem(e.target.value) :
                                                                fieldType === 'location' ? setSelectedLocation(e.target.value) :
                                                                    setSelectedCustomer(e.target.value);
                                                        };
                                                        return (
                                                            <td
                                                                ref={columnRefs.current[`col${index}`]}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][item].toString().length > 15 ? uiMappingData[0][item] : ""}
                                                                onDoubleClick={() => sortData(trasnposedData, index)}
                                                                style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                key={index}
                                                                className={`sticky ${sortedIndex === index && keySorting ? "bg-blue-100" : "bg-slate-100"} shadow-col overflow-hidden cursor-pointer px-3 text-xs py-1 border-1 border-gray-200 z-50`}
                                                            >
                                                                <div className='flex flex-row gap-1 items-center  px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(item) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>
                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][item]}
                                                                    </div>
                                                                    <div className='plan-select cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none appearance-none px-1 relative`}
                                                                            onChange={handleChange}
                                                                            value={""}
                                                                        >
                                                                            <option value="" disabled hidden></option>
                                                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((data) => {
                                                                                if (data.toString()[0] === fieldKey && uiMappingData[0][data] !== null) {
                                                                                    return (
                                                                                        <option value={data} selected={uiMappingData[0][item] === uiMappingData[0][data]}>
                                                                                            {uiMappingData[0][data]}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='resize-handle'
                                                                    style={{ cursor: 'col-resize', padding: '0 5px' }}
                                                                    onMouseDown={(e) => handleMouseDownDynamic(e, `col${index}`)}
                                                                />
                                                            </td>
                                                        );
                                                    })}




                                                    {trasnposedData.size !== 0 && Object.keys(trasnposedData.get([...trasnposedData.keys()][0])).map((item, index) => {



                                                        if (new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                            return (<React.Fragment key={index}>
                                                                <td className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""} z-40  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                    <div className='flex flex-row items-center gap-3 justify-between'>
                                                                        <div className='flex flex-col items-start'>
                                                                            <p>
                                                                                {supFields['interval'] === "FWEEK" || supFields['interval'] === "FDATE" &&
                                                                                    `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                {supFields['interval'] === "FMONTH" &&
                                                                                    `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                {supFields['interval'] === "FYEAR" &&
                                                                                    new Date(item).getFullYear().toString()}
                                                                            </p>

                                                                            <p className='text-slate-400 font-normal gap-2 flex justify-between items-center'>
                                                                                {/* <span>

                                                                                    {Intl.NumberFormat('en-US', {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1
                                                                                    }).format(index < midIndex ? totalSqty[index] : totalFqty[index])}
                                                                                </span> */}
                                                                                {/* <span className='flex items-center justify-center text-lg'>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedUp />
                                                                                    </div>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedDown />
                                                                                    </div>
                                                                                </span> */}
                                                                            </p>
                                                                        </div>
                                                                        <div className='text-base  flex flex-col justify-start items-center '>
                                                                            <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortUp />
                                                                            </div>
                                                                            <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortDown />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>)
                                                        }

                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className=''>
                                                {trasnposedData.size !== 0 && [...trasnposedData.keys()].map((data, rowIndex) => {
                                                    const details = data.split("=");
                                                    const result = findDetails(details);
                                                    return (
                                                        <>
                                                            <tr

                                                                className={`font-semibold  transition-all ease-in-out  duration-500 ${isIndexPresent(rowIndex) ? 'sticky z-50 shadow-col left-0 h-8' : ''}`}
                                                                style={isIndexPresent(rowIndex) ? { top: `${getTopForIndex(rowIndex)}rem` } : {}}
                                                                key={rowIndex}>


                                                                {/* <td className='sticky min-w-[30px] shadow-col bg-white left-0 z-30 transform-all ease-in-out duration-500 h-full font-bold text-lg text-center hover:bg-slate-100 cursor-pointer'></td> */}

                                                                {<td className={`sticky ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  ${showMultiCellConfigIndex === rowIndex ? "z-50" : "z-40"}  shadow-col left-0 min-w-[90px]  text-center text-base no-download-col`}>
                                                                    <div className='flex flex-row items-center px-2 justify-between'>


                                                                        <div
                                                                            onClick={() => {
                                                                                if ((expandAllRows) || (expandRow === rowIndex)) {
                                                                                    setExpandRow(null)
                                                                                    setExpandAllRows(false)
                                                                                }
                                                                                else {
                                                                                    setExpandRow(rowIndex)
                                                                                }
                                                                            }
                                                                            }
                                                                            className={`transform-all cursor-pointer hover:text-blue-500 ease-in-out duration-200 ${(expandAllRows) || (expandRow === rowIndex) ? "rotate-180 transform-all ease-in-out duration-200" : ""}`}>
                                                                            < MdOutlineExpandCircleDown />
                                                                        </div>

                                                                        <div className=''>

                                                                            <input type='checkbox'
                                                                                ref={(el) => (checkboxRefs.current[rowIndex] = el)} // Assign ref to each checkbox
                                                                                onKeyDown={(e) => handleKeyDown(e, rowIndex)} // Handle up/down keys
                                                                                id="childCheckbox"
                                                                                checked={selectedItems.includes(data)}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        // const correspondingObject = trasnposedData.get(data);
                                                                                        // checkedGraphData(correspondingObject, rowIndex)
                                                                                        addCheckIndex(rowIndex)
                                                                                        setSelectedItems(prevState => [...prevState, data])
                                                                                    }
                                                                                    else {
                                                                                        // const correspondingObject = trasnposedData.get(data);
                                                                                        // uncheckGraphData(correspondingObject, rowIndex)
                                                                                        removeCheckIndex(rowIndex)
                                                                                        setSelectedItems(selectedItems.filter(item => item !== data))
                                                                                    }
                                                                                }} className='text-center text-base ' />
                                                                        </div>
                                                                    </div>
                                                                </td>}

                                                                {
                                                                    supAdditionalFields.length === 0 &&
                                                                    <>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px`, left: `${90}px` }}
                                                                            className={`sticky z-40 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4    text-left  py-2 text-xs  `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[0].toString().length > 22 ? details[0] : ""}
                                                                        >
                                                                            {details[0] !== null ? details[0] : "Not Available"}
                                                                        </td>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                                            className={`sticky z-40 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4     text-left  py-2 text-xs `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[1].toString().length > 22 ? details[1] : ""}
                                                                        >
                                                                            {details[1] !== null ? details[1] : "Not Available"}
                                                                        </td>

                                                                    </>
                                                                }
                                                                {supAdditionalFields.length !== 0 && supAdditionalFields.map((item, index) => {
                                                                    const sp = (90 + ((index) * 180))

                                                                    const leftValue = `${sp}px`;

                                                                    // Calculate the sum of all previous columns' widths
                                                                    const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                                        .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                                        .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns


                                                                    return (
                                                                        <td
                                                                            // data-tooltip-id="my-tooltip" data-tooltip-content={details[index] !== null && details[index].toString().length > 22 ? details[index] : ""}
                                                                            style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                            className={`sticky z-40 text-left overflow-hidden px-3 py-2
                                                                         shadow-col   text-xs ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  `} key={index}>{details[index] !== null ? details[index] : "Not Available"}</td>
                                                                    )

                                                                })}




                                                                {trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {

                                                                    const isCommentPresent = result && result.FDATES.includes(Object.keys(trasnposedData.get(data))[index])
                                                                    let postID;
                                                                    let fdate = Object.keys(trasnposedData.get(data))[index]
                                                                    if (isCommentPresent) {
                                                                        postID = result.POST_ID
                                                                    }


                                                                    // for normal fqty
                                                                    if (item[supFields['base_3']] !== null) {
                                                                        return (
                                                                            <td
                                                                                onDoubleClick={() => {
                                                                                    if (checkAllChangesArrayAreEmpty(changes)) {
                                                                                        setChanges((prevState) => [
                                                                                            ...prevState,
                                                                                            createNewEntry(item.FQTY_USER, details, intervalFiltered[index], supAdditionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                        ]);
                                                                                    }
                                                                                }}
                                                                                className={`
                                                                                ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-30 scale-110 " : ""} transition-all duration-150 ease-in-out
                                                                                 text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1 ${changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], supAdditionalFields))
                                                                                        ? 'text-white'
                                                                                        : 'bg-gray-50 text-[#4285F4]'} ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}
                                                                            >
                                                                                {changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], supAdditionalFields)) ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        defaultValue={
                                                                                            item["FQTY"] !== null
                                                                                                ? item["FQTY"] === item["FQTY_USER"]
                                                                                                    ? parseFloat(Number(item["FQTY"]).toFixed(2)).toString()
                                                                                                    : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString()
                                                                                                : (0)
                                                                                        }
                                                                                        className={`p-2 h-full w-full bg-[#4285F4] ${item["FQTY"] !== null && item["FQTY"] !== item["FQTY_USER"]
                                                                                            ? "text-white bg-[#ed7d31]"
                                                                                            : ""}`}
                                                                                        onChange={(e) => {
                                                                                            e.target.className = `p-2 h-full w-full ${e.target.value !== item["FQTY"] ? "bg-[#ed7d31]" : "bg-[#4285F4]"
                                                                                                }`;
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            setChanges((prevState) => prevState.slice(0, -1)); // Remove last entry before updating

                                                                                            setChanges((prevState) => [
                                                                                                ...prevState,
                                                                                                createNewEntry(e.target.value, details, intervalFiltered[index], supAdditionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                            ]);
                                                                                        }}
                                                                                        autoFocus
                                                                                    />
                                                                                ) :
                                                                                    <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                        <p
                                                                                            onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "FQTY")}
                                                                                            className={`p-2 h-full w-full flex items-center justify-between `}
                                                                                        >
                                                                                            {Intl.NumberFormat('en-US', {
                                                                                                notation: "compact",
                                                                                                maximumFractionDigits: 1,
                                                                                            }).format(
                                                                                                parseFloat(Number(item[supFields['base_13']]).toFixed(2)).toString())
                                                                                            }
                                                                                            <Button
                                                                                                onClick={() => getSidePanelData(item, trasnposedData, data, index, "FQTY_ENGINE", postID, fdate)}
                                                                                                type='link'
                                                                                                className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'
                                                                                            >
                                                                                                <LuInfo />
                                                                                            </Button>
                                                                                        </p>

                                                                                        {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" && (
                                                                                            <CellRightClickMenu data={data} POST_ID={postID} FDATE={fdate} />
                                                                                        )}
                                                                                        {
                                                                                            isCommentPresent &&
                                                                                            <TopRightCorner rowIndex={rowIndex} colIndex={index} isSplit={item["isSplit"]} qtyType={"FQTY"} postID={postID} fdate={fdate} />
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        );
                                                                    }






                                                                })}
                                                            </tr>
                                                            {
                                                                ((expandAllRows) || (expandRow === rowIndex)) &&
                                                                <>
                                                                    {Object.keys(Expandchecklist).map((key) => (
                                                                        Expandchecklist[key] && (
                                                                            <CommonExpandedRow
                                                                                key={key}
                                                                                rowIndex={rowIndex}
                                                                                tdateVar={tdateVar}
                                                                                fields={supFields}
                                                                                name={key}
                                                                                additionalFields={supAdditionalFields}
                                                                                trasnposedData={trasnposedData}
                                                                                data={data}
                                                                                midIndex={midIndex}
                                                                            />
                                                                        )
                                                                    ))}

                                                                    <tr className=''>
                                                                        <td colSpan={2} className='sticky left-0 border-none w-full p-0 z-10'>
                                                                            <ExpandCheckboxList
                                                                                expandAllRows={expandAllRows}
                                                                                setExpandAllRows={setExpandAllRows}
                                                                                Expandchecklist={Expandchecklist}
                                                                                setExpandchecklist={setExpandchecklist}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            }

                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </>)}

                                <div
                                    onClick={() => scrollToOffsetCenter()}
                                    className='fixed bottom-3 hover:bg-blue-500 hover:text-white cursor-pointer h-3  left-[50%] flex text-base px-1 justify-center items-center z-50 transform  border-2 rounded-full shadow-sm bg-white border-blue-500'>
                                    <div className='rotate-45'>
                                        <PiArrowsInSimple />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    tableRef.current.scrollIntoView({ behavior: 'smooth' });
                                }} className='fixed bottom-4 hover:bg-blue-500 hover:text-white cursor-pointer w-3  right-3 flex text-base  justify-center items-center z-50 transform  border-2 rounded-full shadow-sm bg-white border-blue-500'>
                                    <div className=''>

                                        <TbArrowNarrowUp />
                                    </div>
                                </div>
                            </div>
                        }
                        {/* {checkBoxOverLay === true ?
                            <Overlay
                                supFields={columnsShown.length !== 0 ? columnsShown : [supFields["item"], supFields["location"], supFields["category"]]}
                                locationFilters={locationFilter}
                                productFilters={productFilter}
                                customerFilters={customerFilter}
                                uiMappingData={uiMappingData}
                                setsupFields={setColumnShown}
                                // setNext={setNext}
                                onClose={() => setCheckBoxOverlay(false)} /> : null} */}
                        {
                            overlayFlag === true ?
                                <EditOverlay

                                    // adjLoading={adjLoading}
                                    // setAdjLoading={setAdjLoading}
                                    getData={getSupplyData}
                                    getGraphData={getSupplyGraphData}
                                    changes={changes}
                                    salesChanges={salesChanges}
                                    eventChanges={eventChanges}
                                    marketChanges={marketChanges}
                                    promotionChanges={promotionChanges}
                                    consensusChanges={consensusChanges}
                                    setOverlayFlag={setOverlayFlag}
                                    setChanges={setChanges} /> : null
                        }
                        {/* {
                            inATTFilterPopUp &&
                            <FilterInATTPopUp />
                        } */}
                        {/* {
                            true &&
                            <DetailedProposal
                                setSelectedItem={setSelectedItem}
                                setSelectedLocation={setSelectedLocation}
                                setSelectedCustomer={setSelectedCustomer}
                                supFields={supFields}
                                setsupFields={setsupFields}
                                uiMappingData={uiMappingData}
                                supAdditionalFields={supAdditionalFields}
                                trasnposedData={trasnposedData}
                                midIndex={midIndex}
                                tdateVar={tdateVar}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        } */}
                        {
                            graphEditPopUp &&
                            <SupplyGraphEditPopUp />
                        }

                    </>
            }
            <Tooltip id="my-tooltip" />

        </div>
    )
}

export default SupplyView