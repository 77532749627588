import React from 'react'
import { useBase } from '../Layout/baseContext/BaseContext'

import LowestLevel from './components/LowestLevel';
import SidePanelComments from './components/SidePanelComments';
import TaskList from './components/TaskList';
import { TailSpin } from 'react-loader-spinner';


const SidePanel = () => {
  const { showSidePanel, planningSidePanelData, clickedQty, qtyMapping, sidePanelLoading } = useBase();



  return (



    <div className='w-full h-full flex flex-col gap-3 '>
      <div
        className={` fixed flex flex-col z-40 h-full  w-[5rem] shadow-side-panel ${showSidePanel ? "right-[7.5rem]" : "-right-96"
          } transition-all duration-300 ease-in-out `}
      ></div>
      <div className='w-full h-full flex flex-col gap-2 p-3'>


        {
          sidePanelLoading && planningSidePanelData === null ?
            <div className='h-[10%] flex justify-center bg-slate-100/70  backdrop-blur-lg rounded-2xl items-center z-50 box-shadow-panel'><TailSpin
              visible={true}
              height="20"
              width="20"
              color="#2B3674"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            /></div>
            :
            <div className='bg-slate-100/70  backdrop-blur-lg rounded-2xl w-full h-fit p-4 box-shadow-panel z-50'>
              {
                planningSidePanelData &&
                <>

                  <h3 className='text-sm font-semibold px-1 pb-1 flex justify-between items-center'>
                    <p>
                      {qtyMapping[clickedQty]}
                    </p>
                    <p> {planningSidePanelData.date}</p>

                  </h3>
                  <h3 className='flex justify-between bg-slate-100  rounded-md w-full font-semibold text-bodyTextBlue px-1 '>
                    <div className='flex gap-1'>

                      {
                        planningSidePanelData.details.map((item, index) => (
                          <span>
                            {item}
                            {index !== planningSidePanelData.details.length - 1 && " - "}
                          </span>
                        ))
                      }
                    </div>
                  </h3>
                </>
              }
            </div>
        }
        <TaskList />
        <LowestLevel />

        <SidePanelComments />

      </div>
    </div>

  )
}

export default SidePanel