import React, { useState } from 'react'
import { useBase } from '../../Layout/baseContext/BaseContext';
import { TailSpin } from 'react-loader-spinner';


const LowestLevel = () => {
    const { planningSidePanelData, sidePanelLoading, uiMappingData, clickedQty, qtyMapping } = useBase();
    const [tabSelected, setTabSelected] = useState('item')

    
  return (
    <>
    {
          sidePanelLoading ?
            <div className='h-[38%] flex justify-center bg-slate-100/70  backdrop-blur-lg rounded-2xl items-center z-50 box-shadow-panel'><TailSpin
              visible={true}
              height="20"
              width="20"
              color="#2B3674"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            /></div>
            :
            planningSidePanelData === null ?
              "No Data"
              :
              <div className='bg-slate-100/70  backdrop-blur-lg rounded-2xl w-full h-[38%] p-4 box-shadow-panel z-50'>
                <div className='h-full flex flex-col'>
                 
                  <div className='flex w-full justify-between font-semibold border-b pb-1 mt-1'>
                    <p onClick={() => setTabSelected("item")} className={`${tabSelected === "item" ? "text-blue-500     bg-blue-100 px-4" : "text-slate-500 hover:bg-blue-100"} transition-all duration-all ease-in-out rounded-full py-1 px-2     cursor-pointer`}>{uiMappingData[0]['ITEM']}</p>
                    <p onClick={() => setTabSelected("location")} className={`${tabSelected === "location" ? "text-blue-500 bg-blue-100 px-4" : "text-slate-500 hover:bg-blue-100"} transition-all duration-all ease-in-out rounded-full py-1 px-2 cursor-pointer`}>{uiMappingData[0]['LOCATION']}</p>
                    <p onClick={() => setTabSelected("customer")} className={`${tabSelected === "customer" ? "text-blue-500 bg-blue-100 px-4" : "text-slate-500 hover:bg-blue-100"} transition-all duration-all ease-in-out rounded-full py-1 px-2 cursor-pointer`}>{uiMappingData[0]['CUSTOMER']}</p>
                  </div>
                  <div className='relative h-full w-full text-[10px]'>
                    <div className='absolute h-full w-full overflow-y-scroll scroll'>
                      <div className='w-full flex flex-col h-full text-slate-500 px-2'>
                        {
                          tabSelected === 'item' &&
                          planningSidePanelData.item.map((data) => (
                            <div className='flex justify-between w-full py-1 border-b'>
                              <p>{data.ITEM}</p>
                              <p className={`${clickedQty === "FQTY_ENGINE" ?
                                  data["FQTY_ENGINE"] === data["FQTY_USER"] ? data['FQTY_ENGINE'] : "text-[#ed7d31]"
                                  :
                                  ""
                                }`}>

                                {Intl.NumberFormat('en-US', {
                                  notation: "compact",
                                  maximumFractionDigits: 1
                                }).format(

                                  clickedQty === "FQTY_ENGINE" ?
                                    data["FQTY_ENGINE"] === data["FQTY_USER"] ? data['FQTY_ENGINE'] : data["FQTY_USER"]
                                    :
                                    clickedQty === "FQTY" ? data["FQTY_ENGINE"] : data[clickedQty]

                                )}
                              </p>
                            </div>
                          ))
                        }
                        {
                          tabSelected === 'location' &&
                          planningSidePanelData.location.map((data) => (
                            <div className='flex justify-between w-full py-1 border-b'>
                              <p>{data.LOCATION}</p>
                              <p className={`${clickedQty === "FQTY_ENGINE" ?
                                  data["FQTY_ENGINE"] === data["FQTY_USER"] ? data['FQTY_ENGINE'] : "text-[#ed7d31]"
                                  :
                                  ""
                                }`}>{Intl.NumberFormat('en-US', {
                                  notation: "compact",
                                  maximumFractionDigits: 1
                                }).format(
                                  clickedQty === "FQTY_ENGINE" ?
                                    data["FQTY_ENGINE"] === data["FQTY_USER"] ? data['FQTY_ENGINE'] : data["FQTY_USER"]
                                    :
                                    clickedQty === "FQTY" ? data["FQTY_ENGINE"] : data[clickedQty]
                                )}</p>
                            </div>
                          ))
                        }
                        {
                          tabSelected === 'customer' &&
                          planningSidePanelData.customer.map((data) => (
                            <div className='flex justify-between w-full py-1 border-b'>
                              <p>{data.CUSTOMER}</p>
                              <p className={`${clickedQty === "FQTY_ENGINE" ?
                                  data["FQTY_ENGINE"] === data["FQTY_USER"] ? data['FQTY_ENGINE'] : "text-[#ed7d31]"
                                  :
                                  ""
                                }`}>{Intl.NumberFormat('en-US', {
                                  notation: "compact",
                                  maximumFractionDigits: 1
                                }).format(
                                  clickedQty === "FQTY_ENGINE" ?
                                    data["FQTY_ENGINE"] === data["FQTY_USER"] ? data['FQTY_ENGINE'] : data["FQTY_USER"]
                                    :
                                    clickedQty === "FQTY" ? data["FQTY_ENGINE"] : data[clickedQty]
                                )}</p>
                            </div>
                          ))
                        }


                      </div>
                    </div>
                  </div>
                </div>
              </div>

        }
    </>
  )
}

export default LowestLevel