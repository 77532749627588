import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const PieChart = ({ demandReport }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (!demandReport || !demandReport["Year_Over_Growth_Data"]) return;

        // Get Yearly Data
        const yearlyData = demandReport["Year_Over_Growth_Data"];

        // Filter past years (excluding 2025)
        const pastYears = yearlyData.filter((d) => d.Year < 2025);

        // Calculate average yearly growth
        const avgGrowth =
            pastYears.reduce((sum, d) => sum + d.growth_percentage, 0) / pastYears.length;

        // Get last year's sales (2024)
        const lastYearSales =
            yearlyData.find((d) => d.Year === 2024)?.total_sales || 0;

        // Calculate yearly target (based on last year + average growth)
        const yearlyTarget = lastYearSales * (1 + avgGrowth / 100);

        // Get total sales for the current year (2025)
        const currentYearSales =
            yearlyData.find((d) => d.Year === 2025)?.total_sales || 0;

        // Calculate achieved percentage
        const achievedPercentage = Math.min(
            100,
            Math.round((currentYearSales / yearlyTarget) * 100)
        );
        const remainingPercentage = 100 - achievedPercentage;

        // Update chart data
        setChartData({
            labels: ["Achieved", "Remaining"],
            datasets: [
                {
                    data: [achievedPercentage, remainingPercentage],
                    backgroundColor: ["#34a853", "#ffdb5c"],
                    hoverBackgroundColor: ["#21a845", "#edc535"],
                },
            ],
        });
    }, [demandReport]);

    return (
        <div className='bg-white rounded-md shadow-sm p-4 text-center'>
            <h3 className='text-xs font-semibold mb-2'>Target Completion</h3>
            {chartData ? (
                <div className="flex justify-center">
                    <Pie
                        data={chartData}
                        width={170}  // Smaller width
                        height={170} // Smaller height
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: true,
                                    position: "top", // Moves legend to the top
                                },
                            },
                        }}
                    />
                </div>
            ) : (
                <p>Loading chart...</p>
            )}
        </div>
    );
};

export default PieChart;
