import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, Tooltip);

const DemandGraph = ({ data, tableName }) => {
 
    // Filter out null or undefined x-axis values and corresponding data
  const filteredLabels = data['x-axis'].filter(label => label !== null && label !== undefined);
  const countData = filteredLabels.map(label => data['y-axis']['count'][label] || 0);
  const volumeData = filteredLabels.map(label => data['y-axis']['volume'][label] || 0);

  const chartData = {
    labels: filteredLabels,
    datasets: [
      {
        type: 'line',
        label: 'Volume',
        data: volumeData,
        borderColor: '#34a853',
        backgroundColor: '#34a853',
        borderWidth: 2,
        yAxisID: 'y2',
        fill: false,
      },
      {
        type: 'bar',
        label: 'Count',
        data: countData,
        backgroundColor: '#ffdb5c',
        barThickness: 10, // Decreased the bar thickness
        yAxisID: 'y1',
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y1: {
        type: 'linear',
        position: 'right',
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            return formatNumber(value);
          },
        },
      },
      y2: {
        type: 'linear',
        position: 'left',
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            return formatNumber(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
        labels: {
          color: 'black',
          font: {
            size: 12,
          },
        },
      },
    },
  };

  // Helper function to format numbers in international format
  const formatNumber = (num) => {
    if (num >= 1e9) {
      return Math.floor(num / 1e9) + 'B'; // Use Math.floor to remove decimals
    } else if (num >= 1e6) {
      return Math.floor(num / 1e6) + 'M';
    } else if (num >= 1e3) {
      return Math.floor(num / 1e3) + 'K';
    } else {
      return Math.floor(num); // Ensure integers for smaller numbers
    }
  };
  

  return (
    <div className='bg-white rounded-md shadow-sm p-4'>
      <p className='flex justify-center w-full font-semibold text-base'>{tableName}</p>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default DemandGraph;
