import React, { useEffect, useRef, useState } from 'react';
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext';
import { Button, Tour } from 'antd';
import { FaExternalLinkAlt } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { RiDatabase2Line } from "react-icons/ri";
import { MdOutlineEmojiEvents } from "react-icons/md";

const FirstUploadPage = ({ setFirstPage }) => {
    const { categories, setselectedCategory } = useUpload();
    const [isTourOpen, setIsTourOpen] = useState(false);
    const masterDataRef = useRef(null);

    useEffect(() => {
        // Check if showGuide is true in localStorage
        const showGuide = JSON.parse(localStorage.getItem('showGuide'));
        if (showGuide) {
            setIsTourOpen(true); // Start the tour if true
        }
    }, []);

    const handleCardClick = (cat) => {
        setFirstPage(false);
        setselectedCategory(cat);
        setIsTourOpen(false); // Close tour when clicking a category
    };

    const tourSteps = [
        {
            title: "Select master data",
            description: "Click here to upload master data.",
            target: () => masterDataRef.current,
        }
    ];

    return (
        <div className='flex flex-col justify-center h-full w-full items-center text-xs text-bodyTextBlue'>
            <Tour
                open={isTourOpen}
                onClose={() => setIsTourOpen(false)}
                steps={tourSteps}
                locale={{ close: "Close", }} // Change "Finish" to "Close"
            /> 
            <div className='flex flex-col gap-6 items-center border bg-white box-shadow p-20 rounded-lg'>
                <h1 className='text-4xl'>Upload data to make it easy for you</h1>
                <h3 className='text-2xl text-slate-400'>Select a category</h3>
                <div className='flex gap-8 justify-between'>
                    {
                        Object.entries(categories).map((cat) => (
                            <div
                                key={cat[0]}
                                onClick={() => handleCardClick(cat)}
                                ref={cat[0] === 'Master data' ? masterDataRef : null} // Reference for "Master Data"
                                className='bg-slate-50 w-52 h-28 cursor-pointer flex flex-col gap-2 justify-center items-center text-xl rounded-lg box-shadow hover:scale-110 transition-all duration-200'
                            >
                                {cat[0] === 'Master data' && <RiDatabase2Line className='text-3xl' />}
                                {cat[0] === 'Transactions' && <GrTransaction className='text-3xl' />}
                                {cat[0] === 'Initiatives' && <MdOutlineEmojiEvents className='text-3xl' />}
                                {cat[0] === 'External' && <FaExternalLinkAlt className='text-3xl' />}
                                <p>{cat[0]}</p>
                            </div>
                        ))
                    }
                </div>
                <div className='text-lg'>Or</div>
                <div>
                    <Button type='primary'>Download all templates</Button>
                </div>
            </div>
        </div>
    );
}

export default FirstUploadPage;
