import React, { useRef, useState, useEffect } from 'react';
import { usePlan } from './Layout/baseContext/planningContext/PlanningContext';
import { useBase } from './Layout/baseContext/BaseContext';
import { useAlert } from './Layout/baseContext/alertsContext/AlertsContext';
import api from '../api';
import { TailSpin } from 'react-loader-spinner';
import { Button } from 'antd';
import { FixedSizeList as List } from 'react-window';

const FilterButton = ({ children, setFilterString, filterString, locationFilters, customerFilters, productFilters, uiMappingData }) => {


  const filterSections = ["Product Master", "Location Master", "Customer Master", "Cluster"]
  const [selectedSection, setSelectedSection] = useState(0);
  const [subSelections, setSubSelections] = useState([]);
  const [subSelection, setSubSelection] = useState('');
  const [filterLoader, setFilterLoader] = useState(false)


  const {
    subSelectionFilterMap, setSubSelectionFilterMap,
    checkBoxArray, setCheckBoxArray,
    showOverlay, setShowOverlay,
    submitFilters,
    clearAllFilters
  } = usePlan();
  const { DFUunfilteredData, setDFUFullData, DFUFullData } = useAlert()

  const handleSubmit = () => {
    // const activeFilteredLFUs = filterFUArray(m1LFUdata, 'LFU');
    // const activeFilteredDFUs = filterFUArray(m1LFUdata, 'DFU');

    // console.log("----activeFilteredDFUs- in submit-----",activeFilteredDFUs);
    // setUniqueLFUs(activeFilteredLFUs)
    // setUniqueDFUs(activeFilteredDFUs)
    updateFilters()
    submitFilters()

  }

  const { filters, setFilters, setFilterSelectionSeq, activeFilters, setActiveFilters, uniqueDFUs, uniqueLFUs,
    activeItemFilters, setActiveItemFilters,
    activeLocationFilters, setActiveLocationFilters,
    activeCustomerFilters, setActiveCustomerFilters,
    activeDFUFilters, setActiveDFUFilters,
    activeLFUFilters, setActiveLFUFilters,
    enabledProductValues, setEnabledProductValues,
    enabledLocationValues, setEnabledLocationValues,
    enabledCustomerValues, setEnabledCustomerValues,
    m1LFUdata, setM1LFUdata, m1LFUfilteredData, setM1LFUfilteredData,
    startingSubSelection, setStartingSubSelection,
    setUniqueLFUs,
    setUniqueDFUs,
    selectedDFUs, setSelectedDFUs,
    selectedLFUs, setSelectedLFUs
  } = useBase()

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  useEffect(() => {
    if (Object.keys(productFilters).length === 0 || Object.keys(customerFilters).length === 0 || Object.keys(locationFilters).length === 0) {
      setLoading(true);
    }
    else {
      setSubSelections([{
        "parent": "Product Master",
        "selectors": Object.keys(productFilters[0])
      },
      {
        "parent": "Location Master",
        "selectors": Object.keys(locationFilters[0])
      },
      {
        "parent": "Customer Master",
        "selectors": Object.keys(customerFilters[0])
      },
      {
        "parent": "Cluster",
        "selectors": ["LFU", "DFU"]
      }
      ])

      setLoading(false);
    }
  }, [loading])

  const buttonRef = useRef(null);







  useEffect(() => {
    if (filterString === "") {
      clearAllFilters();
    }
  }, [filterString])
  const displaySelectedFilters = () => {
    return [...filters.entries()].map(([key, value], index) => (
      <div key={index} className="flex items-center pr-2 border-r border-black">

        <span className="font-bold">{
          key === 'LFU' || key === 'DFU' ?
            key
            :
            uiMappingData[0][key]
        }
          :
        </span>
        <span className="ml-2">{value.join(', ')}</span>
      </div>
    ));
  };

  const toggleOverlay = () => {
    setSubSelection('')
    setShowOverlay(!showOverlay);

  };

  const filterFUArray = (data, key) => {
    const uniqueValues = new Set();

    data.forEach(item => {
      if (item[key]) {
        uniqueValues.add(item[key]);
      }
    });

    return Array.from(uniqueValues);
  };


  useEffect(() => {
    setLoading(true)
    var newMap = new Map();
    if (Object.keys(productFilters).length !== 0 && Object.keys(locationFilters).length !== 0 && Object.keys(customerFilters).length !== 0 && m1LFUdata.length !== 0) {


      const activeFilteredItem = filterActiveData(productFilters, m1LFUdata, 'ITEM');
      const activeFilteredLocation = filterActiveData(locationFilters, m1LFUdata, 'LOCATION');
      const activeFilteredCustomer = filterActiveData(customerFilters, m1LFUdata, 'CUSTOMER');
      const activeFilteredLFUs = filterFUArray(m1LFUdata, 'LFU');
      const activeFilteredDFUs = filterFUArray(m1LFUdata, 'DFU');

      if (selectedSection === 0) {
        Object.keys(activeFilteredItem[0]).map((item) => {
          newMap.set(item, Array.from(new Set(activeFilteredItem.map((data) => data[item]))));
        })
      }
      else if (selectedSection === 1) {
        Object.keys(activeFilteredLocation[0]).map((item) => {
          newMap.set(item, Array.from(new Set(activeFilteredLocation.map((data) => data[item]))));
        })
      }
      else if (selectedSection === 2) {

        Object.keys(activeFilteredCustomer[0]).map((item) => {
          newMap.set(item, Array.from(new Set(activeFilteredCustomer.map((data) => data[item]))));
        })
      }
      // else if (selectedSection === 3) {
      setUniqueLFUs(activeFilteredLFUs)
      setUniqueDFUs(activeFilteredDFUs)
      // }

      const m1LFUDFUs = new Set(m1LFUdata.map(item => item.DFU));

      // Filter DFUFullData to include only entries whose DFU exists in m1LFUData
      const filteredDFUFullData = DFUFullData.filter(item => m1LFUDFUs.has(item.DFU));
      setDFUFullData(filteredDFUFullData)


      setSubSelectionFilterMap(newMap);
      setLoading(false)

      // setSubSelection(newMap.get([...newMap.keys()][0]))
    }
  }, [m1LFUdata])

  useEffect(() => {
    if (filters.size === 0) {
      setStartingSubSelection('')
    }
    if (filters.size > 0) {
      const firstKey = filters.keys().next().value
      setStartingSubSelection(firstKey)
    }


  }, [filters])

  useEffect(() => {
    setSubSelection('')
  }, [selectedSection])

  const filterLFUDataByFU = (selectedFUs, filteredLFUDataByCustomerData, key) => {
    if (selectedFUs.length === 0) {
      return filteredLFUDataByCustomerData
    }
    else {
      const result = filteredLFUDataByCustomerData.filter((item) => selectedFUs.includes(item[key]))
      return result;
    }
  }


 


  const updateFilters = () => {
    const productValues = productFilters
      .filter(product => {
        return [...filters.keys()].every(key => {
          if (key.startsWith('I') && filters.get(key)?.length) {
            // Ensure that only the filters related to the current subSelection are considered
            return filters.get(key).includes(product[key])
          }
          return true; // Ignore other keys
        });
      })

    const filteredLFUDataByItemData = filterLFUData(productValues, m1LFUdata, 'ITEM');

    const locationValues = locationFilters
      .filter(location => {
        return [...filters.keys()].every(key => {
          if ((key.startsWith('LO') || key.startsWith('LA')) && filters.get(key)?.length) {
            // Ensure that only the filters related to the current subSelection are considered
            return filters.get(key).includes(location[key])
          }
          return true; // Ignore other keys
        });
      })

    const filteredLFUDataByLocationData = filterLFUData(locationValues, filteredLFUDataByItemData, 'LOCATION');

    const customerValues = customerFilters
      .filter(customer => {
        return [...filters.keys()].every(key => {
          if (key.startsWith('C') && filters.get(key)?.length) {
            // Ensure that only the filters related to the current subSelection are considered
            return filters.get(key).includes(customer[key])
          }
          return true; // Ignore other keys
        });
      })


    const filteredLFUDataByCustomerData = filterLFUData(customerValues, filteredLFUDataByLocationData, 'CUSTOMER');






    const filteredLFUDataByLFU = filterLFUDataByFU(selectedLFUs, filteredLFUDataByCustomerData, 'LFU')

    const filteredLFUDataByDFU = filterLFUDataByFU(selectedDFUs, filteredLFUDataByLFU, 'DFU')


    setM1LFUdata(filteredLFUDataByDFU)
  }

  useEffect(() => {
    updateFilters()
  }, [subSelection]);

  // useEffect(() => {

  // },[subSelection])










  // Function to filter the m1LFUdata array
  const filterLFUData = (activeFilters, lfuData, key) => {
    // Extract the ITEM values from activeItemFilters for quick lookup
    const active = new Set(activeFilters.map((filter) => filter[key]));
    // Filter m1LFUdata based on ITEM presence in activeItems
    return lfuData.filter((data) => active.has(data[key]));
  };

  // Function to filter the m1LFUdata array
  const filterActiveData = (activeFilters, lfuData, key) => {
    // Extract the ITEM values from activeItemFilters for quick lookup
    const active = new Set(lfuData.map((filter) => filter[key]));
    // Filter m1LFUdata based on ITEM presence in activeItems
    return activeFilters.filter((data) => active.has(data[key]));
  };



  const handleEditButton = () => {
    const cachedm1LFUdata = localStorage.getItem("m1LFUdata");

    setM1LFUdata(JSON.parse(cachedm1LFUdata))
  }


  const LFURow = ({ index, style, data }) => {
    const items = data[index]; // Each row contains a pair of items

    return (
      <div style={style} className="flex flex-row justify-between items-center">
        {items.map((item) => (
          item && (
            <div
              key={item}
              className="flex flex-row text-gray-700 w-1/2" // Each item takes 50% width
            >
              <input
                id={item}
                type="checkbox"
                className="cursor-pointer"
                checked={checkBoxArray.includes(item)}
                onChange={(e) => {
                  let updatedMap = new Map(filters);
                  let arr = filters.get(subSelection) || [];
                  if (e.target.checked) {
                    arr.push(item);
                    setCheckBoxArray((prevState) => [...prevState, item]);
                    setSelectedLFUs((prev) => [...prev, item]);
                  } else {
                    arr = arr.filter((data) => data !== item);
                    setCheckBoxArray((prevState) => prevState.filter((data) => data !== item));
                    setSelectedLFUs((prev) => prev.filter((data) => data !== item));

                    if (arr.length === 0) {
                      updatedMap.delete(subSelection);
                    }
                  }
                  if (arr.length > 0) {
                    updatedMap.set(subSelection, arr);
                  }
                  setFilters(updatedMap);
                }}
              />
              <label htmlFor={item} className="cursor-pointer">{item}</label>
            </div>
          )
        ))}
      </div>
    );
  };

  const DFURow = ({ index, style, data }) => {
    const items = data[index]; // Each row contains a pair of items

    return (
      <div style={style} className="flex flex-row justify-between items-center">
        {items.map((item) => (
          item && (
            <div
              key={item}
              className="flex flex-row text-gray-700 w-1/2" // Each item takes 50% width
            >
              <input
                id={item}
                type="checkbox"
                onChange={(e) => {
                  // if (!isEnabled) return; // Prevent interaction if disabled

                  // Clone the current filters map
                  let updatedMap = new Map(filters);

                  // Get the array of values for the current key (subSelection)
                  let arr = filters.get(subSelection) || [];

                  if (e.target.checked) {
                    // Add the item to the array
                    arr.push(item);
                    setCheckBoxArray((prevState) => [...prevState, item]);
                    setSelectedDFUs((prev) => [...prev, item]);

                  } else {
                    // Remove the item from the array
                    arr = arr.filter((data) => data !== item);
                    setCheckBoxArray((prevState) => prevState.filter((data) => data !== item));
                    setSelectedDFUs((prev) => prev.filter((data) => data !== item));

                    // If the array becomes empty, remove the key from the map
                    if (arr.length === 0) {
                      updatedMap.delete(subSelection);
                    }
                  }

                  // Update the map if the key is still valid
                  if (arr.length > 0) {
                    updatedMap.set(subSelection, arr);
                  }

                  // Update the filters state
                  setFilters(updatedMap);
                }}
                checked={checkBoxArray.includes(item)}
                // disabled={!isEnabled || !isCrossEnabled} 
                className="cursor-pointer"
              />
              <label htmlFor={item} className="cursor-pointer">{item}</label>
            </div>
          )
        ))}
      </div>
    );
  };

  const NormalRow = ({ index, style, data }) => {
    const items = data[index]; // Each row contains multiple items

    return (
      <div style={style} className="flex flex-row justify-start items-center">
        {items.map((item) => (
          item && (
            <div
              key={item}
              className="flex flex-row text-gray-700 w-1/2" // Adjust width for items in a row
            >
              <input
                id={item}
                type="checkbox"
                checked={checkBoxArray.includes(item)}
                onChange={(e) => {
                  let updatedMap = new Map(filters);
                  let arr = filters.get(subSelection) || [];

                  if (e.target.checked) {
                    arr.push(item);
                    setCheckBoxArray((prevState) => [...prevState, item]);
                  } else {
                    arr = arr.filter((data) => data !== item);
                    setCheckBoxArray((prevState) => prevState.filter((data) => data !== item));

                    if (arr.length === 0) {
                      updatedMap.delete(subSelection);
                    }
                  }

                  if (arr.length > 0) {
                    updatedMap.set(subSelection, arr);
                  }

                  setFilters(updatedMap);
                }}
                className="cursor-pointer"
              />
              <label htmlFor={item} className="cursor-pointer">{item}</label>
            </div>
          )
        ))}
      </div>
    );
  };

  const itemsPerRow = 3;

  // Filter LFUs based on search query
  const filteredLFUs = uniqueLFUs.filter(
    (item) => item !== null && item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Group LFUs into pairs for rendering
  const groupedLFUs = [];
  for (let i = 0; i < filteredLFUs.length; i += itemsPerRow) {
    groupedLFUs.push(filteredLFUs.slice(i, i + itemsPerRow));
  }

  // Filter DFUs based on search query
  const filteredDFUs = uniqueDFUs.filter(
    (item) => item !== null && item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Group DFUs into pairs for rendering
  const groupedDFUs = [];
  for (let i = 0; i < filteredDFUs.length; i += itemsPerRow) {
    groupedDFUs.push(filteredDFUs.slice(i, i + itemsPerRow));
  }



  //normal
  // Filter items based on the search query
  var filteredItems;
  var groupedItems;
  if (subSelectionFilterMap.size !== 0 && subSelectionFilterMap.get(subSelection) !== undefined) {


    filteredItems = subSelectionFilterMap.size !== 0 && subSelectionFilterMap.get(subSelection)
      .filter(item => item !== null && item.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => {
        const parseValue = (value) => {
          return value.match(/(\d+|\D+)/g)?.map(part =>
            isNaN(part) ? part.toLowerCase() : Number(part)
          ) || [];
        };

        const aParts = parseValue(a);
        const bParts = parseValue(b);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
          const aPart = aParts[i] ?? '';
          const bPart = bParts[i] ?? '';

          if (typeof aPart === 'number' && typeof bPart === 'number') {
            if (aPart !== bPart) return aPart - bPart;
          } else {
            const comparison = aPart.toString().localeCompare(bPart.toString());
            if (comparison !== 0) return comparison;
          }
        }

        return 0;
      });

    // Group filtered items into pairs
    groupedItems = [];
    for (let i = 0; i < filteredItems.length; i += itemsPerRow) {
      groupedItems.push(filteredItems.slice(i, i + itemsPerRow));
    }
  }

  return (
    <div className=" flex items-center z-50 text-bodyTextBlue" style={{ zIndex: 200 }}>
      <button
        ref={buttonRef}
        className={`bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 text-sm shadow-md rounded-full`}
        onClick={toggleOverlay}
      >
        {children}
      </button>
      {showOverlay ? (

        <div
          className="fixed mt-3 mr-20 bg-white p-4 w-10/12 h-[500px] rounded-lg shadow-lg filter-overlay z-100"
          style={{
            top: buttonRef.current.offsetTop + buttonRef.current.offsetHeight,
            right: 0,
            left: 'auto',
          }}
        >
          <div className='flex flex-col space-y-2 h-full'>
            <h3 className='text-lg font-semibold '>Filters</h3>
            <input
              type="text"
              placeholder="Search filters..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 py-3 rounded-lg text-sm focus:outline-none"
            />
            <div className="flex flex-row   border-b-gray-200 border-b items-center space-x-2 justify-start">
              {filterSections.map((item, index) => {
                return (
                  <div
                    className={` flex flex-row py-2 space-x-2 hover:text-blue-500 cursor-pointer items-center px-2 ${index === selectedSection ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                    onClick={() => {
                      setSelectedSection(index)
                    }}
                  >
                    {/* <div className={`w-4 h-4 rounded-md  ${selectedSection === index ? 'border border-blue-500' : 'border border-gray-300'} `}></div> */}
                    <div className=' text-sm font-semibold'>{item}</div>
                  </div>)

              })}

            </div>
            <div className='flex flex-col h-[200px]'>
              <p className='font-semibold text-gray-600 border-b border-gray-200 pb-2 pl-2'>Select filters to apply</p>
              <div className='flex items-start h-full'>
                <div className='w-1/5  relative h-full '>
                  <div className='absolute overflow-y-scroll h-full w-full'>

                    <div className=' h-full flex flex-col border-r border-r-gray-200 overflow-y-scroll overflow-x-hidden scroll'>
                      {subSelections.map((item, index) => {
                        if (item["parent"] === filterSections[selectedSection]) {
                          return item["selectors"]

                            .map((name) => {

                              return (
                                <div
                                  className={`flex flex-row  m-0 px-2 py-2 border-b justify-between hover:bg-slate-100  items-center text-xs w-full cursor-pointer rounded-md ${subSelection === name ? 'bg-slate-200' : ''}`}
                                  onClick={() => setSubSelection(name)}
                                >

                                  <h3 className="font-bold ">
                                    {
                                      filterSections[selectedSection] === "Cluster" ?
                                        name :
                                        uiMappingData[0][name]
                                    }
                                  </h3>

                                </div>
                              );
                            });
                        }

                      })}
                    </div>
                  </div>
                </div>
                <div className='w-4/5 flex  flex-col p-2  space-y-2 h-full'>
                  <h5 className='text-sm flex items-center gap-3'>
                    {/* <span>{checkBoxArray.length} </span>
                    <p>
                      Total filters selected
                    </p> */}
                    <div className="flex">
                      <input
                        id="select-all"
                        type="checkbox"
                        onChange={(e) => {
                          const isChecked = e.target.checked;

                          // Get the currently visible items based on the search query
                          const visibleItems =
                            subSelectionFilterMap.get(subSelection)?.filter((item) =>
                              item !== null && item.toLowerCase().includes(searchQuery.toLowerCase())
                            ) || [];

                          if (isChecked) {
                            // Add all visible items to filters and checkBoxArray
                            setFilters((prevFilters) => {
                              const updatedFilters = new Map(prevFilters);
                              const currentSelection = updatedFilters.get(subSelection) || [];
                              updatedFilters.set(
                                subSelection,
                                Array.from(new Set([...currentSelection, ...visibleItems]))
                              );
                              return updatedFilters;
                            });

                            setCheckBoxArray((prevArray) =>
                              Array.from(new Set([...prevArray, ...visibleItems]))
                            );
                          } else {
                            // Remove all visible items from filters and checkBoxArray
                            setFilters((prevFilters) => {
                              const updatedFilters = new Map(prevFilters);
                              const currentSelection = updatedFilters.get(subSelection) || [];
                              updatedFilters.set(
                                subSelection,
                                currentSelection.filter((item) => !visibleItems.includes(item))
                              );
                              return updatedFilters;
                            });

                            setCheckBoxArray((prevArray) =>
                              prevArray.filter((item) => !visibleItems.includes(item))
                            );
                          }
                        }}
                        checked={
                          // Check if all visible items are selected
                          [...subSelectionFilterMap.keys()].includes(subSelection) && subSelectionFilterMap
                            .get(subSelection)
                            .filter((item) =>
                              item !== null && item.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            ?.every((item) => checkBoxArray.includes(item)) || false
                        }
                      />
                      <label htmlFor="select-all" className="cursor-pointer font-semibold">
                        Select All
                      </label>
                    </div>
                    {
                      startingSubSelection === subSelection && filters.size !== 0 &&
                      <Button type='primary' className='h-6' onClick={() => handleEditButton()}>
                        Edit
                      </Button>
                    }
                  </h5>
                  {/* <div className='relative h-full w-full'>

                    <div className='absolute overflow-y-scroll scroll h-full w-full'> */}
                  {
                    loading ?
                      <div className='h-full flex justify-center items-center'><TailSpin
                        visible={true}
                        height="30"
                        width="30"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                      /></div>
                      :
                      <div className="grid grid-cols-1 justify-start items-start h-full w-full">
                        {
                          subSelection === "LFU" &&

                          <List
                            height={130} // Height of the container
                            itemCount={groupedLFUs.length} // Total number of grouped rows
                            itemSize={25} // Height of each row
                            width="100%" // Width of the container
                            itemData={groupedLFUs} // Pass grouped data
                          >
                            {LFURow}
                          </List>


                        }
                        {
                          subSelection === "DFU" &&
                          <List
                            height={130} // Height of the container
                            itemCount={groupedDFUs.length} // Total number of grouped rows
                            itemSize={25} // Height of each row
                            width="100%" // Width of the container
                            itemData={groupedDFUs} // Pass grouped data
                          >
                            {DFURow}
                          </List>

                        }
                        {
                          subSelection !== "DFU" && subSelection !== "LFU" && groupedItems &&
                          <List
                            height={130} // Container height
                            itemCount={groupedItems.length} // Total number of grouped rows
                            itemSize={25} // Height of each row
                            width="100%" // Container width
                            itemData={groupedItems} // Pass grouped data
                          >
                            {NormalRow}
                          </List>
                        }

                      </div>
                  }
                  {/* </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="p-2 border-t border-t-gray-200">
              <h3 className="text-lg font-semibold ">Selected Filters</h3>


              <div className="flex flex-row space-x-2 items-center max-h-10 overflow-y-scroll scroll">
                {displaySelectedFilters()}
              </div>


            </div>
            <div className='flex flex-row items-center space-x-4 justify-end w-full p-2 '>
              <button className="px-12 py-2 font-semibold border border-red-500 hover:bg-red-50 rounded-md text-red-500" onClick={clearAllFilters}>Clear All</button>
              <button className='px-12 py-2 font-semibold border bg-slate-400 hover:bg-slate-500 rounded-md text-white' onClick={toggleOverlay}>Cancel</button>
              <button className='px-12 py-2 font-semibold bg-blue-500 hover:bg-blue-600 rounded-md text-white' onClick={handleSubmit}>Apply</button>
            </div>
          </div>
        </div>) : null
      }

    </div>
  );
};

export default FilterButton;