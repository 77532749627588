import React, { createContext, useState, useContext, useEffect } from 'react';
import { useBase } from '../BaseContext';
import { message } from 'antd';
import api from '../../../../api';

const SupplyContext = createContext();

export const SupplyProvider = ({ children }) => {
  const { selectedMenu , tdateVar, filterString} = useBase();

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [supFields, setsupFields] = useState({
    "base_1": "HOLDING_COST",
    "base_2": "POERQTY",
    "base_3": "TOTAL_COST",
    "base_4": "PROFIT",
    "base_5": "REVENUE",
    "base_6": "LOST_SALES_COST",
    "base_7": "PRODUCTION_COST",
    "base_8": "PRQTY",
    "base_9": "BOH",
    "base_10": "EOH",
    "base_11": "FULLFILLED_DEMAND",
    "base_12": "UNFULLFILLED_DEMAND",
    "base_13": "FQTY_ENGINE",
    "interval": "FDATE",
    "item": "ITEM",
    "location": "LOCATION",
  })

  const [supFieldsMapping, setSupFieldsMapping] = useState({
    "HOLDING_COST":"Holding cost",
    "POERQTY": "Expected receipts",
    "TOTAL_COST":"Total cost",
    "PROFIT":"Profit",
    "REVENUE":"Revenue",
    "LOST_SALES_COST":"Lost sales cost",
    "PRODUCTION_COST":"Production cost",
    "PRQTY":"Production",
    "BOH":"Beginning on hand",
    "EOH":"Ending on hand",
    "FULLFILLED_DEMAND":"Fulfilled demand",
    "UNFULLFILLED_DEMAND":"Unfulfilled demand",
    "FQTY_ENGINE":"Engine",
  })

  const [supAdditionalFields, setSupAdditionalFields] = useState([])

  const [supplyData, setSupplyData] = useState([])
  const [supplyGraphData, setSupplyGraphData] = useState([])
  const [supplyIntervals, setsupplyIntervals] = useState([])
  const [trasnposedData, setTransposedData] = useState(new Map());
  const [checkIndexes, setCheckIndexes] = useState([]);
  const [checkboxCount, setCheckboxCount] = useState(-1);
  const [selectedItems, setSelectedItems] = useState([]);

  const [totalSqty, setTotalSqty] = useState(null)
  const [totalFqty, setTotalFqty] = useState(null)
  const [graphDisplayData, setGraphDisplayData] = useState({
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    data5: [],
    data6: [],
    data7: [],
    data8: [],
    data9: [],
    data10: [],
    data11: [],
    data12: [],
    dates: [],
  });
  const [callingAPI, setcallingAPI] = useState(false)
  const [page, setPage] = useState(2);
  const [quickFilters, setQuickFilters] = useState(null)
  const [columnsShown, setColumnShown] = useState([])
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    rowIndex: null,
    colIndex: null,
    isSplit: false,
    qtyType: null,
  });
  const [checkCommentsData, setCheckCommentsData] = useState(null)
  const [inATTFilterPopUp, setinATTFilterPopUp] = useState(false)
  const [filterATT, setFilterATT] = useState(null);
  const [graphEditPopUp, setGraphEditPopUp] = useState(false)


  const [supplyLoading, setSupplyLoading] = useState(false)
  const [supplyGraphLoading, setSupplyGraphLoading] = useState(false)
  const [showGraphs, setShowGraphs] = useState({
    [supFields['base_1']]: false,
    [supFields['base_2']]: false,
    [supFields['base_3']]: false,
    [supFields['base_4']]: false,
    [supFields['base_5']]: false,
    [supFields['base_6']]: false,
    [supFields['base_7']]: false,
    [supFields['base_8']]: true,
    [supFields['base_9']]: false,
    [supFields['base_10']]: false,
    [supFields['base_11']]: false,
    [supFields['base_12']]: false,
    [supFields['base_13']]: true
  });

  const [graphTypes, setGraphTypes] = useState({
    [supFields['base_1']]: 'line',
    [supFields['base_2']]: 'bar',
    [supFields['base_3']]: 'line',
    [supFields['base_4']]: 'line',
    [supFields['base_5']]: 'line',
    [supFields['base_6']]: 'line',
    [supFields['base_7']]: 'line',
    [supFields['base_8']]: 'bar',
    [supFields['base_9']]: 'bar',
    [supFields['base_10']]: 'line',
    [supFields['base_11']]: 'line',
    [supFields['base_12']]: 'line',
    [supFields['base_13']]: 'line',
  });




  const getSupplyData = async () => {
    setSupplyLoading(true)

    setcallingAPI(true)
    try {
      const fields = Object.values(supFields).join(",");

      const response = await api.get(`/api/s0_supply_cube/?OBJECTIVE_TYPE=1&fields=${fields}${filterString}`);

      setSupplyData(response.data.results)
    }
    catch (e) {
      console.log(e);
    }
    setcallingAPI(false)
    setSupplyLoading(false)


  }


  function processDates(data) {
    // Use a Map to remove duplicates (keyed by FDATE)
    const uniqueDatesMap = new Map();
    data.forEach(item => {
      uniqueDatesMap.set(item.FDATE, item);
    });

    // Convert the Map back to an array and sort by FDATE
    const sortedData = Array.from(uniqueDatesMap.values()).sort(
      (a, b) => new Date(a.FDATE) - new Date(b.FDATE)
    );

    return sortedData;
  }

  const getSupplyIntervals = async () => {
    try {


      const response = await api.get("/api/s0_supply_cube/?OBJECTIVE_TYPE=1&fields=FDATE");
      const responseData = response.data;
      const uniqueSortedDates = processDates(responseData.results)
      setStartDate(tdateVar)

      setEndDate(uniqueSortedDates[uniqueSortedDates.length - 1][supFields['interval']])
      setsupplyIntervals(uniqueSortedDates);



    } catch (error) {
      console.log(error.message);
    }
  }

  const getSupplyGraphData = async () => {
    setSupplyGraphLoading(true)
    try {
      const filteredFields = Object.values(supFields).filter((item) => item !== 'ITEM' && item !== 'LOCATION')
      const fields = Object.values(filteredFields).join(",");

      const response = await api.get(`/api/s0_supply_cube/?OBJECTIVE_TYPE=1&fields=${fields}${filterString}`);
      const responseData = response.data;
      setSupplyGraphData(responseData);


    } catch (error) {
      // alert(error.message);
      console.log(error);
    }
    setSupplyGraphLoading(true)

  }

  useEffect(() => {
    if (selectedMenu === 'supplyViewBase') {

      getSupplyIntervals();
      getSupplyData();
      getSupplyGraphData();

      
    }
  }, [selectedMenu,supFields, filterString])

  const uncheckAll = () => {
    setCheckIndexes([]);
    setCheckboxCount(-1)
    setSelectedItems([]);
    populateGraphDisplayData(supplyGraphData);
  }

  const populateGraphDisplayData = (supplyGraphData) => {
    const newGraphData = {
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      data7: [],
      data8: [],
      data9: [],
      data10: [],
      data11: [],
      data12: [],
      data13: [],
      dates: [],
    };

    if (supplyGraphData.length !== 0) {
      for (let x = 0; x < supplyGraphData.results.length; x++) {
        const currentDate = new Date(supplyGraphData.results[x][supFields.interval]);
        if (new Date(startDate) <= currentDate && new Date(endDate) >= currentDate) {
          newGraphData.data1.push(supplyGraphData.results[x][supFields.base_1]);
          newGraphData.data2.push(supplyGraphData.results[x][supFields.base_2]);
          newGraphData.data3.push(supplyGraphData.results[x][supFields.base_3]);
          newGraphData.data4.push(supplyGraphData.results[x][supFields.base_4]);
          newGraphData.data5.push(supplyGraphData.results[x][supFields.base_5]);
          newGraphData.data6.push(supplyGraphData.results[x][supFields.base_6]);
          newGraphData.data7.push(supplyGraphData.results[x][supFields.base_7]);
          newGraphData.data8.push(supplyGraphData.results[x][supFields.base_8]);
          newGraphData.data9.push(supplyGraphData.results[x][supFields.base_9]);
          newGraphData.data10.push(supplyGraphData.results[x][supFields.base_10]);
          newGraphData.data11.push(supplyGraphData.results[x][supFields.base_11]);
          newGraphData.data12.push(supplyGraphData.results[x][supFields.base_12]);
          newGraphData.data13.push(supplyGraphData.results[x][supFields.base_13]);
          newGraphData.dates.push(supplyGraphData.results[x][supFields.interval]);
        }
      }
    }

    // Update state with the new data
    setGraphDisplayData(newGraphData);
  };


  const handleOpenMenu = (e, rowIndex, colIndex, isSplit, qtyType) => {
    e.preventDefault();
    setContextMenu({ visible: true, rowIndex, colIndex, isSplit, qtyType });
  };
  return (
    <SupplyContext.Provider value={{
      getSupplyData, getSupplyGraphData, getSupplyIntervals,
      supplyData, setSupplyData,
      supplyGraphData, setSupplyGraphData,
      supFields, setsupFields,
      supplyIntervals, setsupplyIntervals,
      supplyLoading, setSupplyLoading,
      supplyGraphLoading,
      trasnposedData, setTransposedData,
      checkIndexes, setCheckIndexes,
      checkboxCount, setCheckboxCount,
      uncheckAll,
      selectedItems, setSelectedItems,
      populateGraphDisplayData,
      graphDisplayData, setGraphDisplayData,
      callingAPI, setcallingAPI,
      page, setPage,
      totalSqty, setTotalSqty,
      totalFqty, setTotalFqty,
      quickFilters, setQuickFilters,
      columnsShown, setColumnShown,
      contextMenu, setContextMenu,
      handleOpenMenu,
      checkCommentsData, setCheckCommentsData,
      inATTFilterPopUp, setinATTFilterPopUp,
      filterATT, setFilterATT,
      graphEditPopUp, setGraphEditPopUp,
      supAdditionalFields, setSupAdditionalFields,
      startDate, setStartDate,
      endDate, setEndDate,
      showGraphs,graphTypes, setGraphTypes,
      setShowGraphs,
      supFieldsMapping, setSupFieldsMapping
    }}>
      {children}
    </SupplyContext.Provider>
  );
};

export const useSupply = () => useContext(SupplyContext);