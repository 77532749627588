import { Layout } from 'antd'
import React from 'react'
import HeaderComponent from '../Layout/Header/HeaderComponent'
import { Content } from 'rsuite'
import SupplyView from './components/supplyView/SupplyView'
import { useBase } from '../Layout/baseContext/BaseContext'

const SupplyViewBase = ({
    setNext,
    callingAPI,
    setPage,
    page,
    adjLoading,
    setAdjLoading,
    filterString,
    locationFilters,
    productFilters,
    customerFilters,
    getData,
    getGraphData,
    columnsShown,
    setColumnShown,
    setCheckBoxString,
    graphLoading,
    tdateVar,
    startDate,
    setStartDate,
    intervalList,
    graphData,
    fields,
    setFields,
    planningData,
    tableLoading,
    additionalFields,
    setFilterString,
    setSelectedArea,
    uiMappingData, handleRefresh,
}) => {

    const {selectedArea} = useBase()
    return (
        <Layout className="h-full w-full overflow-hidden" style={{ background: "#F9F9FC" }}>
            <header
                className="bg-[#f9f9fc] w-full h-full z-30"
            >
                {
                    Object.keys(locationFilters).length > 0 && Object.keys(productFilters).length > 0 && Object.keys(customerFilters).length > 0
                        ?
                        <HeaderComponent selectedArea={selectedArea} uiMappingData={uiMappingData} filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilters} productFilters={productFilters} customerFilters={customerFilters} setSelectedArea={setSelectedArea} /> : null
                }

            </header>
            <Content
                className="content   w-full h-full overflow-scroll"
            >
                {

                    Object.keys(locationFilters).length > 0 && Object.keys(productFilters).length > 0 && Object.keys(customerFilters).length > 0 ?

                        <SupplyView
                            />
                        : null
                }
            </Content>
        </Layout>)
}

export default SupplyViewBase