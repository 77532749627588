import React, { useState, useCallback } from 'react';
import {
    ReactFlow,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Controls,
    MiniMap,
    Background,
    Panel,
    useReactFlow,
    useNodesState,
    useEdgesState
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Button, Input } from 'antd';

let nodeId = 0;
const edgeOptions = {
    animated: false,
    style: {
        stroke: '#7d8293',
    },
};
const connectionLineStyle = { stroke: '#7d8293' };

const FlowDiagram = () => {
    const [variant, setVariant] = useState('cross');
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [editingNode, setEditingNode] = useState(null);
    const [newLabel, setNewLabel] = useState('');

    const reactFlowInstance = useReactFlow();

    const onNodeSelect = (event, node) => {
        setSelectedNode(node);
    };

    const onNodeDoubleClick = (event, node) => {
        setEditingNode(node.id);
        setNewLabel(node.data.label);
    };

    const onClick = useCallback(() => {
        const id = `${++nodeId}`;
        const newNode = {
            id,
            position: {
                x: Math.random() * 250,
                y: Math.random() * 250,
            },
            data: {
                label: `Node ${id}`,
            },
        };
        setNodes((nds) => nds.concat(newNode));
    }, [setNodes]);

    const onDelete = () => {
        if (selectedNode) {
            setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id));
            setEdges((eds) => eds.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id));
            setSelectedNode(null);
        }
    };

    const onLabelChange = (event) => {
        setNewLabel(event.target.value);
    };

    const onLabelSubmit = () => {
        setNodes((nds) =>
            nds.map((node) =>
                node.id === editingNode ? { ...node, data: { ...node.data, label: newLabel } } : node
            )
        );
        setEditingNode(null);
    };

    const nodeColor = (node) => {
        switch (node.type) {
            case 'input':
                return '#6ede87';
            case 'output':
                return '#6865A5';
            default:
                return '#ff0072';
        }
    };

    return (
        <div className='flex flex-col pt-4 w-full min-h-screen text-xs pr-4 pl-[3.8rem] text-bodyTextBlue pb-3 '>
            <div>
                <h1 className='text-base font-semibold'>Build Flow</h1>
            </div>
            <div className='react-flow-div flex flex-col h-full w-full  bg-white box-shadow rounded-lg mt-4 '>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    defaultEdgeOptions={edgeOptions}
                    connectionLineStyle={connectionLineStyle}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges])}
                    onNodeClick={onNodeSelect}
                    onNodeDoubleClick={onNodeDoubleClick}
                    fitView
                >
                    <Controls />
                    <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
                    <Background color="#ccc" variant={variant} />
                    <Panel>
                        <div className='flex justify-between items-center'>
                            <div>
                                <Button onClick={onClick} type='primary'>
                                    Add Node
                                </Button>
                                <Button onClick={onDelete} type='primary' disabled={!selectedNode} className="ml-2 !bg-orange-400">
                                    Delete Node
                                </Button>
                            </div>
                        </div>
                    </Panel>
                    <Panel position='top-right'>
                        <div className='flex gap-2 items-center'>
                            <div className='text-base font-semibold'>Variant</div>
                            <Button className='p-0 h-fit px-3 rounded-full' type='primary' onClick={() => setVariant('dots')}>dots</Button>
                            <Button className='p-0 h-fit px-3 rounded-full' type='primary' onClick={() => setVariant('lines')}>lines</Button>
                            <Button className='p-0 h-fit px-3 rounded-full' type='primary' onClick={() => setVariant('cross')}>cross</Button>
                        </div>
                    </Panel>
                    <Panel position='top-center'>
                        {editingNode && (
                            <div className=" z-10 p-2 bg-white border rounded shadow-md">
                                <Input
                                    value={newLabel}
                                    onChange={onLabelChange}
                                    onPressEnter={onLabelSubmit}
                                    onBlur={onLabelSubmit}
                                />
                            </div>
                        )}
                    </Panel>
                </ReactFlow>
            </div>
        </div>
    );
};

export default FlowDiagram;
