import { useEffect, useRef, useState } from "react";

const InsightsSection = ({ visibleSections, setVisibleSections }) => {
    const sectionRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sectionRef.current && !sectionRef.current.contains(event.target)) {
                // Reset all sections to false
                setVisibleSections({
                    yearOverYearGrowth: false,
                    abcClassification: false,
                    growthAndDecline: false,
                    demandVariability: false,
                    maturityAnalysis: false,
                    productLifecycle: false,
                    orderFrequency: false,
                });
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setVisibleSections]);

    // Check if at least one section is visible
    const isAnySectionVisible = Object.values(visibleSections).some(Boolean);

    if (!isAnySectionVisible) return null; // Hide component if all are false

    return (
        <div ref={sectionRef} className="absolute cursor-default min-w-[20rem] hover:scale-100 hover:!text-slate-500 text-slate-500 text-xs max-w-[40rem] left-5 top-0 bg-slate-100 border border-[#4d8bf5] p-2 rounded-md">
            {visibleSections.yearOverYearGrowth && (
                <div>
                    {/* <h3 className="font-semibold ">Year-over-year Growth</h3> */}
                    <p>Highlights overall business growth across products, locations, and customers.</p>
                </div>
            )}

            {visibleSections.abcClassification && (
                <div>
                    {/* <h3>ABC Classification by Product, Location, and Customer</h3> */}
                    <p>Pareto analysis categorizing:</p>
                    <ul className="list-disc pl-5" >
                        <li>Top products contributing to sales.</li>
                        <li>Top customers by volume.</li>
                        <li>Top locations by volume.</li>
                    </ul>
                    <p>
                        <strong>Why it’s useful:</strong> Prioritizes your focus on the most critical revenue-driving products, customers, and locations.
                    </p>
                </div>
            )}

            {visibleSections.growthAndDecline && (
                <div>
                    {/* <h3>Growth and Decline Across Products, Locations, and Customers</h3> */}
                    <p>Tracks sales patterns for products, locations, and customers:</p>
                    <ul className="list-disc pl-5">
                        <li>Growing patterns: Short-term and long-term growth.</li>
                        <li>Declining patterns: Significant declines in sales history.</li>
                    </ul>
                    <p>
                        <strong>Why it’s useful:</strong> Allows you to act on emerging trends early and take corrective actions if needed.
                    </p>
                </div>
            )}

            {visibleSections.demandVariability && (
                <div>
                    {/* <h3>Demand Variability</h3> */}
                    <p>Measures the consistency of buying and shipping patterns.</p>
                    <ul className="list-disc pl-5">
                        <li>Categorizes products, customers, and locations into high, medium, and low volatility.</li>
                        <li>Insights into regularity and irregularity of sales.</li>
                    </ul>
                    <p>
                        <strong>Why it’s useful:</strong> Helps differentiate between stable and unpredictable demand, aiding in accurate forecasting.
                    </p>
                </div>
            )}

            {visibleSections.maturityAnalysis && (
                <div>
                    {/* <h3>Maturity of the Product Portfolio, Location Service History, and Customer Base</h3> */}
                    <p>Represents the lifecycle stage of:</p>
                    <ul className="list-disc pl-5">
                        <li>Products: Mature, relatively mature, and new products.</li>
                        <li>Locations: Established, emerging, and new locations.</li>
                        <li>Customers: Loyal, growing, and new customers.</li>
                    </ul>
                    <p>
                        <strong>Why it’s useful:</strong> Guides lifecycle management and identifies potential growth areas.
                    </p>
                </div>
            )}

            {visibleSections.productLifecycle && (
                <div>
                    {/* <h3>Product Lifecycle Management</h3> */}
                    <p>Tracks changes in the portfolio:</p>
                    <ul className="list-disc pl-5">
                        <li>Phased-out items: Products, locations, and customers no longer active.</li>
                        <li>New additions: Products, locations, and customers added this year.</li>
                    </ul>
                    <p>
                        <strong>Why it’s useful:</strong> Helps estimate planning complexity based on transitions.
                    </p>
                </div>
            )}

            {visibleSections.orderFrequency && (
                <div>
                    {/* <h3>Order Frequency Distribution</h3> */}
                    <p>Shows how frequently sales occur:</p>
                    <ul className="list-disc pl-5">
                        <li>Categorizes products, customers, and locations into high, medium, and low order frequency.</li>
                    </ul>
                    <p>
                        <strong>Why it’s useful:</strong> Helps understand order patterns for better forecasting and inventory planning.
                    </p>
                </div>
            )}
        </div>
    );
};

export default InsightsSection;
