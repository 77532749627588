import React from 'react'
import defaultActivity from "../../../components/assets/img/defaultActivity.png"
import { FaAngleRight } from "react-icons/fa6";
import ModificationForm from './ModificationForm';
import { Button } from 'antd';
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import { useBase } from '../../Layout/baseContext/BaseContext';
import { TbVideo } from "react-icons/tb";
import PostGraph from './PostGraph';
import { TailSpin } from 'react-loader-spinner';
import PostTable from './PostTable';


const ActivityPost = () => {
    const { selectedPost, setDiscussion,discussion,postGraphLoading, postTableLoading } = usePost();

    const { uiMappingData, fields, tdateVar } = useBase()

    const dateOnly = selectedPost && selectedPost.UPDATED_AT.split('T')[0];

    const calculateSum = (data, valueType) => {
        return data.reduce((sum, item) => {
            const value = parseFloat(item[valueType]);
            return sum + (isNaN(value) ? 0 : value); // Ignore invalid or missing values
        }, 0);
    };

    return (
        <div className='flex flex-col mt-2 w-full'>
            <div className='flex flex-col h-full p-3 mt-2 shadow-[rgba(203,213,225,0.5)_0px_0px_10px] rounded-xl w-full'>
                <div className=' flex gap-2 border-b border-slate-100 pb-2'>
                    <div className='flex-shrink-0'>
                        <img src={defaultActivity} width={30} alt="" />
                    </div>
                    <div className='text-xs'>
                        <p className='leading-6 -indent-2 pl-2'>
                            <span className='bg-violet-100 text-violet-700 font-semibold rounded-full px-3 py-1 mr-2'>{selectedPost.BY_ROLE}</span>
                            {
                                selectedPost.DETAILS.length === 1 ?
                                    <span className='font-normal '>
                                        <b>{selectedPost.BY_USER}</b>{` has adjusted `}
                                        {
                                            selectedPost.QTY === "FQTY_USER" && <b>forecast </b>
                                        }
                                        {
                                            selectedPost.QTY === "FQTY_NEW" && <b>new product quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_DISCONTINUED" && <b>discontinued quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_BUDGET" && <b>budget quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_CONSENSUS" && <b>consensus </b>
                                        }{
                                            selectedPost.QTY === "FQTY_MARKET" && <b>marketing quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_PROMOTION" && <b>promotion quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_EVENT" && <b>event quantity </b>
                                        }
                                        {` from `}
                                        <b className='semibold'>{Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: 2 }).format(selectedPost.DETAILS[0].PREVIOUS_VALUE)}</b>{` to `}
                                        <b className='semibold'>{Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: 2 }).format(selectedPost.DETAILS[0].NEW_VALUE)}</b>{` at `}
                                        <b className='semibold'>{`${uiMappingData[0][selectedPost.ITEM_LEVEL]}-${uiMappingData[0][selectedPost.LOCATION_LEVEL]}-${uiMappingData[0][selectedPost.CUSTOMER_LEVEL]}`}</b>{` level with values `}
                                        <b className='semibold'>{`${selectedPost.ITEM_VALUE}-${selectedPost.LOCATION_VALUE}-${selectedPost.CUSTOMER_VALUE}`}</b>{` in `}
                                        <b className='semibold'>{selectedPost.TIME_LEVEL}</b>{` for `}
                                        <b className='semibold'>{selectedPost.DETAILS[0].TIME_VALUE}</b>
                                        {
                                            selectedPost.INITIAL_COMMENT &&
                                            <>
                                                {` with an initial comment `}
                                                <b className='semibold'>{selectedPost.INITIAL_COMMENT}</b>
                                            </>
                                        }
                                    </span>

                                    :
                                    <span className='font-normal '>
                                        {`User `}<b>{selectedPost.BY_USER}</b>{` has adjusted `}
                                        {
                                            selectedPost.QTY === "FQTY_USER" && <b>forecast </b>
                                        }
                                        {
                                            selectedPost.QTY === "FQTY_NEW" && <b>new product quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_DISCONTINUED" && <b>discontinued quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_BUDGET" && <b>budget quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_CONSENSUS" && <b>consensus </b>
                                        }{
                                            selectedPost.QTY === "FQTY_MARKET" && <b>marketing quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_PROMOTION" && <b>promotion quantity </b>
                                        }{
                                            selectedPost.QTY === "FQTY_EVENT" && <b>event quantity </b>
                                        }
                                        {` from `}
                                        <b>{Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: 2 }).format(calculateSum(selectedPost.DETAILS, "PREVIOUS_VALUE"))}</b>{` to `}
                                        <b>{Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: 2 }).format(calculateSum(selectedPost.DETAILS, "NEW_VALUE"))}</b>{` at `}
                                        <b>{`${uiMappingData[0][selectedPost.ITEM_LEVEL]}-${uiMappingData[0][selectedPost.LOCATION_LEVEL]}-${uiMappingData[0][selectedPost.CUSTOMER_LEVEL]}`}</b>{` level with values `}
                                        <b>{`${selectedPost.ITEM_VALUE}-${selectedPost.LOCATION_VALUE}-${selectedPost.CUSTOMER_VALUE}`}</b>{` in `}
                                        <b>{selectedPost.TIME_LEVEL}</b>{` for `}
                                        <b>several</b>{` months`}
                                        {
                                            selectedPost.INITIAL_COMMENT &&
                                            <>
                                                {` with an initial comment `}
                                                <b className='semibold'>{selectedPost.INITIAL_COMMENT}</b>
                                            </>
                                        }
                                    </span>

                            }
                        </p>
                    </div>

                </div>

                <div className='flex justify-between mt-2'>
                    <div className='flex gap-x-4 pl-2 flex-wrap'>
                        <div className='flex text-xs items-center'>
                            <p className='text-inactiveText'>Status:</p>
                            <Button type='link' className='flex ml-2 items-center text-xs p-0' onClick={() => setDiscussion(!discussion)}>
                                <p className='underline mr-1 font-semibold capitalize' >{selectedPost.STATUS} Discussion</p>
                                <span className='pt-1'>
                                    <FaAngleRight />
                                </span>
                            </Button>
                        </div>
                        <div className='flex text-xs items-center '>
                            <p className='text-inactiveText'>Last Edits Made:</p>
                            <Button type='link' className='flex ml-2 items-center text-xs p-0'>
                                <p className='underline mr-1 font-semibold'>{dateOnly}</p>
                                <span className='pt-1'>
                                    <FaAngleRight />
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div className='flex gap-2 text-xs flex-wrap justify-end'>
                        <Button type='link' className='flex items-center gap-1 text-xs p-0'>
                            <p className='text-lg'><TbVideo /></p>
                            <p>Start a new meeting</p>
                        </Button>
                        <button className='bg-slate-100 text-green-500 font-semibold rounded-full px-3 py-1 hover:bg-slate-200 w-40'>Approve changes</button>
                        {/* <button className='bg-slate-100 text-red-500 font-semibold rounded-full px-3 py-1 hover:bg-slate-200 w-40'>Raise changes</button> */}
                    </div>
                </div>
                {/* <ModificationForm /> */}


                <div className='flex flex-row justify-center items-center px-6 pt-6 pb-4 bg-white rounded-lg w-full'>
                    {
                        postGraphLoading ?
                            <div className='h-[10rem] flex justify-center gap-2 items-center'><TailSpin
                                visible={true}
                                height="30"
                                width="30"
                                color="#2B3674"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            Loading graph</div>
                            :

                            <PostGraph />
                    }
                </div>
                {
                    postTableLoading ?
                        <div className='h-[10rem] flex justify-center gap-2 items-center'><TailSpin
                            visible={true}
                            height="30"
                            width="30"
                            color="#2B3674"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /> Loading table
                        </div>
                        :
                        <div className='w-full flex'>
                            <PostTable />

                        </div>
                }

            </div>
        </div>

    )
}

export default ActivityPost