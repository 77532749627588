import React, { useState } from 'react'
import { FaRegCommentDots } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';
import { Button, Input } from 'antd';
import { TbSend2 } from "react-icons/tb";
import { useBase } from '../Layout/baseContext/BaseContext';
import api from '../../api';
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner';
import CommentsUI from './CommentsUI';
import { MdAddTask } from "react-icons/md";
import AddTaskUI from './AddTaskUI';


const CellRightClickMenu = ({ data, POST_ID, FDATE, qty }) => {
    const { fields, additionalFields, trasnposedData, getCellComments, cellCommentsData, commentsLoading, postingCommentLoading, setPostingCommentLoading, setCellCommentsData } = useBase();
    const { menuRef, contextMenu, showAddComment, setShowAddComment } = usePlan()


    const [showAddTask, setShowAddTask] = useState(false)





    const handleCommentClick = () => {
        setCellCommentsData([])
        setShowAddComment(true)
        if (POST_ID && FDATE) {
            getCellComments(POST_ID, FDATE, false)
        }
    }




    return (
        <div className={`absolute ${contextMenu.isSplit ? "left-[80px]" : "left-[110px]"} top-0 left-[0px] flex flex-col min-w-40 w-fit max-w-60 p-1  bg-slate-50/70 backdrop-blur-xl rounded-md box-shadow  z-50`}
            ref={(el) => menuRef.current[`${contextMenu.rowIndex}-${contextMenu.colIndex}-${contextMenu.qtyType}`] = el}
            onClick={(e) => e.stopPropagation()} // Prevent clicks from propagating outside

        >
            {/* Content of the custom context menu */}
            {
                !showAddComment && !showAddTask &&
                <>
                    <div onClick={() => handleCommentClick()} className='p-1 hover:bg-slate-100 rounded-md cursor-pointer text-xs flex gap-2 items-center'>
                        <p className='text-[#4489e2] text-base'><FaRegCommentDots /></p>
                        <p className='text-bodyTextBlue'>Add comment</p>
                    </div>
                    <div className='p-1 hover:bg-slate-100 rounded-md cursor-pointer text-xs flex gap-2 items-center'>
                        <p className='text-[#4489e2] text-base'><MdAddTask /></p>
                        <p onClick={() => setShowAddTask(true)} className='text-bodyTextBlue'>Add task</p>
                    </div>
                    <div className='p-1 hover:bg-slate-100 rounded-md cursor-pointer text-xs flex gap-2 items-center'>
                        <p className='text-[#4489e2] text-base'><HiOutlineLightBulb /></p>
                        <p className='text-bodyTextBlue'>Insights</p>
                    </div>
                </>

            }
            {showAddComment &&
                <CommentsUI data={data} POST_ID={POST_ID} FDATE={FDATE} />
            }
            {
                showAddTask &&
                <AddTaskUI data={data} FDATE={FDATE} qty={qty}/>
            }

        </div>
    )
}

export default CellRightClickMenu





