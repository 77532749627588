import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GrowthChart = ({ demandReport }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (!demandReport || !demandReport.Year_Over_Growth_Data) return;

        const data = demandReport.Year_Over_Growth_Data;

        // Group total sales by year
        const yearlySales = {};
        data.forEach(({ Year, total_sales }) => {
            yearlySales[Year] = (yearlySales[Year] || 0) + total_sales;
        });

        // Get current year and calculate days passed dynamically
        const today = new Date();
        const currentYear = today.getFullYear();
        const startOfYear = new Date(currentYear, 0, 1);
        const daysPassed = Math.floor((today - startOfYear) / (1000 * 60 * 60 * 24)) + 1;
        const totalDaysInYear = currentYear % 4 === 0 ? 366 : 365; // Leap year check

        // Adjust current year's sales proportionally
        if (yearlySales[currentYear]) {
            yearlySales[currentYear] = Math.round((yearlySales[currentYear] / daysPassed) * totalDaysInYear);
        }

        // Calculate growth percentage
        const years = Object.keys(yearlySales).sort((a, b) => a - b);
        const growthRates = [];

        for (let i = 1; i < years.length; i++) {
            const prevYearSales = yearlySales[years[i - 1]];
            const currentYearSales = yearlySales[years[i]];
            const growth = ((currentYearSales - prevYearSales) / prevYearSales) * 100;
            growthRates.push({ year: years[i], growth: parseFloat(growth.toFixed(2)) });
        }

        // Prepare data for chart.js
        setChartData({
            labels: growthRates.map((item) => item.year),
            datasets: [
                {
                    label: "Overall Growth Rate (%)",
                    data: growthRates.map((item) => item.growth),
                    borderColor: "rgb(75, 192, 192)",
                    backgroundColor: "rgb(75, 192, 192)", // No color fill
                    borderWidth: 2,
                    tension: 0.3,
                },
            ],
        });
    }, [demandReport]);

    // Chart options: Remove grid and adjust legend
    const options = {
        scales: {
            x: { grid: { display: false } }, // Remove X-axis grid
            y: { grid: { display: false } }, // Remove Y-axis grid
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true, // Remove color box from legend
                    boxWidth: 0, // Hide legend box
                },
            },
        },
    };

    return (
        <div className='bg-white rounded-md shadow-sm p-4'>
            {chartData ? <Line data={chartData} options={options} /> : <p>Loading chart...</p>}
        </div>
    );
};

export default GrowthChart;
