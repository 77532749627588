import React, { createContext, useState, useContext, useEffect } from 'react';
import api from "../../../../api";
import { useBase } from '../BaseContext';
import { message } from 'antd';
import { usePlan } from '../planningContext/PlanningContext';

const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const [discussion, setDiscussion] = useState(false);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discussionLoading, setDiscussionLoading] = useState(false)
  const [comments, setComments] = useState([]);
  const [postingComment, setPostingComment] = useState(false)
  const { fields, filterString} = useBase();

  const [postGraphData, setpostGraphData] = useState([])
  const [currentPostGraph, setCurrentPostGraph] = useState(null)
  const [postGraphLoading, setpostGraphLoading ] = useState(false)

  const [postTableData, setPostTableData] = useState([])
  const [currentPostTable, setCurrentPostTable] = useState(null)
  const [postTableLoading, setPostTableLoading] = useState(false)
  const [trasnposedData, setTransposedData] = useState(new Map());

  const {selectedArea,selectedPost, setSelectedPost} = useBase()
  


  const fetchPosts = async () => {
    try {
      setLoading(true);

      const data = localStorage.getItem("posts");
      if (data) {
        const postData = JSON.parse(data);
        setPosts(postData)
        setSelectedPost(postData[0])
      }
      else {
        const response = await api.get("/api/posts/");

        // Axios response data is available in response.data
        const result = response.data;
      
        // Store the posts in localStorage
        localStorage.setItem("posts", JSON.stringify(result));
      
        // Update the state with the fetched posts
        setPosts(result);
        setSelectedPost(result[0])
      }
      setLoading(false)
    }
    catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  

  useEffect(() => {
    if(selectedArea === "post"){
      fetchPosts();
    }
  }, [selectedArea]);

  const fetchComments = async (posting = false) => {
    try {
      // if(comments.length === 0){
        //   setDiscussionLoading(true);
      // }
      
      if (!posting) {
        setDiscussionLoading(true);
      }


      const response = await api.get("/api/comments/")

      const allComments = response.data;

      const selectedComments = allComments.filter((comment) => comment.POST_ID === selectedPost.POST_ID)
      
      setDiscussionLoading(false);
      
      
      setComments(selectedComments);
    } catch (error) {
      console.error('Error fetching discussion data:', error);
    }
  };

  useEffect(() => {
    if(selectedPost !== null){
      fetchComments();
    }
  },[selectedPost])



  const getPostGraph = async ()  => {
    setpostGraphLoading(true)
    try{
      const filterStringForPostGraph = [
        selectedPost.ITEM_LEVEL && selectedPost.ITEM_VALUE ? `&${selectedPost.ITEM_LEVEL}=${selectedPost.ITEM_VALUE}` : '',
        selectedPost.LOCATION_LEVEL && selectedPost.LOCATION_VALUE ? `&${selectedPost.LOCATION_LEVEL}=${selectedPost.LOCATION_VALUE}` : '',
        selectedPost.CUSTOMER_LEVEL && selectedPost.CUSTOMER_VALUE ? `&${selectedPost.CUSTOMER_LEVEL}=${selectedPost.CUSTOMER_VALUE}` : ''
    ].filter(Boolean).join('');
        const response = await api.get("/api/forecastcube/?fields=" + fields["interval"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + filterStringForPostGraph + "&page=1");

        setpostGraphData((prev) => [
          ...prev,
          {
            postID: selectedPost.POST_ID,
            postGraphData: response.data.results,
          },
        ]);

        setCurrentPostGraph(response.data.results)
    }
    catch(e){
      console.log(e);
      // message.info("Something went wrong");
    }
    setpostGraphLoading(false)
  }


  const getPostTable = async ()  => {
    setPostTableLoading(true)
    try{
      const filterStringForPostTable = [
        selectedPost.ITEM_LEVEL && selectedPost.ITEM_VALUE ? `&${selectedPost.ITEM_LEVEL}=${selectedPost.ITEM_VALUE}` : '',
        selectedPost.LOCATION_LEVEL && selectedPost.LOCATION_VALUE ? `&${selectedPost.LOCATION_LEVEL}=${selectedPost.LOCATION_VALUE}` : '',
        selectedPost.CUSTOMER_LEVEL && selectedPost.CUSTOMER_VALUE ? `&${selectedPost.CUSTOMER_LEVEL}=${selectedPost.CUSTOMER_VALUE}` : ''
    ].filter(Boolean).join('');

    const fieldStringForPostTable = [
      selectedPost.ITEM_LEVEL && selectedPost.ITEM_VALUE ? `${selectedPost.ITEM_LEVEL}` : '',
      selectedPost.LOCATION_LEVEL && selectedPost.LOCATION_VALUE ? `,${selectedPost.LOCATION_LEVEL}` : '',
      selectedPost.CUSTOMER_LEVEL && selectedPost.CUSTOMER_VALUE ? `,${selectedPost.CUSTOMER_LEVEL}` : ''
  ].filter(Boolean).join('');
  const response = await api.get("/api/forecastcubeTable/?fields=" + fieldStringForPostTable + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_4"] + "," + fields["base_3"] + "," + "OPEN_OQTY,QQTY,FQTY_SALES,FQTY_CONSENSUS,FQTY_MARKET,FQTY_PROMOTION,FQTY_EVENT,FQTY_NEW,FQTY_DISCONTINUED" + filterStringForPostTable + "&page=1");


        setPostTableData((prev) => [
          ...prev,
          {
            postID: selectedPost.POST_ID,
            postTableData: response.data.results,
          },
        ]);

        setCurrentPostTable(response.data.results)
    }
    catch(e){
      console.log(e);
      // message.info("Something went wrong");
    }
    setPostTableLoading(false)
  }

  useEffect(() => {
    //for graph
    if(postGraphData){
      const isPostIDPresent = postGraphData.find((data) => data.postID === selectedPost.POST_ID);
      if(isPostIDPresent){
        setCurrentPostGraph(isPostIDPresent.postGraphData)
      }
      else{
        getPostGraph();
      }
    }
    else{
      getPostGraph();
    }

    // for table
    if(postTableData){
      const isPostIDPresent = postTableData.find((data) => data.postID === selectedPost.POST_ID);
      if(isPostIDPresent){
        setCurrentPostTable(isPostIDPresent.postTableData)
      }
      else{
        getPostTable();
      }
    }
    else{
      getPostTable();
    }
  
  },[selectedPost, selectedArea])




  return (
    <PostContext.Provider value={{ posts, setPosts,
     discussion, setDiscussion,
      selectedPost, setSelectedPost,
       loading, fetchComments,
        discussionLoading, setDiscussionLoading,
         comments, setComments,
          postingComment, setPostingComment,
          currentPostGraph,
          postGraphLoading,
          postTableLoading,
          trasnposedData, setTransposedData,
          currentPostTable,
           }}>
      {children}
    </PostContext.Provider>
  );
};

export const usePost = () => useContext(PostContext);