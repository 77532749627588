import React from 'react'
import CommentsUI from '../../PlanningOverview/CommentsUI'
import { useBase } from '../../Layout/baseContext/BaseContext'

const SidePanelComments = () => {
    const {cellFdate ,cellData} = useBase()
    return (
        <div className='bg-slate-100/70  backdrop-blur-lg rounded-2xl w-full h-[25%] flex flex-col p-4 box-shadow-panel z-50'>
            <CommentsUI data={cellData} FDATE={cellFdate}/>
        </div>
    )
}

export default SidePanelComments