import React from 'react'
import { Line } from 'react-chartjs-2'
import { FiDownload } from 'react-icons/fi';
import { BiSolidBarChartAlt2 } from "react-icons/bi";

const data = {
    TotalSpent: {
        January: 10,
        February: 5,
        March: 2,
        April: 4,
        May: 3,
        June: 5,
        July: 2,
        August: 3,
        September: 1,
        October: 6,
        November: 4,
        December: 7
    },
    TotalGained: {
        January: 5,
        February: 8,
        March: 3,
        April: 6,
        May: 2,
        June: 4,
        July: 1,
        August: 7,
        September: 3,
        October: 5,
        November: 6,
        December: 8
    },
};

const OverallRevenue = () => {
    const sortedKeys = Object.keys(data.TotalSpent)
        .map(key => key) // Keep keys as strings (month names)
        .sort((a, b) => new Date(`${a} 1, 2000`) - new Date(`${b} 1, 2000`)); // Sort months in correct order

    // Define colors
    const colors = {
        TotalSpent: '#4285f4',
        TotalGained: '#ed7d31',
    };

    // Create datasets
    const datasets = [
        {
            label: 'Total Spent',
            data: sortedKeys.map((key) => data.TotalSpent[key]), // Map data points based on sorted keys
            fill: false,
            borderColor: colors.TotalSpent,
            backgroundColor: colors.TotalSpent,
            tension: 0.4,

           },
        {
            label: 'Total Gained',
            data: sortedKeys.map((key) => data.TotalGained[key]), // Map data points based on sorted keys
            fill: false,
            borderColor: colors.TotalGained,
            backgroundColor: colors.TotalGained,
            tension: 0.4,
        }
    ];

    // Prepare chart data
    const chartData = {
        labels: sortedKeys,
        datasets: datasets,
    };

    // Chart options
    const options = {
        indexAxis: 'x',
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false, // Hide legend
            },
        },
        layout: {
            
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        maintainAspectRatio: false 
    };
    return (
        <div className='w-full h-full flex flex-row p-4'>
            <div className='w-1/4 border-r pr-2'>
                <div>
                    <h3 className='text-slate-400 text-sm'>Demand overview</h3>
                </div>
                <div className='flex flex-col gap-4 pb-6 h-full'>
                    <div className='mt-4'>
                        <div className='flex flex-row items-center justify-between'>
                            <div className='flex flex-row items-center'>
                                <div className='bg-violet-700 w-3 rounded-full h-[2px]'></div>
                                <div className='ml-1'>B2B demand</div>
                            </div>
                            <div>
                                <div className='text-green-500 font-semibold'>+1.52%</div>
                            </div>
                        </div>
                        <div className='text-base font-semibold '>
                            $37.5K
                        </div>
                    </div>
                    <div>
                        <div className='flex flex-row items-center justify-between'>
                            <div className='flex flex-row items-center'>
                                <div className='bg-violet-700 w-3 rounded-full h-[2px]'></div>
                                <div className='ml-1'>B2C demand</div>
                            </div>
                            <div>
                                <div className='text-green-500 font-semibold'>+2.45%</div>
                            </div>
                        </div>
                        <div className='text-base font-semibold '>
                            $12.5K
                        </div>
                    </div>
                    {/* <div>
                        <div className='flex flex-row items-center justify-between'>
                            <div className='flex flex-row items-center'>
                                <div className='bg-violet-700 w-3 rounded-full h-[2px]'></div>
                                <div className='ml-1'>Revenue</div>
                            </div>
                            <div>
                                <div className='text-green-500 font-semibold'>+43.5K</div>
                            </div>
                        </div>
                        <div className='text-base font-semibold '>
                            $43.5K
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='w-3/4 flex flex-col'>
                <div className='flex items-center gap-2 justify-end '>
                    <div className="flex flex-row  items-center border border-blue-300 hover:bg-blue-50 p-2 rounded-full cursor-pointer text-blue-400 font-bold text-xs" >
                        <FiDownload />
                    </div>
                    <div className='text-slate-400 bg-blue-50 p-2 rounded-full'>
                        This month
                    </div>
                    <div className="flex flex-row  items-center border border-blue-300 hover:bg-blue-50 p-2 rounded-full cursor-pointer text-blue-400 font-bold text-xs">
                        <BiSolidBarChartAlt2 />
                    </div>
                </div>
                <div className='h-[200px] w-full'>
                    <Line 
                      width={"100%"}

                    className='h-full' data={chartData} options={options} />
                </div>
            </div>
        </div>
    )
}

export default OverallRevenue