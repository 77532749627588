import React from 'react'
import TaskListUI from './TaskListUI'

const TaskList = () => {
    return (
        <div className='bg-slate-100/70  backdrop-blur-lg rounded-2xl w-full h-[20%] flex flex-col p-4 box-shadow-panel z-50'>
            <TaskListUI/>
        </div>
    )
}

export default TaskList