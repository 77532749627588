import React, { useEffect, useState } from 'react'
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext'
import { useBase } from '../Layout/baseContext/BaseContext';
import { LuInfo } from 'react-icons/lu';
import { Button } from 'antd';
import CellRightClickMenu from './CellRightClickMenu';

const CommonEditableRow = ({
    name,
    additionalFields,
    trasnposedData,
    data,
    midIndex,
    fields,
    tdateVar,
    // changesArrayName,
    // setChangesArrayName,
    changesArrayName,
    setChangesArrayName,
    fqty_key_name,
    intervalFiltered,
    details,
    selectedItem,
    selectedCustomer,
    selectedLocation,
    createNewEntry,
    doesChangeMatch,
    checkAllChangesArrayAreEmpty,
    rowIndex
}) => {
    const { showAverage,
        showSum, contextMenu, handleOpenMenu } = usePlan();
    const { selectedArea, getSidePanelData } = useBase()
    const [colSpanNumber, setColSpanNumber] = useState(0);

    // const [processedData, setProcessedData] = useState(null);

    useEffect(() => {
        if (showAverage && showSum) {
            setColSpanNumber(2)
        }
        else if (showAverage || showSum) {
            setColSpanNumber(1)
        }
        else {
            setColSpanNumber(0)
        }
    }, [showAverage, showSum])



    return (
        <tr className=' bg-slate-100 '>
            {
                additionalFields.length === 0 &&
                <td colSpan={selectedArea === 'post' ? 1 : 4 + colSpanNumber} className='sticky p-1 min-w-[30px] shadow-col bg-violet-50 left-0 z-30  h-full  text-bodyTextBlue  pl-3 text-sm  '>{name}</td>
            }
            {
                additionalFields.length !== 0 &&
                <td colSpan={selectedArea === 'post' ? 1 : additionalFields.length + 1 + colSpanNumber} className='sticky p-1 min-w-[30px] bg-violet-50 shadow-col  left-0 z-30  h-full text-bodyTextBlue  pl-3 text-sm  '>{name}</td>
            }
            {trasnposedData && trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {
                // for normal sqty
                if (item["SQTY"] !== null && item["SQTY"] !== 0 && index < midIndex) {
                    return (
                        <td
                            colSpan={index === midIndex ? 2 : 1} className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#646666]'}`}>

                            {changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                <input
                                    type="text"
                                    defaultValue={item["SQTY"] !== null ? item["SQTY"] : 0}
                                    className={`p-2 h-full w-full bg-[#4285F4]`}
                                    onChange={(e) => {
                                        if (e.target.value !== item["SQTY"]) {
                                            e.target.className = "p-2 h-full w-full text-white bg-[#ed7d31]"
                                        }
                                        else {
                                            e.target.className = "p-2 h-full w-full text-white bg-[#4285F4]"
                                        }
                                    }}
                                    onBlur={(e) => {
                                        setChangesArrayName(prevArray => prevArray.slice(0, -1))
                                        setChangesArrayName((prevState) => [...prevState, {
                                            SQTY_USER: parseInt(e.target.value),
                                            interval: intervalFiltered[index],
                                            [selectedItem]: details[0],
                                            [selectedLocation]: details[1],
                                            [selectedCustomer]: details[2]
                                        }])
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <p className='p-2 h-full w-full flex items-center justify-between bg-violet-50'>{Intl.NumberFormat('en-US', {
                                    notation: "compact",
                                    maximumFractionDigits: 1
                                }).format(item["SQTY"] !== null ? item["SQTY"] : 0)}
                                    <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, fqty_key_name)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                        <LuInfo />
                                    </Button></p>
                            )}
                        </td>
                    );
                }
                // for normal fqty
                else if ((item[fqty_key_name] !== null && index >= midIndex) || (item["SQTY"] === null && item[fqty_key_name] === null && index >= midIndex)) {
                    return (
                        <td
                            colSpan={index === midIndex ? 2 : 1}
                            onDoubleClick={() => {
                                if (checkAllChangesArrayAreEmpty(changesArrayName)) {
                                    setChangesArrayName((prevState) => [
                                        ...prevState,
                                        createNewEntry(item[fqty_key_name], details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])
                                    ]);
                                }
                            }}
                            className={`
                            ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === fqty_key_name ? "z-50 scale-110 " : ""} transition-all duration-150 ease-in-out
                            text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}

                        >
                            {changesArrayName.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields)) ? (
                                <input
                                    type="text"
                                    defaultValue={
                                        item[fqty_key_name] !== null
                                            ? parseFloat(Number(item[fqty_key_name]).toFixed(2)).toString()
                                            : (0)
                                    }
                                    className={`p-2 h-full w-full text-white bg-[#4285F4] ${item[fqty_key_name] !== null && item[fqty_key_name] !== item["FQTY_USER"]
                                        ? "text-white bg-[#ed7d31]"
                                        : ""}`}
                                    onChange={(e) => {
                                        e.target.className = `p-2 h-full w-full text-white ${e.target.value !== item[fqty_key_name] ? "bg-[#ed7d31]" : "bg-[#4285F4]"
                                            }`;
                                    }}
                                    onBlur={(e) => {
                                        setChangesArrayName((prevState) => prevState.slice(0, -1)); // Remove last entry before updating

                                        setChangesArrayName((prevState) => [
                                            ...prevState,
                                            createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])
                                        ]);
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === fqty_key_name ? "z-40 box-shadow" : "z-30"}`}>

                                    <p
                                        onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, false, fqty_key_name)}
                                        className={`p-2 h-full w-full flex items-center justify-between
                            ${item[fqty_key_name] !== null ?
                                                item[fqty_key_name] === item["FQTY_USER"] ?
                                                    "" : "text-white bg-[#ed7d31]"
                                                :
                                                ""}
                        `}
                                    >{Intl.NumberFormat('en-US', {
                                        notation: "compact",
                                        maximumFractionDigits: 1
                                    }).format(item[fqty_key_name] !== null ?

                                        parseFloat(Number(item[fqty_key_name]).toFixed(2)).toString()
                                        :
                                        (0))}
                                        <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, fqty_key_name)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                            <LuInfo />
                                        </Button>
                                    </p>
                                    {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === fqty_key_name && (
                                        <CellRightClickMenu />
                                    )}
                                </div>
                            )}
                        </td>
                    );
                }
                // for 0 sqty
                else if (item["SQTY"] !== null && item["SQTY"] === 0 && index < midIndex) {
                    return (
                        <td
                            colSpan={index === midIndex ? 2 : 1}
                            className={`bg-violet-50 min-w-[110px] max-w-[110px]`}>
                            <p className='p-2 h-full w-full flex items-center justify-between'>{Intl.NumberFormat('en-US', {
                                notation: "compact",
                                maximumFractionDigits: 1
                            }).format(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(2)).toString() : 0)}
                                <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, fqty_key_name)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                    <LuInfo />
                                </Button>
                            </p>
                        </td>
                    );
                }
                else if (item["SQTY"] !== null && item[fqty_key_name] === null && index >= midIndex) {
                    return (
                        <td
                            onDoubleClick={() => {
                                if (checkAllChangesArrayAreEmpty(changesArrayName)) {
                                    setChangesArrayName((prevState) => [
                                        ...prevState,
                                        createNewEntry(item[fqty_key_name], details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])
                                    ]);
                                }
                            }}
                            colSpan={index === midIndex ? 2 : 1}
                            className={`
                            ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === fqty_key_name ? "z-50 scale-110 " : "z-30"} transition-all duration-150 ease-in-out
                            text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}
                        >
                            {changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                <input
                                    type="text"
                                    defaultValue={item[fqty_key_name] !== null ? item[fqty_key_name] : 0}
                                    className={`p-2 h-full w-full text-white bg-[#4285F4]`}
                                    onChange={(e) => {
                                        e.target.className = `p-2 h-full w-full text-white ${e.target.value !== item[fqty_key_name] ? "bg-[#ed7d31]" : "bg-[#4285F4]"
                                            }`;
                                    }}
                                    onBlur={(e) => {
                                        setChangesArrayName((prevState) => prevState.slice(0, -1)); // Remove last entry before updating
                                        setChangesArrayName((prevState) => [
                                            ...prevState,
                                            createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])
                                        ]);
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === fqty_key_name ? "z-40 box-shadow" : ""}`}>

                                    <p
                                        onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, false, fqty_key_name)}
                                        className={`p-2 h-full w-full flex items-center justify-between
                            ${item[fqty_key_name] !== null ?
                                                item[fqty_key_name] === item["FQTY_USER"] ?
                                                    "" : "text-white bg-[#ed7d31]"
                                                :
                                                ""}`}
                                    >{Intl.NumberFormat('en-US', {
                                        notation: "compact",
                                        maximumFractionDigits: 1
                                    }).format(item[fqty_key_name] !== null ?

                                        parseFloat(Number(item[fqty_key_name]).toFixed(2)).toString()
                                        :
                                        (0))}
                                        <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, fqty_key_name)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                            <LuInfo />
                                        </Button>
                                    </p>
                                    {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === fqty_key_name && (
                                        <CellRightClickMenu />
                                    )}
                                </div>
                            )}
                        </td>
                    );
                }



                //  else if (item[fqty_key_name] !== null && item[fqty_key_name] !== 0) {
                //     return (
                //         <td
                //             colSpan={index === midIndex ? 2 : 1}
                //             onDoubleClick={() => {
                //                 if (checkAllChangesArrayAreEmpty(changesArrayName)) {
                //                     setChangesArrayName((prevState) => [
                //                         ...prevState,
                //                         createNewEntry(item[fqty_key_name], details, intervalFiltered[index], additionalFields, fqty_key_name)
                //                     ]);
                //                 }
                //             }}
                //             className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}

                //         >
                //             {changesArrayName.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields)) ? (
                //                 <input
                //                     type="text"
                //                     defaultValue={
                //                         item[fqty_key_name] !== null
                //                             ? item[fqty_key_name] === item[fqty_key_name]
                //                                 ? parseFloat(Number(item[fqty_key_name]).toFixed(2)).toString()
                //                                 : parseFloat(Number(item[fqty_key_name]).toFixed(2)).toString()
                //                             : (0)
                //                     }
                //                     className={`p-2 h-full w-full text-white bg-[#4285F4] ${item[fqty_key_name] !== null && item[fqty_key_name] !== item[fqty_key_name]
                //                         ? "text-white bg-[#ed7d31]"
                //                         : ""}`}
                //                     onChange={(e) => {
                //                         e.target.className = `p-2 h-full w-full ${e.target.value !== item[fqty_key_name] ? "text-white bg-[#ed7d31]" : "text-white bg-[#4285F4]"
                //                             }`;
                //                     }}
                //                     onBlur={(e) => {
                //                         setChangesArrayName((prevState) => prevState.slice(0, -1)); // Remove last entry before updating

                //                         setChangesArrayName((prevState) => [
                //                             ...prevState,
                //                             createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, fqty_key_name)
                //                         ]);
                //                     }}
                //                     autoFocus
                //                 />
                //             ) : (
                //                 <p
                //                     className={`p-2 h-full w-full 
                //         ${item[fqty_key_name] !== null ?
                //                             item[fqty_key_name] === item[fqty_key_name] ?
                //                                 "" : "text-white bg-[#ed7d31]"
                //                             :
                //                             ""}`}
                //                 >{Intl.NumberFormat('en-US', {
                //                     notation: "compact",
                //                     maximumFractionDigits: 1
                //                 }).format(item[fqty_key_name] !== null ?
                //                     (item[fqty_key_name] === item[fqty_key_name] ?
                //                         parseFloat(Number(item[fqty_key_name]).toFixed(2)).toString() : parseFloat(Number(item[fqty_key_name]).toFixed(2)).toString())
                //                     :
                //                     (0))}</p>
                //             )}
                //         </td>
                //     );
                // }


                // else if (item["SQTY"] !== null && item["SQTY"] === 0 && index < midIndex) {
                //     return (
                //         <td colSpan={index === midIndex ? 2 : 1} className='min-w-[110px] max-w-[110px] bg-violet-50'>
                //             <p className='p-2 h-full w-full'>{Intl.NumberFormat('en-US', {
                //                 notation: "compact",
                //                 maximumFractionDigits: 1
                //             }).format(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>
                //         </td>
                //     );
                // }


                // else if (item[fqty_key_name] !== null && item[fqty_key_name] === 0) {
                //     return (
                //         <td
                //             onDoubleClick={() => {
                //                 setChangesArrayName((prevState) => [
                //                     ...prevState,
                //                     createNewEntry(item[fqty_key_name], details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])
                //                 ]);
                //             }}
                //             colSpan={index === midIndex ? 2 : 1}
                //             className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}
                //         >
                //             {changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                //                 <input
                //                     type="text"
                //                     defaultValue={item[fqty_key_name] !== null ? item[fqty_key_name] : 0}
                //                     className={`p-2 h-full w-full bg-[#4285F4]`}
                //                     onChange={(e) => {
                //                         if (e.target.value !== item[fqty_key_name]) {
                //                             e.target.className = "p-2 h-full w-full text-white bg-[#ed7d31]"
                //                         }
                //                         else {
                //                             e.target.className = "p-2 h-full w-full text-white bg-[#4285F4]"
                //                         }
                //                     }}
                //                     onBlur={(e) => {
                //                         setChangesArrayName(prevArray => prevArray.slice(0, -1))
                //                         setChangesArrayName((prevState) => [...prevState,
                //                         createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])])
                //                     }}
                //                     autoFocus
                //                 />
                //             ) : (
                //                 <p className='p-2 h-full w-full bg-violet-50'>{Intl.NumberFormat('en-US', {
                //                     notation: "compact",
                //                     maximumFractionDigits: 1
                //                 }).format(item[fqty_key_name] !== null ? item[fqty_key_name] : 0)}</p>
                //             )}
                //         </td>
                //     );
                // }


                // else if (item["SQTY"] === null && item[fqty_key_name] === null && index > midIndex) {
                //     return (
                //         <td

                //             onDoubleClick={() => {
                //                 setChangesArrayName((prevState) => [
                //                     ...prevState,
                //                     createNewEntry(item[fqty_key_name], details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])
                //                 ]);
                //             }}
                //             colSpan={index === midIndex ? 2 : 1}
                //             className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}
                //         >
                //             {changesArrayName.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                //                 <input
                //                     type="text"
                //                     defaultValue={item[fqty_key_name] !== null ? item[fqty_key_name] : 0}
                //                     className={`p-2 h-full w-full bg-[#4285F4]`}
                //                     onChange={(e) => {
                //                         if (e.target.value !== item[fqty_key_name]) {
                //                             e.target.className = "p-2 h-full w-full text-white bg-[#ed7d31]"
                //                         }
                //                         else {
                //                             e.target.className = "p-2 h-full w-full text-white bg-[#4285F4]"
                //                         }
                //                     }}
                //                     onBlur={(e) => {
                //                         setChangesArrayName(prevArray => prevArray.slice(0, -1))
                //                         setChangesArrayName((prevState) => [...prevState,
                //                         createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, fqty_key_name, item[fqty_key_name])])
                //                     }}
                //                     autoFocus
                //                 />
                //             ) : (
                //                 <p className='p-2 h-full w-full bg-violet-50'>{Intl.NumberFormat('en-US', {
                //                     notation: "compact",
                //                     maximumFractionDigits: 1
                //                 }).format(item[fqty_key_name] !== null ? item[fqty_key_name] : 0)}</p>
                //             )}
                //         </td>
                //     );
                // }

            })}

        </tr>)
}

export default CommonEditableRow



