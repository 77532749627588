import React from 'react';
import Chart from "chart.js/auto";

import { Bar } from 'react-chartjs-2';
import { useBase } from '../Layout/baseContext/BaseContext';
import { Button } from 'antd';
import graphLogo from '../assets/img/Screenshot 2024-09-09 162232.png'
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';

const CombinedGraph = ({ fields, tdateVar, barData1, barData2, lineData1, lineData2, duration }) => {
  const { selectedMenu } = useBase();
  const { showLineGraph, setShowLineGraph, graphTypes } = useBase();
  const { setGraphEditPopUp, showGraphs, setShowGraphs } = usePlan();

  const budget = lineData1.datasets[0].data.map((item) => item === 0 ? null : item);
  const planner = lineData2.datasets[0].data.map((item) => item === 0 ? null : item);
  const sqty = barData1.datasets[0].data.map((item) => item === 0 ? null : item);
  const fqty_engine = barData2.datasets[0].data.map((item) => item === 0 ? null : item);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (fields['interval'] === "FWEEK") {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear().toString().slice(-2);
      return `${day}-${month}-${year}`;
    }
    if (fields['interval'] === "FMONTH") {
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear().toString().slice(-2);
      return `${month}-${year}`;
    }
    if (fields['interval'] === "FYEAR") {
      return date.getFullYear().toString();
    }
    return date.toLocaleDateString('en-US');
  };

  const formattedDates = barData1.labels.map(formatDate);

  const labels = barData1.labels.filter((date) => new Date(date) >= new Date(tdateVar));
  const formatDates = labels.map(formatDate);

  // Filter datasets based on showGraphs state
  const datasets = [
    showGraphs.budget && {
      type: graphTypes.budget === "bar" ? "bar" : "line",
      label: "Budget",
      data: budget,
      pointStyle: 'circle',
      pointRadius: 3,
      fill: false,
      pointBackgroundColor: '#34a853',
      backgroundColor: graphTypes.budget === "bar" ? "#34a853" : "transparent",
      borderColor: graphTypes.budget === "line" ? "#34a853" : undefined,
      borderWidth: graphTypes.budget === "line" ? 2 : undefined,
      barThickness: graphTypes.budget === "bar" ? 10 : undefined,
    },
    showGraphs.planner && {
      type: graphTypes.planner === "bar" ? "bar" : "line",
      label: "Planner",
      data: planner,
      pointStyle: 'circle',
      pointRadius: 3,
      fill: false,
      pointBackgroundColor: '#ED7D31',
      backgroundColor: graphTypes.planner === "bar" ? "#ED7D31" : "transparent",
      borderColor: graphTypes.planner === "line" ? "#ED7D31" : undefined,
      borderWidth: graphTypes.planner === "line" ? 2 : undefined,
      barThickness: graphTypes.planner === "bar" ? 10 : undefined,
    },
    showGraphs.history && {
      type: graphTypes.history === "bar" ? "bar" : "line",
      label: "History",
      data: sqty,
      pointStyle: 'circle',
      pointRadius: 3,
      fill: false,
      pointBackgroundColor: '#ffdb5c',
      backgroundColor: graphTypes.history === "bar" ? "#ffdb5c" : "transparent",
      borderColor: graphTypes.history === "line" ? "#ffdb5c" : undefined,
      borderWidth: graphTypes.history === "line" ? 2 : undefined,
      barThickness: graphTypes.history === "bar" ? 10 : undefined,
    },
    showGraphs.engine && {
      type: graphTypes.engine === "bar" ? "bar" : "line",
      label: "Engine",
      data: fqty_engine,
      pointStyle: 'circle',
      pointRadius: 4,
      fill: false,
      pointBackgroundColor: '#4d8bf5',
      backgroundColor: graphTypes.engine === "bar" ? "#4d8bf5" : "transparent",
      borderColor: graphTypes.engine === "line" ? "#4d8bf5" : undefined,
      borderWidth: graphTypes.engine === "line" ? 3 : undefined,
      barThickness: graphTypes.engine === "bar" ? 10 : undefined,
    },
  ].filter(Boolean); // Remove null entries

  const data1 = {
    labels: selectedMenu === 'supplyViewBase' ? formatDates : formattedDates,
    datasets,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return Intl.NumberFormat('en-US', {
              notation: "compact",
              maximumFractionDigits: 1
            }).format(value);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col bg-white items-center w-full h-[200px]">
      <div className="flex flex-row items-center justify-between w-full -mt-4">
        <div className='flex items-center gap-2  mb-2 '>
          <img onClick={() => setGraphEditPopUp(true)} className='aspect-[4/3] hover:opacity-70 cursor-pointer' src={graphLogo} width={30} height={20} alt="" />
        </div>
        <div className='flex items-center gap-4'>
          {showGraphs.engine && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#4489e2] rounded-full' />
              <p>Engine</p>
            </div>
          )}
          {showGraphs.history && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#ffdb5c] rounded-full' />
              <p>History</p>
            </div>
          )}
          {showGraphs.planner && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#ed7d31] rounded-full' />
              <p>Planner</p>
            </div>
          )}
          {showGraphs.budget && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#34a853] rounded-full' />
              <p>Budget</p>
            </div>
          )}
        </div>
      </div>
      <Bar data={data1} options={options} />
    </div>
  );
};

export default CombinedGraph;

