import React, { useEffect, useState } from 'react'
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext'
import { LuInfo } from 'react-icons/lu';
import { Button } from 'antd';
import { useBase } from '../Layout/baseContext/BaseContext';
import { useSupply } from '../Layout/baseContext/supplyContext/supplyContext';

const CommonExpandedRow = ({
    name,
    additionalFields,
    trasnposedData,
    data,
    midIndex,
    fields,
    tdateVar
}) => {
    const { getSidePanelData, selectedMenu } = useBase()
    const { showAverage,
        showSum } = usePlan();
    const [colSpanNumber, setColSpanNumber] = useState(0);
        const {supFieldsMapping} = useSupply()
    // const [processedData, setProcessedData] = useState(null);

    useEffect(() => {
        if (showAverage && showSum) {
            setColSpanNumber(2)
        }
        else if (showAverage || showSum) {
            setColSpanNumber(1)
        }
        else {
            setColSpanNumber(0)
        }
    }, [showAverage, showSum])



    return (
        <tr className=' bg-slate-100 '>
            {
                additionalFields.length === 0 &&
                <td colSpan={selectedMenu === 'details' ?  4 + colSpanNumber : 3 + colSpanNumber } className='sticky p-1 min-w-[30px] shadow-col bg-violet-50 left-0 z-30  h-full  text-bodyTextBlue  pl-3 text-sm  '>

                    {selectedMenu !== 'details' ? supFieldsMapping[name] : name}
                </td>
            }
            {
                additionalFields.length !== 0 &&
                <td colSpan={additionalFields.length + 1 + colSpanNumber} className='sticky p-1 min-w-[30px] bg-violet-50 shadow-col  left-0 z-30  h-full text-bodyTextBlue  pl-3 text-sm  '>{name}</td>
            }


            {trasnposedData && trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {
                {
                    if (name === "Open Orders") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col  min-w-[110px] max-w-[110px] text-[#4285F4] bg-violet-50'>
                                <p className='w-full h-full flex items-center justify-between'>

                                    {item.OPEN_OQTY === null ? 0 : item.OPEN_OQTY}
                                    <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, "OPEN_OQTY")} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                        <LuInfo />
                                    </Button>
                                </p>
                            </td>
                        )
                    }
                    if (name === "Actuals") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col  min-w-[110px] max-w-[110px] text-[#4285F4] bg-violet-50'>
                                <p className='w-full h-full flex items-center justify-between'>

                                    {item.FQTY === null ? 0 : Intl.NumberFormat('en-US', {
                                        notation: "compact",
                                        maximumFractionDigits: 1
                                    }).format(item.FQTY)}
                                    <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, "FQTY")} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                        <LuInfo />
                                    </Button>
                                </p>
                            </td>
                        )
                    }
                    if (name === "Forecast (OC)") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col text-[#4285F4] min-w-[110px] max-w-[110px] bg-violet-50'>{Intl.NumberFormat('en-US', {
                                notation: "compact",
                                maximumFractionDigits: 1
                            }).format(item.FQTY_ENGINE_OC ? Number(item.FQTY_ENGINE_OC).toFixed(0) : 0)}</td>
                        )
                    }
                    if (name === "Quotations") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>
                                <p className='w-full h-full flex items-center justify-between'>
                                    {item.QQTY === null ? 0 : item.QQTY}
                                    <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, "QQTY")} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>
                                        <LuInfo />
                                    </Button>
                                </p>
                            </td>
                        )
                    }
                    // if (name === "POERQTY") {
                    //     return (
                    //         <td className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item.POERQTY === null ? 0 : item.POERQTY}</td>
                    //     )
                    // }
                    if (name === "Year ago") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>
                                {item.yearAgo === null ? "-" : Intl.NumberFormat('en-US', {
                                    notation: "compact",
                                    maximumFractionDigits: 1
                                }).format(item.yearAgo)}
                            </td>
                        )
                    }
                    else {
                        return (
                            <td colSpan={index === midIndex ? 1 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item[name]}</td>
                        )
                    }
                }
            })}
            {/* <td className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>0</td> */}

        </tr>)
}

export default CommonExpandedRow



