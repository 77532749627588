import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useBase } from '../BaseContext';
import { message } from 'antd';
import api from '../../../../api';
import { useAlert } from '../alertsContext/AlertsContext';
import moment from 'moment';

const PlanningContext = createContext();

export const PlanningProvider = ({ children }) => {
  const [subSelectionFilterMap, setSubSelectionFilterMap] = useState(new Map());
  const [inATTFilterPopUp, setinATTFilterPopUp] = useState(false)
  const [filterATT, setFilterATT] = useState(null);
  const { setFilterString, filters, setFilters, checkBoxArray, setCheckBoxArray } = useBase();
  const [showOverlay, setShowOverlay] = useState(false);
  const [graphEditPopUp, setGraphEditPopUp] = useState(false)
  const { additionalFields, setAdditionalFields, selectedArea, setSelectedArea, selectedDFUs, setActiveFilters,

    activeItemFilters, setActiveItemFilters,
    activeLocationFilters, setActiveLocationFilters,
    activeCustomerFilters, setActiveCustomerFilters,
    activeDFUFilters, setActiveDFUFilters,
    activeLFUFilters, setActiveLFUFilters,setM1LFUdata,setM1LFUfilteredData,setStartingSubSelection,
    setSelectedLFUs,setSelectedDFUs
  } = useBase();
  const [proposalData, setProposalData] = useState([])
  const [showAverage, setShowAverage] = useState(false)
  const [showSum, setShowSum] = useState(false)
  const [showGraphs, setShowGraphs] = useState({
    "engine": true,
    "planner": true,
    "budget": true,
    "history": true
  })

  const { DFUunfilteredData, setDFUFullData, DFUFullData } = useAlert()
  const [multiAdjustmentData, setMultiAdjustmentData] = useState(
    {
      "activityID": "",
      "Duration": "",
      "Option selected": "",
      "Bucket length": null,
      "TDATE": "",
      "column_keys": null
    }
  )

  const [bucketLength, setBucketLength] = useState(2)
  const [selectedAdjType, setSelectedAdjType] = useState(null)
  const [quickFilters, setQuickFilters] = useState(null)
  const [cellComments, setCellComments] = useState([])

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    rowIndex: null,
    colIndex: null,
    isSplit: false,
    qtyType: null,
  });
  const menuRef = useRef({});
  const [showAddComment, setShowAddComment] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)


  // Example usage
  const handleOpenMenu = (e, rowIndex, colIndex, isSplit, qtyType) => {
    e.preventDefault();
    setContextMenu({ visible: true, rowIndex, colIndex, isSplit, qtyType });
  };


  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const currentKey = `${contextMenu.rowIndex}-${contextMenu.colIndex}-${contextMenu.qtyType}`;
      if (
        menuRef.current[currentKey] &&
        !menuRef.current[currentKey].contains(event.target)
      ) {
        setContextMenu({ visible: false, rowIndex: null, colIndex: null, isSplit: false, qtyType: null });
        setShowAddComment(false)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenu]);








  const submitFilters = () => {
    var filter_string = "";
    [...filters.keys()].map((item) => {
      filter_string += "&" + item + "=";
      filters.get(item).map((data, index) => {
        if (index < filters.get(item).length)
          filter_string += data + ","
        else
          filter_string += data
      })
    })

    setFilterString(encodeURI(filter_string.slice(0, filter_string.length - 1)));
    setShowOverlay(false)


    // if (selectedDFUs.length !== 0) {
    //   // const m1LFUDFUs = new Set(m1LFUdata.map(item => item.DFU));

    //   // Filter DFUFullData to include only entries whose DFU exists in m1LFUData
    //   const filteredDFUFullData = DFUFullData.filter(item => selectedDFUs.includes(item.DFU));
    //   setDFUFullData(filteredDFUFullData)
      
    // }
    // else {
      // const m1LFUDFUs = new Set(m1LFUdata.map(item => item.DFU));

      // // Filter DFUFullData to include only entries whose DFU exists in m1LFUData
      // const filteredDFUFullData = DFUFullData.filter(item => m1LFUDFUs.has(item.DFU));
      // setDFUFullData(filteredDFUFullData)
    // }


  }


  const clearAllFilters = () => {
    setFilters(new Map());
    setCheckBoxArray([]);
    setActiveFilters([])
    setFilterSelectionSeq({
      "ITEM": 0,
      "LOCATION": 0,
      "CUSTOMER": 0
    })


    setSelectedDFUs([])
    setSelectedLFUs([])
    const cachedm1LFUdata = localStorage.getItem("m1LFUdata");
    
    setM1LFUdata(JSON.parse(cachedm1LFUdata))
    setStartingSubSelection('')
    
    // setActiveItemFilters([])
    // setActiveLocationFilters([])
    // setActiveCustomerFilters([])
    console.log("--------DFUunfilteredData-----",DFUunfilteredData);
    setDFUFullData(DFUunfilteredData)
    

    // Optionally, reset other states related to filters if necessary
  };


  const getProposals = async (details) => {
    // try {
    //   if (additionalFields.length === 0) {
    //     const result = await api.get("/api/proposal/", {
    //       params: {
    //         IATT2: details[0],
    //         CATT2: details[2]
    //       }
    //     })
    //     setProposalData(result.data);
    //   }
    //   else {
    //     const indexIATT2 = additionalFields.indexOf("IATT2");
    //     const indexCATT2 = additionalFields.indexOf("CATT2");

    //     const result = await api.get("/api/proposal/", {
    //       params: {
    //         IATT2: details[indexIATT2],
    //         CATT2: details[indexCATT2]
    //       }
    //     })
    //     setProposalData(result.data);
    //   }
    // }
    // catch (e) {
    //   message.info("No Proposals are available")
    // }
  }
  const { tdateVar, fields, setFilterSelectionSeq,m1LFUdata } = useBase()


  const saveRowAdjustment = async (details) => {


    const activityId = moment().format("YYYYMMDDHHmmssSSS");
    let newEntry = {}; // Initialize newEntry as an empty object

    if (additionalFields.length > 0) {
      additionalFields.forEach((field, idx) => {
        newEntry[field] = details[idx];
      });
    } else {
      newEntry[fields.item] = details[0];
      newEntry[fields.location] = details[1];
      newEntry[fields.customer] = details[2];
    }

    let data;
    if (selectedAdjType === "0") {
      data = {
        activityID: activityId,
        Duration: fields["interval"],
        "Option selected": "forecast 0",
        "Bucket length": null,
        TDATE: tdateVar,
        column_keys: newEntry,
      };
    }
    if (selectedAdjType === "prev years") {
      data = {
        activityID: activityId,
        Duration: fields["interval"],
        "Option selected": "previous years",
        "Bucket length": null,
        TDATE: tdateVar,
        column_keys: newEntry,
      };
    }
    if (selectedAdjType === "avg") {
      data = {
        activityID: activityId,
        Duration: fields["interval"],
        "Option selected": "average",
        "Bucket length": bucketLength,
        TDATE: tdateVar,
        column_keys: newEntry,
      };
    }

    try {
      await api.post("api/submitRowAdj", {
        user: localStorage.getItem("username")
      })
    }
    catch (e) {
      console.log(e);
    }
  };



  

 


  const [checkCommentsData, setCheckCommentsData] = useState(null)
  const [checkTaskData, setCheckTaskData] = useState(null)

  const checkComments = async () => {
    let response
    if (additionalFields.length === 0) {
      response = await api.post("/api/checkComments/", {
        columns: [fields["item"], fields["location"], fields["category"]]
      });
    }
    else {

      response = await api.post("/api/checkComments/", {
        columns: additionalFields,
      });
    }
    setCheckCommentsData(response.data.comments)


  }
  const checkTasks = async () => {
    let response
    if (additionalFields.length === 0) {
      response = await api.post("/api/checkTasks/", {
        columns: [fields["item"], fields["location"], fields["category"]],
        username:localStorage.getItem("username")
      });
    }
    else {

      response = await api.post("/api/checkTasks/", {
        columns: additionalFields,
        username:localStorage.getItem("username")
      });
    }
    setCheckTaskData(response.data.tasks)


  }



  return (
    <PlanningContext.Provider value={{
      filters, setFilters,
      subSelectionFilterMap, setSubSelectionFilterMap,
      inATTFilterPopUp, setinATTFilterPopUp,
      filterATT, setFilterATT,
      checkBoxArray, setCheckBoxArray,
      submitFilters,
      showOverlay, setShowOverlay,
      clearAllFilters,
      graphEditPopUp, setGraphEditPopUp,
      getProposals,
      proposalData,
      showAverage, setShowAverage,
      showSum, setShowSum,
      showGraphs, setShowGraphs,
      multiAdjustmentData, setMultiAdjustmentData,
      bucketLength, setBucketLength,
      saveRowAdjustment,
      selectedAdjType, setSelectedAdjType,
      quickFilters, setQuickFilters,
      contextMenu, setContextMenu,
      menuRef,
      handleOpenMenu,
      cellComments, setCellComments,
      checkCommentsData, setCheckCommentsData,
      checkComments,
      showAddComment, setShowAddComment,
      submitLoading, setSubmitLoading,
      checkTasks,
      checkTaskData, setCheckTaskData
    }}>
      {children}
    </PlanningContext.Provider>
  );
};

export const usePlan = () => useContext(PlanningContext);