import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useSupply } from '../../../Layout/baseContext/supplyContext/supplyContext';
import graphLogo from '../../../assets/img/Screenshot 2024-09-09 162232.png'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const SupplyGraph = ({ graphDisplayData }) => {

    const {graphTypes,supFields,setGraphEditPopUp,showGraphs,supFieldsMapping} = useSupply()

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += Intl.NumberFormat('en-US', {
                                notation: 'compact',
                                maximumFractionDigits: 1,
                            }).format(context.parsed.y);
                        }
                        return label;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    callback: function (value) {
                        return Intl.NumberFormat('en-US', {
                            notation: 'compact',
                            maximumFractionDigits: 1,
                        }).format(value);
                    },
                },
            },
            y1: {
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    callback: function (value) {
                        return Intl.NumberFormat('en-US', {
                            notation: 'compact',
                            maximumFractionDigits: 1,
                        }).format(value);
                    },
                },
            },
        },
        maintainAspectRatio: false,
    };

    const data = {
        labels: graphDisplayData.dates,
        datasets: [
            // Line for FQTY_ENGINE
            showGraphs[supFields['base_13']] && {
                type: graphTypes[supFields['base_13']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_13']],
                data: graphDisplayData.data13,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#4d8bf5',
                backgroundColor: graphTypes[supFields['base_13']] === 'line' ? 'transparent' : '#4d8bf5',
                borderColor: graphTypes[supFields['base_13']] === 'line' ? '#4d8bf5' : undefined,
                borderWidth: graphTypes[supFields['base_13']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_13']] === 'line' ? undefined : 10,
                yAxisID: 'y',
            },
    
            // Secondary axis lines for cost fields, revenue, and profit
            showGraphs[supFields['base_1']] && {
                type: graphTypes[supFields['base_1']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_1']],
                data: graphDisplayData.data1,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#95ed31',
                backgroundColor: graphTypes[supFields['base_1']] === 'line' ? 'transparent' : '#95ed31',
                borderColor: graphTypes[supFields['base_1']] === 'line' ? '#95ed31' : undefined,
                borderWidth: graphTypes[supFields['base_1']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_1']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_3']] && {
                type: graphTypes[supFields['base_3']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_3']],
                data: graphDisplayData.data3,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#31ede7',
                backgroundColor: graphTypes[supFields['base_3']] === 'line' ? 'transparent' : '#31ede7',
                borderColor: graphTypes[supFields['base_3']] === 'line' ? '#31ede7' : undefined,
                borderWidth: graphTypes[supFields['base_3']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_3']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_4']] && {
                type: graphTypes[supFields['base_4']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_4']],
                data: graphDisplayData.data4,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#ed31d7',
                backgroundColor: graphTypes[supFields['base_4']] === 'line' ? 'transparent' : '#ed31d7',
                borderColor: graphTypes[supFields['base_4']] === 'line' ? '#ed31d7' : undefined,
                borderWidth: graphTypes[supFields['base_4']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_4']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_5']] && {
                type: graphTypes[supFields['base_5']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_5']],
                data: graphDisplayData.data5,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#9831ed',
                backgroundColor: graphTypes[supFields['base_5']] === 'line' ? 'transparent' : '#9831ed',
                borderColor: graphTypes[supFields['base_5']] === 'line' ? '#9831ed' : undefined,
                borderWidth: graphTypes[supFields['base_5']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_5']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_6']] && {
                type: graphTypes[supFields['base_6']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_6']],
                data: graphDisplayData.data6,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#88022f',
                backgroundColor: graphTypes[supFields['base_6']] === 'line' ? 'transparent' : '#88022f',
                borderColor: graphTypes[supFields['base_6']] === 'line' ? '#88022f' : undefined,
                borderWidth: graphTypes[supFields['base_6']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_6']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_7']] && {
                type: graphTypes[supFields['base_7']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_7']],
                data: graphDisplayData.data7,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#8cffb3',
                backgroundColor: graphTypes[supFields['base_7']] === 'line' ? 'transparent' : '#8cffb3',
                borderColor: graphTypes[supFields['base_7']] === 'line' ? '#8cffb3' : undefined,
                borderWidth: graphTypes[supFields['base_7']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_7']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_10']] && {
                type: graphTypes[supFields['base_10']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_10']],
                data: graphDisplayData.data10,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#1a5562',
                backgroundColor: graphTypes[supFields['base_10']] === 'line' ? 'transparent' : '#1a5562',
                borderColor: graphTypes[supFields['base_10']] === 'line' ? '#1a5562' : undefined,
                borderWidth: graphTypes[supFields['base_10']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_10']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_11']] && {
                type: graphTypes[supFields['base_11']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_11']],
                data: graphDisplayData.data11,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#3c1160',
                backgroundColor: graphTypes[supFields['base_11']] === 'line' ? 'transparent' : '#3c1160',
                borderColor: graphTypes[supFields['base_11']] === 'line' ? '#3c1160' : undefined,
                borderWidth: graphTypes[supFields['base_11']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_11']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
            showGraphs[supFields['base_12']] && {
                type: graphTypes[supFields['base_12']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_12']],
                data: graphDisplayData.data12,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#69580c',
                backgroundColor: graphTypes[supFields['base_12']] === 'line' ? 'transparent' : '#69580c',
                borderColor: graphTypes[supFields['base_12']] === 'line' ? '#69580c' : undefined,
                borderWidth: graphTypes[supFields['base_12']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_12']] === 'line' ? undefined : 10,
                yAxisID: 'y1',
            },
    
            // Stacked bars for PRQTY, POERQTY, BOH
            showGraphs[supFields['base_8']] && {
              type: graphTypes[supFields['base_8']] === 'line' ? 'line' : 'bar',
              label: supFieldsMapping[supFields['base_8']],
              data: graphDisplayData.data8,
              pointRadius: 3,
              fill: false,
              pointBackgroundColor: '#ED7D31',
              backgroundColor: graphTypes[supFields['base_8']] === 'line' ? 'transparent' : '#ED7D31',
              borderColor: graphTypes[supFields['base_8']] === 'line' ? '#ED7D31' : undefined,
              borderWidth: graphTypes[supFields['base_8']] === 'line' ? 2 : undefined,
              barThickness: graphTypes[supFields['base_8']] === 'line' ? undefined : 10,
              yAxisID: 'y',

          },
            showGraphs[supFields['base_9']] && {
                type: graphTypes[supFields['base_9']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_9']],
                data: graphDisplayData.data9,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#ffdb5c',
                backgroundColor: graphTypes[supFields['base_9']] === 'line' ? 'transparent' : '#ffdb5c',
                borderColor: graphTypes[supFields['base_9']] === 'line' ? '#ffdb5c' : undefined,
                borderWidth: graphTypes[supFields['base_9']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_9']] === 'line' ? undefined : 10,
                yAxisID: 'y',

            },
           
            showGraphs[supFields['base_2']] && {
                type: graphTypes[supFields['base_2']] === 'line' ? 'line' : 'bar',
                label: supFieldsMapping[supFields['base_2']],
                data: graphDisplayData.data2,
                pointRadius: 3,
                fill: false,
                pointBackgroundColor: '#34a853',
                backgroundColor: graphTypes[supFields['base_2']] === 'line' ? 'transparent' : '#34a853',
                borderColor: graphTypes[supFields['base_2']] === 'line' ? '#34a853' : undefined,
                borderWidth: graphTypes[supFields['base_2']] === 'line' ? 2 : undefined,
                barThickness: graphTypes[supFields['base_2']] === 'line' ? undefined : 10,
                yAxisID: 'y',
            },
            
            
        ].filter(Boolean),
    };
    

    return (
        <div className="flex flex-col bg-white items-center w-full h-[200px]">
        <div className="flex flex-row items-center justify-between w-full -mt-4">
          <div className='flex items-center gap-2  mb-2 '>
            <img onClick={() => setGraphEditPopUp(true)} className='aspect-[4/3] hover:opacity-70 cursor-pointer' src={graphLogo} width={30} height={20} alt="" />
          </div>
          <div className='flex items-center gap-4'>
          {showGraphs[supFields['base_13']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#4d8bf5] rounded-full' />
              <p>{supFieldsMapping[supFields['base_13']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_9']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#ffdb5c] rounded-full' />
              <p>{supFieldsMapping[supFields['base_9']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_8']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#ED7D31] rounded-full' />
              <p>{supFieldsMapping[supFields['base_8']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_2']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#34a853] rounded-full' />
              <p>{supFieldsMapping[supFields['base_2']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_1']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#95ed31] rounded-full' />
              <p>{supFieldsMapping[supFields['base_1']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_3']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#31ede7] rounded-full' />
              <p>{supFieldsMapping[supFields['base_3']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_4']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#ed31d7] rounded-full' />
              <p>{supFieldsMapping[supFields['base_4']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_5']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#9831ed] rounded-full' />
              <p>{supFieldsMapping[supFields['base_5']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_6']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#88022f] rounded-full' />
              <p>{supFieldsMapping[supFields['base_6']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_7']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#8cffb3] rounded-full' />
              <p>{supFieldsMapping[supFields['base_7']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_10']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#1a5562] rounded-full' />
              <p>{supFieldsMapping[supFields['base_10']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_11']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#3c1160] rounded-full' />
              <p>{supFieldsMapping[supFields['base_11']]}</p>
            </div>
          )}
          {showGraphs[supFields['base_12']] && (
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#69580c] rounded-full' />
              <p>{supFieldsMapping[supFields['base_12']]}</p>
            </div>
          )}
        </div>
        </div>
        <Bar data={data} options={options} />
      </div>
    );
};

export default SupplyGraph;
