import React, { useEffect, useState } from 'react';
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';
import { Button, Checkbox } from 'antd';
import { useBase } from '../Layout/baseContext/BaseContext';
import { FiSearch } from 'react-icons/fi';

const FilterInATTPopUp = () => {
    const {
        setinATTFilterPopUp,
        filterATT,
        filters,
        setFilters,
        subSelectionFilterMap,
        setSubSelectionFilterMap,
        checkBoxArray,
        setCheckBoxArray,
        submitFilters,
        clearAllFilters
    } = usePlan();
    const { uiMappingData, locationFilter, customerFilter, productFilter } = useBase();

    const [searchQuery, setSearchQuery] = useState('');
    const [filerItems, setfilerItems] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const newMap = new Map();

        if (productFilter && customerFilter && locationFilter) {
            if (
                Object.keys(productFilter).length !== 0 &&
                Object.keys(customerFilter).length !== 0 &&
                Object.keys(locationFilter).length !== 0
            ) {
                if (filterATT && filterATT.charAt(0) === 'I') {
                    Object.keys(productFilter[0] || {}).forEach((item) => {
                        newMap.set(item, Array.from(new Set(productFilter.map((data) => data[item]))));
                    });
                } else if (filterATT && filterATT.charAt(0) === 'L') {
                    Object.keys(locationFilter[0] || {}).forEach((item) => {
                        newMap.set(item, Array.from(new Set(locationFilter.map((data) => data[item]))));
                    });
                } else if (filterATT && filterATT.charAt(0) === 'C') {
                    Object.keys(customerFilter[0] || {}).forEach((item) => {
                        newMap.set(item, Array.from(new Set(customerFilter.map((data) => data[item]))));
                    });
                }

                setSubSelectionFilterMap(newMap);
            }
        }
    }, [productFilter, customerFilter, locationFilter, filterATT, setSubSelectionFilterMap]);

    useEffect(() => {
        if (filterATT && subSelectionFilterMap.has(filterATT)) {
            const filteredItems = subSelectionFilterMap
                .get(filterATT)
                .filter((item) => item !== null && item.toLowerCase().includes(searchQuery.toLowerCase()));
            setfilerItems(filteredItems);
        } else {
            setfilerItems([]);
        }
    }, [searchQuery, filterATT, subSelectionFilterMap]);


    // Update "Select All" state based on individual checkbox states
    useEffect(() => {
        const allSelected = filerItems.every((item) => checkBoxArray.includes(item));
        setSelectAllChecked(allSelected && filerItems.length > 0);
    }, [filerItems, checkBoxArray]);

    const handleSelectAll = (checked) => {
        if (checked) {
            // Select all items displayed in the UI
            const updatedArray = [...new Set([...checkBoxArray, ...filerItems])];
            setCheckBoxArray(updatedArray);

            const updatedMap = new Map(filters);
            updatedMap.set(filterATT, updatedArray);
            setFilters(updatedMap);
        } else {
            // Deselect all items displayed in the UI
            const updatedArray = checkBoxArray.filter((item) => !filerItems.includes(item));
            setCheckBoxArray(updatedArray);

            const updatedMap = new Map(filters);
            updatedMap.set(filterATT, updatedArray);
            setFilters(updatedMap);
        }
    };

    const handleCheckboxChange = (item, checked) => {
        const updatedMap = new Map(filters); // Clone the filters map
        let arr = filters.get(filterATT) || []; // Get the current array for the filterATT key

        if (checked) {
            // Add the item to the array if checked
            arr.push(item);
            updatedMap.set(filterATT, arr); // Update the key with the new array
            setCheckBoxArray((prevState) => [...prevState, item]); // Update the checkbox array state
        } else {
            // Remove the item from the array if unchecked
            arr = arr.filter((data) => data !== item);
            if (arr.length === 0) {
                // If the array is empty, remove the key from the map
                updatedMap.delete(filterATT);
            } else {
                // Otherwise, update the key with the modified array
                updatedMap.set(filterATT, arr);
            }
            setCheckBoxArray((prevState) => prevState.filter((data) => data !== item)); // Update the checkbox array state
        }

        setFilters(updatedMap); // Update the filters state
    };


    useEffect(() => {
        if (filterATT && subSelectionFilterMap.has(filterATT)) {
            const filteredItems = subSelectionFilterMap
                .get(filterATT)
                .filter((item) => item !== null && item.toString().toLowerCase().includes(searchQuery.toLowerCase()))
                .sort((a, b) => {
                    // Convert both values to strings and compare them
                    if (!isNaN(a) && !isNaN(b)) {
                        // Numeric comparison for numbers
                        return parseFloat(a) - parseFloat(b);
                    } else {
                        // String comparison for other cases
                        return a.toString().localeCompare(b.toString(), undefined, {
                            numeric: true, // Enables natural sorting for mixed content
                            sensitivity: 'base',
                        });
                    }
                });
            setfilerItems(filteredItems);
        } else {
            setfilerItems([]);
        }
    }, [searchQuery, filterATT, subSelectionFilterMap]);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 text-bodyTextBlue">
            <div className="bg-white p-7 rounded-lg box-shadow text-center border border-slate-100 transform transition-transform duration-100 ease-out animate-scale-up">
                <h1 className="text-xl font-semibold mb-4">{uiMappingData[0][filterATT]}</h1>
                <div className="bg-slate-100 flex items-center rounded-md mb-4 px-3 py-1 mt-2">
                    <div className="text-secondary">
                        <FiSearch />
                    </div>
                    <input
                        className="bg-slate-100 outline-none pl-2"
                        type="text"
                        name="Search"
                        placeholder="search"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>
                {/* "Select All" checkbox */}
                <div className='flex justify-start mb-2'>
                    <Checkbox
                        // indeterminate={
                        //     filerItems.some((item) => checkBoxArray.includes(item)) &&
                        //     !selectAllChecked
                        // }
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        checked={selectAllChecked}
                    >
                        <p className='font-semibold text-bodyTextBlue'>
                            Select All
                        </p>
                    </Checkbox>
                </div>
                <div className="min-h-60 max-h-60 mb-4 overflow-y-scroll scroll flex flex-col">

                    {filerItems.map((item) => (
                        <Checkbox
                            key={item}
                            onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                            checked={checkBoxArray.includes(item)}
                        >
                            {item}
                        </Checkbox>
                    ))}
                </div>
                <div className="flex justify-end gap-2">
                    <Button type="link" onClick={() => setinATTFilterPopUp(false)}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            setinATTFilterPopUp(false);
                            submitFilters();
                        }}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FilterInATTPopUp;