import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Input, Select, message } from 'antd';
import moment from 'moment';
import { useBase } from '../Layout/baseContext/BaseContext';
import api from '../../api';
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';
import TextArea from 'antd/es/input/TextArea';
import { MdInsights } from "react-icons/md";


const { Option } = Select;

const EditOverlay = ({
  setOverlayFlag,
  changes,
  salesChanges,
  eventChanges,
  marketChanges,
  promotionChanges,
  consensusChanges,
  setChanges, getData, getGraphData, adjLoading, setAdjLoading }) => {
  const { setPage, setNext, additionalFields, setIsFetchingPlan } = useBase();
  const [priceChangeReason, setPriceChangeReason] = useState("Price Change")
  const [adjustmentSplit, setAdjustmentSplit] = useState("Absolute")
  const [timeDisaggregation, setTimeDisaggregation] = useState("Planner Forecast")
  const { submitLoading, setSubmitLoading } = usePlan()

  // function replaceKeys(changes, additionalFields) {
  //   // Create a map to track the first occurrence of each prefix key
  //   const replacementsMap = {};
  //   const prefixes = ["I", "L", "C"];

  //   // Populate the replacements map with the first occurrences
  //   prefixes.forEach(prefix => {
  //     for (const field of additionalFields) {
  //       if (field.startsWith(prefix) && !replacementsMap[prefix]) {
  //         replacementsMap[prefix] = field; // Store the first occurrence
  //       }
  //     }
  //   });

  // Replace keys in the changes array based on the replacements map
  //   const updatedChanges = changes.map(change => {
  //     prefixes.forEach(prefix => {
  //       // Find the original keys to replace
  //       const originalKey = Object.keys(change).find(key => key.startsWith(prefix));
  //       if (originalKey) {
  //         const newKey = replacementsMap[prefix];
  //         // Replace the key if the new key exists
  //         change[newKey] = change[originalKey];
  //         delete change[originalKey]; // Remove the old key
  //       }
  //     });
  //     return change;
  //   });

  //   return updatedChanges;
  // }

  const onSubmit = async () => {
    setSubmitLoading(true)
    try {
      setPage(2)
      // setAdjLoading(true)
      setOverlayFlag(false)
      setNext(true)

      const generateActivityId = () => {
        return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
      };
      const activityId = generateActivityId();
      const username = localStorage.getItem("username");
      // if(additionalFields.length >= 3){
      //   const updatedChanges = replaceKeys(changes, additionalFields);
      // }
      // console.log(updatedChanges,"************");
      const arrays = {
        changes,
        salesChanges,
        eventChanges,
        marketChanges,
        promotionChanges,
        consensusChanges
      };
      const nonEmptyArray = Object.values(arrays).find(array => array.length > 0);

      await api.post("/api/submitAdjustment/",
        {
          user: username,
          data: nonEmptyArray,
          priceChangeReason: priceChangeReason,
          adjustmentSplit,
          timeDisaggregation,
          activityId
        }
      )
      setIsFetchingPlan(false)
      setChanges([]);
      getData();
      getGraphData();
      localStorage.removeItem('posts');
      // setAdjLoading(false)
    } catch (error) {
      // alert(error)
      console.log(error);
    }
    setSubmitLoading(false)
  }

  const [formValues, setFormValues] = useState({
    classification: '',
    eventCategory: '',
    eventName: '',
  });
  const [eventList, setEventList] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [showBoxes, setShowBoxes] = useState({
    "info": false,
    "event": false,
    "nearby": false,
    "collab": false
  })

  // Load eventList from localStorage
  useEffect(() => {
    const storedEventList = localStorage.getItem('eventList');
    if (storedEventList) {
      setEventList(JSON.parse(storedEventList));
    }
  }, []);



  // Handle input changes
  const handleChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // Update classifications when eventCategory changes
    if (field === 'eventCategory') {
      const selectedCategory = eventList.find(
        (event) => event.EVENT_CATEGORY === value
      );
      setClassifications(selectedCategory ? selectedCategory.CLASSIFICATION : []);
      // Reset classification value if the category changes
      setFormValues((prevState) => ({
        ...prevState,
        classification: '',
      }));
    }
  };
  return (
    <>

      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/6 h-5/6  shadow-2xl rounded-md  z-[1000]"
      >
        <div className='flex flex-col items-start w-full h-full backdrop-blur-xl bg-slate-200/70 rounded-md '>
          <div className="flex flex-row  px-3  py-1 border-b text-bodyTextBlue border-b-gray-300  justify-between font-medium text-base  w-full">

            <p>Make Adjustments</p>
            <p onClick={() => setOverlayFlag(false)} className='cursor-pointer hover:text-red-600 rounded-md'>

              <FontAwesomeIcon icon={faClose} />
            </p>
          </div>
          <div className='flex flex-col justify-between h-full w-full'>
            <div className="flex flex-row w-full items-start px-3 py-2 text-sm font-normal text-[#1B2559]">
              <div className="flex flex-col w-2/3 gap-3 border-r-2 border-r-gray-200 pr-2">
                <div className='bg-slate-100 p-2 rounded-md'>
                  <div className='flex gap-2 items-center'>
                    <p>Do you want to add a comment or provide more information which could help others to understand the reason of change ? </p>
                    <p className='flex gap-1'>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, info: true }))} className='!h-6' type='primary'>Yes</Button>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, info: false }))} className='!h-6' type='primary'>No</Button>
                    </p>
                  </div>
                  {
                    showBoxes.info &&
                    <div className='mt-1'>
                      <TextArea rows={2} placeholder="Add information here" maxLength={4} />
                    </div>
                  }
                </div>
                <div className='bg-slate-100 p-2 rounded-md'>
                  <div className='flex gap-2 items-center'>
                    <p>Do you have any additional information like specific event or promotion etc to add ?</p>
                    <p className='flex gap-1'>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, event: true }))} className='!h-6' type='primary'>Yes</Button>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, event: false }))} className='!h-6' type='primary'>No</Button>
                    </p>
                  </div>
                  {
                    showBoxes.event &&

                    <div className='mt-1 grid grid-cols-3 gap-1'>
                      <div className="flex flex-col">
                        <label className='text-slate-400 text-xs mb-[0.1rem] pl-1'>Select category</label>
                        <Select
                          placeholder="Select category"
                          value={formValues.eventCategory}
                          onChange={(value) => handleChange('eventCategory', value)}
                        >
                          {eventList
                            .sort((a, b) => (a.EVENT_CATEGORY === "" ? -1 : b.EVENT_CATEGORY === "" ? 1 : 0))
                            .map((event) => (
                              <Option key={event.EVENT_CATEGORY || "empty"} value={event.EVENT_CATEGORY}>
                                {event.EVENT_CATEGORY || "None"}
                              </Option>
                            ))}
                        </Select>
                      </div>

                      {/* Classification Input */}
                      <div className="flex flex-col">
                        <label className='text-slate-400 text-xs mb-[0.1rem] pl-1'>Select classification</label>
                        <Select
                          placeholder="Select classification"
                          value={formValues.classification}
                          onChange={(value) => handleChange('classification', value)}
                        >
                          {classifications.map((classification) => (
                            <Option key={classification} value={classification}>
                              {classification}
                            </Option>
                          ))}
                        </Select>
                      </div>

                      {/* Event Name Input */}
                      <div className="flex flex-col">
                        <label className='text-slate-400 text-xs mb-[0.1rem] pl-1'>Event Name</label>
                        <Input
                          placeholder="Event Name"
                          value={formValues.eventName}
                          onChange={(e) => handleChange('eventName', e.target.value)}
                        />
                      </div>
                    </div>
                  }
                </div>
                <div className='bg-slate-100 p-2 rounded-md'>
                  <div className='flex gap-2 items-center'>
                    <p>Do you want the engine to identify nearby buckets having an impact based on this event ?</p>
                    <p className='flex gap-1'>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, nearby: true }))} className='!h-6' type='primary'>Yes</Button>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, nearby: false }))} className='!h-6' type='primary'>No</Button>
                    </p>
                  </div>
                  {
                    showBoxes.nearby &&

                    <div className='mt-1'>
                      (Nearby buckets)
                      {/* <TextArea rows={2} placeholder="Add information here" maxLength={4} /> */}
                    </div>
                  }
                </div>
                <div className='bg-slate-100 p-2 rounded-md'>
                  <div className='flex gap-2 items-center'>
                    <p>For better collaboration, do you want to tag a specific person in your organisation ?</p>
                    <p className='flex gap-1'>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, collab: true }))} className='!h-6' type='primary'>Yes</Button>
                      <Button onClick={() => setShowBoxes(prev => ({ ...prev, collab: false }))} className='!h-6' type='primary'>No</Button>
                    </p>
                  </div>
                  {
                    showBoxes.collab &&

                    <div className='mt-1'>
                      <Select className='w-fit'
                        placeholder="Select User"
                      >
                        <Option>John</Option>
                        <Option>Tom</Option>
                      </Select>
                      {/* <TextArea rows={2} placeholder="Add information here" maxLength={4} /> */}
                    </div>
                  }
                </div>
              </div>
              <div className="flex flex-col w-1/3 px-2 space-y-2">
                <div className='flex flex-col space-y-2'>
                  <p>While you were providing us information , we found some quick insights for you based on historical value add</p>
                  <div className='flex flex-row space-x-4 items-center px-4 py-3 rounded-lg bg-slate-100'>
                    <MdInsights />
                    <p className='text-blue-500 font-semibold' >Insight 1</p>

                  </div>
                  <div className='flex flex-row space-x-4 px-4 items-center py-3 rounded-lg bg-slate-100'>
                    <MdInsights />
                    <p className='text-blue-500 font-semibold' >Insight 2</p>

                  </div>
                  <div className='flex flex-row space-x-4 px-4 items-center py-3 rounded-lg bg-slate-100'>
                    <MdInsights />
                    <p className='text-blue-500 font-semibold' >Insight 3</p>

                  </div>
                  <div className='flex flex-row space-x-4 px-4 items-center py-3 rounded-lg bg-slate-100'>
                    <MdInsights />
                    <p className='text-blue-500 font-semibold' >Insight 4</p>

                  </div>
                </div>




              </div>
            </div>

            <div className=' py-4  w-full flex flex-row px-4 justify-end' >
              <div className='flex flex-row space-x-4 items-center text-white'>
                {/* <button className='bg-gray-200 border border-gray-300 text-gray-700 p-2 rounded-lg' onClick={() => setOverlayFlag(false)}>Cancel</button>
              <button className='bg-blue-500 border border-gray-600 p-2 rounded-lg' onClick={onSubmit}>Save Adjustments</button> */}


                <Button type='primary' className='bg-slate-400 hover:!bg-slate-500' onClick={() => setOverlayFlag(false)}>Cancel</Button>
                <Button loading={submitLoading} type='primary' onClick={onSubmit}>Save Adjustments</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOverlay;
