import React, { useEffect, useRef, useState } from 'react'
import { ExpandOutlined } from '@ant-design/icons';
import { IoMdContract } from "react-icons/io";

import dayjs from 'dayjs';
import { Button, DatePicker, Select } from 'antd';
// import EditOverlay from './EditOverlay';
import 'ldrs/ring'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import { TailSpin } from 'react-loader-spinner';
import { Dropdown, message } from 'antd';
import { BsThreeDots } from "react-icons/bs";
import { TbTablePlus } from "react-icons/tb";
import { MdOutlineExpandCircleDown } from "react-icons/md";
import { FaSortDown } from "react-icons/fa6";
import { CgRemoveR } from "react-icons/cg";
import { FilterOutlined, } from "@ant-design/icons/lib/icons";
import { PiArrowsInSimple } from "react-icons/pi";
import { TbArrowNarrowUp } from "react-icons/tb";
import { logDOM } from '@testing-library/react';
import CommonEditableRow from '../../PlanningOverview/CommonEditableRow';
import { GrMultiple } from "react-icons/gr";
import { TiFlashOutline } from "react-icons/ti";
import { usePost } from '../../Layout/baseContext/postContext/PostContext';
import { usePlan } from '../../Layout/baseContext/planningContext/PlanningContext';
import { useBase } from '../../Layout/baseContext/BaseContext';
import SelectedPost from './SelectedPost';




const PostTable = () => {
    const {
        bucketLength, setBucketLength,
        saveRowAdjustment, selectedAdjType, setSelectedAdjType
    } = usePlan()


    const [overlayFlag, setOverlayFlag] = useState(false)

    const { endDate,tdateVar,startDate,intervalList,fields,
        adjLoading, setAdjLoading
     } = useBase();
     const { trasnposedData, setTransposedData, postTableLoading,currentPostTable, selectedPost } = usePost();

    const keyFields = [selectedPost.ITEM_LEVEL,selectedPost.LOCATION_LEVEL,selectedPost.CUSTOMER_LEVEL]
    const filteredKeyFields = keyFields.filter(item => item !== null)
     const additionalFields = filteredKeyFields

    const [changes, setChanges] = useState([]);
    const [salesChanges, setSalesChanges] = useState([])
    const [eventChanges, setEventChanges] = useState([])
    const [marketChanges, setMarketChanges] = useState([])
    const [promotionChanges, setPromotionChanges] = useState([])
    const [consensusChanges, setConsensusChanges] = useState([])
    const [budgetChanges, setBudgetChanges] = useState([])
    const [newChanges, setNewChanges] = useState([])
    const [disChanges, setDisChanges] = useState([])
    const tableRef = useRef(null);
    const tdRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedItem, setSelectedItem] = useState(fields.item)
    const [selectedLocation, setSelectedLocation] = useState(fields.location)
    const [selectedCustomer, setSelectedCustomer] = useState(fields.category)
    const divRef = useRef(null);
    const [expandRow, setExpandRow] = useState(null);


    const [expandAllRows, setExpandAllRows] = useState(false)
    const [showMultiCellConfigIndex, setShowMultiCellConfigIndex] = useState(null)




    const [intervalFiltered, setintervalFiltered] = useState([])


    const checkForMidDate = (item, tdateVar) => {
        if (fields['interval'] === "FYEAR" && new Date(item).getFullYear() === new Date(tdateVar).getFullYear()) {
            return true;
        }
        if (fields['interval'] === "FMONTH") {
            const itemDate = new Date(item);
            const tdate = new Date(tdateVar);
            if (itemDate.getFullYear() === tdate.getFullYear() && itemDate.getMonth() === tdate.getMonth()) {
                return true;
            }
        }
        if (fields['interval'] === "FWEEK" && item === tdateVar) {
            return true;
        }
        return false;
    };

    const generateTransposeData = () => {
        const keyFields = [selectedPost.ITEM_LEVEL,selectedPost.LOCATION_LEVEL,selectedPost.CUSTOMER_LEVEL]
        const filteredKeyFields = keyFields.filter(item => item !== null)
        if (currentPostTable !== null && intervalList.length !== 0 && tdateVar !== null) {
            const transposeMap = new Map();

            // Filter interval list based on startDate and endDate
            const intervalListFiltered = intervalList.filter(dateString => {
                const date = new Date(dateString[fields.interval]);
                return date >= new Date(tdateVar);
            });

            setintervalFiltered(intervalListFiltered);

            currentPostTable && currentPostTable.forEach((data, index) => {
                var key = "";

                if (additionalFields.length === 0) {
                    key = `${data[fields['item']]}=${data[fields['location']]}=${data[fields['category']]}`;
                } else {
                    for (var i = 0; i < additionalFields.length; i++) {
                        if (i > 0) {
                            key += "=";
                        }
                        key += `${data[additionalFields[i]]}`;
                    }
                }

                if (!transposeMap.has(key)) {
                    transposeMap.set(key, {});
                }

                const itemMap = transposeMap.get(key);

                intervalListFiltered.forEach((interval) => {
                    if (!itemMap[interval[fields.interval]]) {
                        itemMap[interval[fields.interval]] = {
                            "FQTY": 0,
                            "SQTY": 0,
                            "isSplit": checkForMidDate(interval[fields.interval], tdateVar),
                            "FQTY_BUDGET": 0,
                            "FQTY_USER": 0,
                            "OPEN_OQTY": 0,
                            "QQTY": 0,
                            "FQTY_SALES": 0,
                            "FQTY_CONSENSUS": 0,
                            "FQTY_MARKET": 0,
                            "FQTY_PROMOTION": 0,
                            "FQTY_EVENT": 0,
                            "FQTY_NEW": 0,
                            "FQTY_DISCONTINUED": 0,
                            "yearAgo": 0 // Initialize yearAgo with null
                        };
                    }
                });

                itemMap[data[fields.interval]] = {
                    "FQTY": data["FQTY_ENGINE"],
                    "SQTY": data["SQTY"],
                    "isSplit": checkForMidDate(data[fields.interval], tdateVar),
                    "FQTY_BUDGET": data["FQTY_BUDGET"],
                    "FQTY_USER": data["FQTY_USER"],
                    "OPEN_OQTY": data["OPEN_OQTY"],
                    "QQTY": data["QQTY"],
                    "FQTY_SALES": data["FQTY_SALES"],
                    "FQTY_CONSENSUS": data["FQTY_CONSENSUS"],
                    "FQTY_MARKET": data["FQTY_MARKET"],
                    "FQTY_PROMOTION": data["FQTY_PROMOTION"],
                    "FQTY_EVENT": data["FQTY_EVENT"],
                    "FQTY_NEW": data["FQTY_NEW"],
                    "FQTY_DISCONTINUED": data["FQTY_DISCONTINUED"],
                    "yearAgo": 0 // Initialize yearAgo with null
                };
            });
            // Remove intervals not in intervalListFiltered
            transposeMap.forEach((itemMap) => {
                Object.keys(itemMap).forEach(interval => {
                    if (!intervalListFiltered.some(filteredInterval => filteredInterval[fields.interval] === interval)) {
                        delete itemMap[interval];
                    }
                });
            });
            // Add yearAgo values

            transposeMap.forEach((itemMap, key) => {
                const sortedDates = Object.keys(itemMap).sort(); // Sort dates to process chronologically

                sortedDates.forEach((currentDate, index) => {
                    const current = itemMap[currentDate];
                    let yearAgoValue = null;

                    if (index > 0) {
                        const intervalType = fields.interval; // Use interval type
                        const currentDateObj = new Date(currentDate);
                        let yearAgoDate;

                        if (intervalType === "FMONTH") {
                            // Get exact one year ago date for FMONTH
                            yearAgoDate = new Date(currentDateObj);

                            yearAgoDate.setFullYear(yearAgoDate.getFullYear() - 1);
                        } else if (intervalType === "FWEEK") {

                            // Get the closest date for FFWEEK
                            yearAgoDate = new Date(currentDateObj);
                            yearAgoDate.setFullYear(yearAgoDate.getFullYear() - 1);

                            // Find the closest date
                            const closestDate = sortedDates.reduce((closest, date) => {
                                const dateObj = new Date(date);
                                const diffCurrent = Math.abs(yearAgoDate - dateObj); // Compare with yearAgoDate
                                const diffClosest = Math.abs(yearAgoDate - new Date(closest));
                                return diffCurrent < diffClosest ? date : closest;
                            }, sortedDates[0]);

                            yearAgoDate = new Date(closestDate);
                        }
                        const yearAgoString = yearAgoDate && yearAgoDate.toISOString().split("T")[0];


                        yearAgoValue = itemMap[yearAgoString]?.SQTY || null;
                    }

                    current.yearAgo = yearAgoValue; // Set the yearAgo value
                });
            });



            const sorted = transposeMap; // Assuming sortMapBySumAvg isn't needed here

            
                setTransposedData(sorted);
           

            return transposeMap;
        }
        return null;
    };

    useEffect(() => {
        generateTransposeData()
    },[currentPostTable])













    var midIndex = 0;



 

  



    // Helper function to check if a change matches the current details and interval
    const doesChangeMatch = (change, details, interval, additionalFields) => {
        if (additionalFields.length > 0) {
            // Check all dynamic fields from additionalFields
            return additionalFields.every((field, idx) => change[field] === details[idx]) && change["interval"] === interval;
        } else {
            // Check for hardcoded fields when additionalFields is empty
            return change[selectedItem] === details[0] &&
                change[selectedLocation] === details[1] &&
                change[selectedCustomer] === details[2] &&
                change["interval"] === interval;
        }
    };

    // Helper function to create a new entry for the changes array
    const createNewEntry = (value, details, interval, additionalFields, keyValue, prevValue) => {
        let newEntry;

        const validKeys = [
            "FQTY_USER",
            "FQTY_SALES",
            "FQTY_CONSENSUS",
            "FQTY_MARKET",
            "FQTY_PROMOTION",
            "FQTY_EVENT",
            "FQTY_BUDGET",
            "FQTY_NEW",
            "FQTY_DISCONTINUED",
        ];

        if (validKeys.includes(keyValue)) {
            newEntry = {
                [keyValue]: parseInt(value),
                previousValue: prevValue,
                interval: interval,
            };
        }





        if (additionalFields.length > 0) {
            additionalFields.forEach((field, idx) => {
                newEntry[field] = details[idx];
            });
        } else {
            newEntry[selectedItem] = details[0];
            newEntry[selectedLocation] = details[1];
            newEntry[selectedCustomer] = details[2];
        }

        return newEntry;
    };


    const checkAllChangesArrayAreEmpty = (excludedArrayRef) => {
        // Get all state arrays
        const allArrays = {
            changes,
            salesChanges,
            eventChanges,
            marketChanges,
            promotionChanges,
            consensusChanges,
            budgetChanges,
            newChanges,
            disChanges
        };

        // Check if all other arrays are empty
        const isEmpty = Object.values(allArrays).every((array) => {
            if (array === excludedArrayRef) {
                return true; // Skip the excluded array
            }
            return array.length === 0; // Ensure all others are empty
        });

        if (!isEmpty) {
            message.info("Undo other changes to modify data here");
        }

        return isEmpty;
    };

    const handleUndo = () => {
        const stateMapping = [
            { state: changes, setState: setChanges },
            { state: salesChanges, setState: setSalesChanges },
            { state: eventChanges, setState: setEventChanges },
            { state: marketChanges, setState: setMarketChanges },
            { state: promotionChanges, setState: setPromotionChanges },
            { state: consensusChanges, setState: setConsensusChanges },
            { state: newChanges, setState: setNewChanges },
            { state: disChanges, setState: setDisChanges },
            { state: budgetChanges, setState: setBudgetChanges },
        ];

        stateMapping.forEach(({ state, setState }) => {
            if (state.length !== 0) {
                setState(prevArray => prevArray.slice(0, -1));
            }
        });
    };

    const dropdownRefs = useRef([]);
    useEffect(() => {

        const handleClickOutside = (event) => {
            // Check if the click is outside of any of the dropdowns
            const isOutside = dropdownRefs.current.every(ref => ref && !ref.contains(event.target));
            if (isOutside) {
                setShowMultiCellConfigIndex(null); // Close the dropdown
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowMultiCellConfigIndex]);


    useEffect(() => {
        setSelectedAdjType(null)
        setBucketLength(2)
    }, [showMultiCellConfigIndex])





    return (
        <div className='flex flex-col space-y-2 w-full  h-full relative overflow-hidden'>

            {
                postTableLoading ?
                    (<div className='h-full flex justify-center items-center'><TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div>)
                    :
                    <>
                        <div className='flex flex-row items-center space-x-4'>

                            {
                                ([changes, salesChanges, eventChanges, marketChanges, consensusChanges, promotionChanges, budgetChanges, newChanges, disChanges].some(array => array.length !== 0)) && !adjLoading ?
                                    <button className={`px-3 py-1 rounded-md text-sm bg-[#ed7d31] text-white shadow-md`} onClick={() => {
                                        handleUndo()
                                    }}>
                                        Undo
                                    </button>
                                    :
                                    null
                            }
                            {([changes, salesChanges, eventChanges, marketChanges, consensusChanges, promotionChanges, budgetChanges, newChanges, disChanges].some(array => array.length !== 0)) && !adjLoading &&
                                <button className={`px-3 py-1 bg-blue-500 rounded-md text-sm text-white shadow-md`} onClick={() => setOverlayFlag(!overlayFlag)}>
                                    Save
                                </button>
                            }

                        </div>
                        {postTableLoading ?
                            (<div className='h-full flex justify-center items-center'><TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#2B3674"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /></div>)
                            :
                            <div ref={divRef} className={`  overflow-scroll  border relative scroll shadow-col  shadow-class`}>
                                {(
                                    <>
                                        <table ref={tableRef} className='resizable-table w-full relative whitespace-nowrap text-xs table-auto shadow-class bg-white  transition-all ease-in-out duration-500 '>
                                            <thead className='w-full sticky top-0 border-b shadow-sm shadow-class bg-white z-50 ' >
                                                <tr className='font-bold  bg-slate-100'>
                                            
                                                    <td className='sticky bg-slate-100 shadow-col left-0 min-w-[50px]   z-50 text-center text-base '>
                                                        <div className='flex flex-row items-center justify-between px-3'>

                                                            <div
                                                                className=" hover:text-blue-500 text-sm">
                                                                {
                                                                    !isExpanded ?
                                                                        <ExpandOutlined className='cursor-pointer'
                                                                        />
                                                                        :
                                                                        <IoMdContract className='cursor-pointer'
                                                                        />
                                                                }

                                                            </div>



                                                        </div>

                                                    </td>



                                                    {trasnposedData.size !== 0 && Object.keys(trasnposedData.get([...trasnposedData.keys()][0])).map((item, index) => {



                                                        if (checkForMidDate(item, tdateVar)  && new Date(item) >= new Date(tdateVar)) {
                                                            
                                                            midIndex = index
                                                            return (<React.Fragment key={index}>
                                                                <td ref={tdRef} colSpan={2} className={`  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                    <div className='flex flex-row items-center gap-1 justify-between'>
                                                                        <div className='flex flex-col items-start'>

                                                                            <div className='flex w-full justify-between '>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>

                                                                                

                                                                            </div>

                                                                        </div>
                                                                       
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>)
                                                        }
                                                        else {
                                                            if (index > midIndex && new Date(item) >= new Date(tdateVar)) {
                                                                return (<React.Fragment key={index}>
                                                                    <td className={`  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                        <div className='flex flex-row items-center gap-3 justify-between'>
                                                                            <div className='flex flex-col items-start'>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>

                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>)
                                                            }
                                                        }
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {trasnposedData.size !== 0 && [...trasnposedData.keys()].map((data, rowIndex) => {
                                                    const details = data.split("=");
                                                    return (
                                                        <>

                                                            <tr

                                                                className={`font-semibold  transition-all ease-in-out  duration-500`}
                                                                key={rowIndex}>


                                                                {/* <td className='sticky min-w-[30px] shadow-col bg-white left-0 z-30 transform-all ease-in-out duration-500 h-full font-bold text-lg text-center hover:bg-slate-100 cursor-pointer'></td> */}

                                                                {<td className={`sticky ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  ${showMultiCellConfigIndex === rowIndex ? "z-40" : "z-30"}  shadow-col left-0 min-w-[50px]  text-center text-base no-download-col`}>
                                                                    <div className='flex flex-row items-center px-2 justify-between'>


                                                                        <div
                                                                            onClick={() => {
                                                                                if ((expandAllRows) || (expandRow === rowIndex)) {
                                                                                    setExpandRow(null)
                                                                                    setExpandAllRows(false)
                                                                                }
                                                                                else {
                                                                                    setExpandRow(rowIndex)
                                                                                }
                                                                            }
                                                                            }
                                                                            className={`transform-all cursor-pointer hover:text-blue-500 ease-in-out duration-200 ${(expandAllRows) || (expandRow === rowIndex) ? "rotate-180 transform-all ease-in-out duration-200" : ""}`}>
                                                                            < MdOutlineExpandCircleDown />
                                                                        </div>
                                                                        <div className='z-50 pl-1 ' ref={(el) => dropdownRefs.current[rowIndex] = el}>
                                                                            <div>
                                                                                <Button onClick={() => { setShowMultiCellConfigIndex(rowIndex) }} type='link' className={`!p-0  hover:!text-blue-500  ${showMultiCellConfigIndex === rowIndex ? "!text-blue-500" : "!text-gray-500"} `}>
                                                                                    <TiFlashOutline />

                                                                                </Button>
                                                                            </div>
                                                                            {
                                                                                showMultiCellConfigIndex === rowIndex &&
                                                                                <div className={`absolute z-50 top-0 left-14 text-xs font-normal`}>
                                                                                    <div className='bg-white shadow-md flex flex-col rounded-md border border-blue-500'>
                                                                                        <p className='font-semibold text-base text-bodyTextBlue px-2 py-1 rounded-tl-md rounded-tr-md border-b-2 text-start bg-slate-100'>Quick Actions</p>
                                                                                        <p onClick={() => setSelectedAdjType("0")} className={`px-2 py-1  ${selectedAdjType === "0" ? "bg-[#ed7d31] text-white" : "hover:bg-slate-100"} rounded-md w-full  text-start cursor-pointer`}>Make all forecasts 0</p>
                                                                                        <hr />
                                                                                        <p onClick={() => setSelectedAdjType("prev years")} className={`px-2 py-1 ${selectedAdjType === "prev years" ? "bg-[#ed7d31] text-white" : "hover:bg-slate-100"}  rounded-md w-full text-start cursor-pointer`}>Make all forecasts same as previous year same period</p>
                                                                                        <hr />
                                                                                        <div onClick={() => setSelectedAdjType("avg")} className={`px-2 py-1 ${selectedAdjType === "avg" ? "bg-[#ed7d31] text-white" : "hover:bg-slate-100"} rounded-md w-full text-start cursor-pointer`}>
                                                                                            Make all forecasts average of previous buckets
                                                                                            {
                                                                                                selectedAdjType == "avg" &&
                                                                                                <div className='flex gap-2 mt-1'>
                                                                                                    <label htmlFor="bucketLength">Enter bucket numbers</label>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        name="bucketLength"
                                                                                                        id="bucketLength"
                                                                                                        className="w-11 h-5 border-2 pl-2 border-slate-300 rounded-md text-black"
                                                                                                        min={2}
                                                                                                        value={bucketLength}
                                                                                                        onChange={(e) => setBucketLength(e.target.value)}
                                                                                                    />
                                                                                                </div>
                                                                                            }

                                                                                            {/* <span className='pl-4'>
                                                                                                <Button onClick={() => saveRowAdjustment("avg")} type='primary' className='!p-2 h-6 !text-xs'>
                                                                                                    Submit
                                                                                                </Button>
                                                                                            </span> */}
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className='flex justify-end pr-2 pb-2 pt-2'>
                                                                                            <Button onClick={() => setShowMultiCellConfigIndex(null)} type='link' className='!p-2 h-6 !text-xs'>
                                                                                                Cancel
                                                                                            </Button>
                                                                                            <Button onClick={() => saveRowAdjustment(details)} type='primary' className='!p-2 h-6 !text-xs'>
                                                                                                Submit
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                       
                                                                    </div>
                                                                </td>}

                                                              


                                                                {trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {

                                                                    if (item["isSplit"] === true) {
                                                                        return (
                                                                            <>
                                                                                <td className={`${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"} min-w-[55px] max-w-[55px]`}>
                                                                                    <p className='p-2 h-full w-full'>{Intl.NumberFormat('en-US', {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1
                                                                                    }).format(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(2)).toString() : 0)}</p>
                                                                                </td>
                                                                                <td
                                                                                    onDoubleClick={() => {
                                                                                        if (checkAllChangesArrayAreEmpty(changes)) {
                                                                                            setChanges((prevState) => [
                                                                                                ...prevState,
                                                                                                createNewEntry(item.FQTY_USER, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                            ]);
                                                                                        }
                                                                                    }}
                                                                                    className={`text-left text-xs min-w-[55px] max-w-[55px] px-0 -py-1 ${changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields))
                                                                                        ? ' text-white'
                                                                                        : 'bg-gray-50 text-[#4285F4]'
                                                                                        } ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}
                                                                                >
                                                                                    {changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields)) ? (
                                                                                        <input
                                                                                            type="text"
                                                                                            defaultValue={
                                                                                                item["FQTY"] !== null
                                                                                                    ? item["FQTY"] === item["FQTY_USER"]
                                                                                                        ? parseFloat(Number(item["FQTY"]).toFixed(2)).toString()
                                                                                                        : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString()
                                                                                                    : (0)
                                                                                            }
                                                                                            className={`p-2 h-full w-full bg-[#4285F4] ${item["FQTY"] !== null && item["FQTY"] !== item["FQTY_USER"]
                                                                                                ? "text-white bg-[#ed7d31]"
                                                                                                : ""}`}
                                                                                            onChange={(e) => {
                                                                                                e.target.className = `p-2 h-full w-full ${e.target.value !== item["FQTY"] ? "bg-[#ed7d31]" : "bg-[#4285F4]"
                                                                                                    }`;
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                setChanges((prevState) => prevState.slice(0, -1)); // Remove last entry before updating

                                                                                                setChanges((prevState) => [
                                                                                                    ...prevState,
                                                                                                    createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                                ]);
                                                                                            }}
                                                                                            autoFocus
                                                                                        />
                                                                                    ) : (
                                                                                        <p
                                                                                            className={`p-2 h-full w-full 
                                                                                    ${item["FQTY"] !== null ?
                                                                                                    item["FQTY"] === item["FQTY_USER"] ?
                                                                                                        "" : "text-white bg-[#ed7d31]"
                                                                                                    :
                                                                                                    ""}`}
                                                                                        >{Intl.NumberFormat('en-US', {
                                                                                            notation: "compact",
                                                                                            maximumFractionDigits: 1
                                                                                        }).format(item["FQTY"] !== null ?
                                                                                            (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                parseFloat(Number(item["FQTY"]).toFixed(2)).toString() : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString())
                                                                                            :
                                                                                            (0))}</p>
                                                                                    )}
                                                                                </td>
                                                                            </>
                                                                        );
                                                                    }
                                                                 

                                                                    // for normal fqty
                                                                    else if ((item["FQTY"] !== null && index > midIndex) || (item["SQTY"] === null && item["FQTY"] === null && index > midIndex)) {
                                                                        return (
                                                                            <td
                                                                                onDoubleClick={() => {
                                                                                    if (checkAllChangesArrayAreEmpty(changes)) {
                                                                                        setChanges((prevState) => [
                                                                                            ...prevState,
                                                                                            createNewEntry(item.FQTY_USER, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                        ]);
                                                                                    }
                                                                                }}
                                                                                className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1 ${changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields))
                                                                                    ? 'text-white'
                                                                                    : 'bg-gray-50 text-[#4285F4]'} ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}
                                                                            >
                                                                                {changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields)) ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        defaultValue={
                                                                                            item["FQTY"] !== null
                                                                                                ? item["FQTY"] === item["FQTY_USER"]
                                                                                                    ? parseFloat(Number(item["FQTY"]).toFixed(2)).toString()
                                                                                                    : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString()
                                                                                                : (0)
                                                                                        }
                                                                                        className={`p-2 h-full w-full bg-[#4285F4] ${item["FQTY"] !== null && item["FQTY"] !== item["FQTY_USER"]
                                                                                            ? "text-white bg-[#ed7d31]"
                                                                                            : ""}`}
                                                                                        onChange={(e) => {
                                                                                            e.target.className = `p-2 h-full w-full ${e.target.value !== item["FQTY"] ? "bg-[#ed7d31]" : "bg-[#4285F4]"
                                                                                                }`;
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            setChanges((prevState) => prevState.slice(0, -1)); // Remove last entry before updating

                                                                                            setChanges((prevState) => [
                                                                                                ...prevState,
                                                                                                createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                            ]);
                                                                                        }}
                                                                                        autoFocus
                                                                                    />
                                                                                ) : (
                                                                                    <p
                                                                                        className={`p-2 h-full w-full
                                                                                ${item["FQTY"] !== null ?
                                                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    "" : "text-white bg-[#ed7d31]"
                                                                                                :
                                                                                                ""}
                                                                            `}
                                                                                    >{Intl.NumberFormat('en-US', {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1
                                                                                    }).format(item["FQTY"] !== null ?
                                                                                        (item["FQTY"] === item["FQTY_USER"] ?
                                                                                            parseFloat(Number(item["FQTY"]).toFixed(2)).toString() : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString())
                                                                                        :
                                                                                        (0))}</p>
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    }

                                                                




                                                                })}
                                                            </tr>
                                                            {
                                                                ((expandAllRows) || (expandRow === rowIndex)) &&
                                                                <>

                                                                    {/* actuals */}
                                                                    {
                                                                        <tr className=' bg-slate-100 '>
                                                                            {
                                                                                additionalFields.length === 0 &&
                                                                                <td colSpan={1} className='sticky p-1 min-w-[30px] shadow-col bg-violet-50 left-0 z-30  h-full  text-bodyTextBlue  pl-3 text-sm  '>Actuals </td>
                                                                            }
                                                                            {
                                                                                additionalFields.length !== 0 &&
                                                                                <td colSpan={1} className='sticky p-1 min-w-[30px] shadow-col bg-violet-50 left-0 z-30  h-full  text-bodyTextBlue  pl-3 text-sm  '>Actuals</td>
                                                                            }
                                                                            {trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {

                                                                                if (item["isSplit"] === true) {
                                                                                    return (
                                                                                        <>
                                                                                            <td className='bg-violet-50 min-w-[55px] max-w-[55px]'>
                                                                                                <p className='p-2 h-full w-full'>{Intl.NumberFormat('en-US', {
                                                                                                    notation: "compact",
                                                                                                    maximumFractionDigits: 1
                                                                                                }).format(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>
                                                                                            </td>
                                                                                            <td className={`text-left min-w-[55px] max-w-[55px] text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}>

                                                                                                {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        defaultValue={item["FQTY"] !== null ? item["FQTY"] : 0}
                                                                                                        className={`p-2 h-full w-full bg-[#4285F4]`}
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.value !== item["FQTY"]) {
                                                                                                                e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                                            }
                                                                                                            else {
                                                                                                                e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                                            }
                                                                                                        }}
                                                                                                        onBlur={(e) => {
                                                                                                            setChanges(prevArray => prevArray.slice(0, -1))
                                                                                                            setChanges((prevState) => [...prevState, {
                                                                                                                FQTY_USER: parseInt(e.target.value),
                                                                                                                interval: intervalFiltered[index],
                                                                                                                [selectedItem]: details[0],
                                                                                                                [selectedLocation]: details[1],
                                                                                                                [selectedCustomer]: details[2]
                                                                                                            }])
                                                                                                        }}
                                                                                                        autoFocus
                                                                                                    />
                                                                                                ) : (
                                                                                                    <p className='p-2 h-full w-full'>{Intl.NumberFormat('en-US', {
                                                                                                        notation: "compact",
                                                                                                        maximumFractionDigits: 1
                                                                                                    }).format(item["FQTY"] !== null ? item["FQTY"] : 0)}</p>
                                                                                                )}
                                                                                            </td>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                              
                                                                                 else if (item["FQTY"] !== null && item["FQTY"] !== 0) {
                                                                                    return (
                                                                                        <td className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}>
                                                                                            {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                                <input
                                                                                                    type="text"
                                                                                                    defaultValue={item["FQTY"] !== null ? item["FQTY"] : 0}
                                                                                                    className={`p-2 h-full w-full bg-[#4285F4]`}
                                                                                                    onChange={(e) => {
                                                                                                        if (e.target.value !== item["FQTY"]) {
                                                                                                            e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                                        }
                                                                                                        else {
                                                                                                            e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={(e) => {
                                                                                                        setChanges(prevArray => prevArray.slice(0, -1))
                                                                                                        setChanges((prevState) => [...prevState, {
                                                                                                            FQTY_USER: parseInt(e.target.value),
                                                                                                            interval: intervalFiltered[index],
                                                                                                            [selectedItem]: details[0],
                                                                                                            [selectedLocation]: details[1],
                                                                                                            [selectedCustomer]: details[2]
                                                                                                        }])
                                                                                                    }}
                                                                                                    autoFocus
                                                                                                />
                                                                                            ) : (
                                                                                                <p className='p-2 h-full w-full bg-violet-50'>{Intl.NumberFormat('en-US', {
                                                                                                    notation: "compact",
                                                                                                    maximumFractionDigits: 1
                                                                                                }).format(item["FQTY"] !== null ? item["FQTY"] : 0)}</p>
                                                                                            )}
                                                                                        </td>
                                                                                    );
                                                                                }
                                                                              
                                                                                else if (item["FQTY"] !== null && item["FQTY"] === 0) {
                                                                                    return (
                                                                                        <td className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}>
                                                                                            {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                                <input
                                                                                                    type="text"
                                                                                                    defaultValue={item["FQTY"] !== null ? item["FQTY"] : 0}
                                                                                                    className={`p-2 h-full w-full bg-[#4285F4]`}
                                                                                                    onChange={(e) => {
                                                                                                        if (e.target.value !== item["FQTY"]) {
                                                                                                            e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                                        }
                                                                                                        else {
                                                                                                            e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={(e) => {
                                                                                                        setChanges(prevArray => prevArray.slice(0, -1))
                                                                                                        setChanges((prevState) => [...prevState, {
                                                                                                            FQTY_USER: parseInt(e.target.value),
                                                                                                            interval: intervalFiltered[index],
                                                                                                            [selectedItem]: details[0],
                                                                                                            [selectedLocation]: details[1],
                                                                                                            [selectedCustomer]: details[2]
                                                                                                        }])
                                                                                                    }}
                                                                                                    autoFocus
                                                                                                />
                                                                                            ) : (
                                                                                                <p className='p-2 h-full w-full bg-violet-50'>{Intl.NumberFormat('en-US', {
                                                                                                    notation: "compact",
                                                                                                    maximumFractionDigits: 1
                                                                                                }).format(item["FQTY"] !== null ? item["FQTY"] : 0)}</p>
                                                                                            )}
                                                                                        </td>
                                                                                    );
                                                                                }
                                                                                else if (item["SQTY"] === null && item["FQTY"] === null && index > midIndex) {
                                                                                    return (
                                                                                        <td className={`text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-violet-50 text-[#4285F4]'}`}>
                                                                                            {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                                <input
                                                                                                    type="text"
                                                                                                    defaultValue={item["FQTY"] !== null ? item["FQTY"] : 0}
                                                                                                    className={`p-2 h-full w-full bg-[#4285F4]`}
                                                                                                    onChange={(e) => {
                                                                                                        if (e.target.value !== item["FQTY"]) {
                                                                                                            e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                                        }
                                                                                                        else {
                                                                                                            e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={(e) => {
                                                                                                        setChanges(prevArray => prevArray.slice(0, -1))
                                                                                                        setChanges((prevState) => [...prevState, {
                                                                                                            FQTY_USER: parseInt(e.target.value),
                                                                                                            interval: intervalFiltered[index],
                                                                                                            [selectedItem]: details[0],
                                                                                                            [selectedLocation]: details[1],
                                                                                                            [selectedCustomer]: details[2]
                                                                                                        }])
                                                                                                    }}
                                                                                                    autoFocus
                                                                                                />
                                                                                            ) : (
                                                                                                <p className='p-2 h-full w-full bg-violet-50'>{Intl.NumberFormat('en-US', {
                                                                                                    notation: "compact",
                                                                                                    maximumFractionDigits: 1
                                                                                                }).format(item["FQTY"] !== null ? item["FQTY"] : 0)}</p>
                                                                                            )}
                                                                                        </td>
                                                                                    );
                                                                                }

                                                                            })}
                                                                        </tr>
                                                                    }


                                                                    {/* budget */}
                                                                    {

                                                                        <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_BUDGET'} changesArrayName={budgetChanges} setChangesArrayName={setBudgetChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Budget"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                    }

                                                                    {/* {
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Open Orders"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }
                                                                    {
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Quotations"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    } */}
                                                                    {/* {
                                                                        Expandchecklist["Year ago"] &&
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Year ago"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    } */}
                                                                    {/* {
                                                                        Expandchecklist["Forecast (OC)"] &&
                                                                        <>
                                                                            <CommonExpandedRow name={"Forecast (OC)"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    } */}

                                                                    {/* {
                                                                        Expandchecklist['Confirmed Orders'] &&
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Confirmed Orders"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    } */}
                                                                    {/* 
                                                                    {
                                                                        Expandchecklist['Due Orders'] &&
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Due Orders"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Weather'] &&
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Weather"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Price'] &&
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Price"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    } */}

                                                                    {
                                                                        <>
                                                                            <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_PROMOTION'} changesArrayName={promotionChanges} setChangesArrayName={setPromotionChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Promotions"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        <>
                                                                            <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_EVENT'} changesArrayName={eventChanges} setChangesArrayName={setEventChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Events"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }


                                                                    {
                                                                        <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_SALES'} changesArrayName={salesChanges} setChangesArrayName={setSalesChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Sales Inputs"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                    }


                                                                    {
                                                                        <>
                                                                            <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_CONSENSUS'} changesArrayName={consensusChanges} setChangesArrayName={setConsensusChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Final Consensus"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }

                                                                    {/* {
                                                                        Expandchecklist['Inventory'] &&
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Inventory"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    } */}
                                                                    {
                                                                        <>
                                                                            <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_MARKET'} changesArrayName={marketChanges} setChangesArrayName={setMarketChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Marketing Inputs"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }

                                                                    {
                                                                        <>
                                                                            <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_DISCONTINUED'} changesArrayName={disChanges} setChangesArrayName={setDisChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Discontinued Products"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }
                                                                    {
                                                                        <>
                                                                            <CommonEditableRow checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_NEW'} changesArrayName={newChanges} setChangesArrayName={setNewChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"New Products"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }

                                                                    {/* {
                                                                        Expandchecklist['Point of Sales'] &&
                                                                        <>
                                                                            <CommonExpandedRow name={"Point of Sales"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    } */}

                                                                    {/* {
                                                                        Expandchecklist['Sell out'] &&
                                                                        <>
                                                                            <CommonExpandedRow tdateVar={tdateVar} fields={fields} name={"Sell out"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }
                                                                    {
                                                                        Expandchecklist["Opportunities"] &&
                                                                        <>
                                                                            <tr className=''>
                                                                                <td colSpan={1000} className='sticky  p-0 '>
                                                                                    <ProposalTable
                                                                                        trasnposedData={trasnposedData}
                                                                                        tdateVar={tdateVar}
                                                                                        startDate={startDate}
                                                                                        endDate={endDate}
                                                                                        midIndex={midIndex}
                                                                                        tdRef={tdRef}
                                                                                        setDetailedProposal={setDetailedProposal}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                            {
                                                                                detailedProposal &&
                                                                                <DetailedProposal
                                                                                    setSelectedItem={setSelectedItem}
                                                                                    setSelectedLocation={setSelectedLocation}
                                                                                    setSelectedCustomer={setSelectedCustomer}
                                                                                    fields={fields}
                                                                                    setFields={setFields}
                                                                                    uiMappingData={uiMappingData}
                                                                                    additionalFields={additionalFields}
                                                                                    trasnposedData={trasnposedData}
                                                                                    midIndex={midIndex}
                                                                                    tdRef={tdRef}
                                                                                    tdateVar={tdateVar}
                                                                                    startDate={startDate}
                                                                                    endDate={endDate}
                                                                                    setDetailedProposal={setDetailedProposal}
                                                                                    data={data}
                                                                                    details={details}
                                                                                />
                                                                            }
                                                                        </>
                                                                    } */}

                                                                    {/* <tr className=''>
                                                                        <td colSpan={13} className='sticky left-0 border-none w-full p-0 z-10'>
                                                                            <ExpandCheckboxList
                                                                                expandAllRows={expandAllRows}
                                                                                setExpandAllRows={setExpandAllRows}
                                                                                Expandchecklist={Expandchecklist}
                                                                                setExpandchecklist={setExpandchecklist} />
                                                                        </td>
                                                                    </tr> */}

                                                                </>
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </>)}

                                
                            
                            </div>
                        }
                        {/* {checkBoxOverLay === true ?
                            <Overlay
                                fields={columnsShown.length !== 0 ? columnsShown : [fields["item"], fields["location"], fields["category"]]}
                                locationFilters={locationFilters}
                                productFilters={productFilters}
                                customerFilters={customerFilters}
                                uiMappingData={uiMappingData}
                                setFields={setColumnShown}
                                setNext={setNext}
                                onClose={() => setCheckBoxOverlay(false)} /> : null
                        } */}
                        {/* {
                            overlayFlag === true ?
                                <EditOverlay

                                    adjLoading={adjLoading}
                                    setAdjLoading={setAdjLoading}
                                    getData={getData}
                                    getGraphData={getGraphData}
                                    changes={changes}
                                    salesChanges={salesChanges}
                                    eventChanges={eventChanges}
                                    marketChanges={marketChanges}
                                    promotionChanges={promotionChanges}
                                    consensusChanges={consensusChanges}
                                    setOverlayFlag={setOverlayFlag}
                                    setChanges={setChanges} /> : null
                        } */}
                        {/* {
                            inATTFilterPopUp &&
                            <FilterInATTPopUp />
                        } */}
                        {/* {
                            true &&
                            <DetailedProposal
                                setSelectedItem={setSelectedItem}
                                setSelectedLocation={setSelectedLocation}
                                setSelectedCustomer={setSelectedCustomer}
                                fields={fields}
                                setFields={setFields}
                                uiMappingData={uiMappingData}
                                additionalFields={additionalFields}
                                trasnposedData={trasnposedData}
                                midIndex={midIndex}
                                tdateVar={tdateVar}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        } */}
                        {/* {
                            graphEditPopUp &&
                            <GraphEditPopUp />
                        } */}

                    </>
            }
            <Tooltip id="my-tooltip" />

        </div>
    )
}

export default PostTable