import React, { useState } from 'react'
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext'
import DemandGraph from './DemandGraph';
import GrowthLineGraph from './GrowthLineGraph';
import GrowthChart from './GrowthChart';
import TargetAchievementPie from './TargetAchievementPie';
import { BsInfoCircleFill } from "react-icons/bs";
import InsightsSection from './InsightsSections';

const Summary = ({ demandReport }) => {
    const {
        itemGraphData,
        customerGraphData,
        locationGraphData } = useUpload();




    //pareto
    const pareto_product_count = demandReport['Pareto_Analysis']['Products'].length
    const pareto_location_count = demandReport['Pareto_Analysis']['Locations'].length
    const pareto_customer_count = demandReport['Pareto_Analysis']['Customers'].length


    const sortData = (data, sortOrder) => {
        const sortedXAxis = sortOrder;
        const sortedCount = {};
        const sortedVolume = {};

        // Sort the `count` and `volume` objects based on `sortOrder`
        sortOrder.forEach((key) => {
            sortedCount[key] = data["y-axis"].count[key];
            sortedVolume[key] = data["y-axis"].volume[key];
        });

        return {
            "x-axis": sortedXAxis,
            "y-axis": {
                count: sortedCount,
                volume: sortedVolume,
            },
        };
    };

    itemGraphData['ISEGMENT2'] = sortData(itemGraphData['ISEGMENT2'], ["A", "B", "C"]);
    customerGraphData['CSEGMENT2'] = sortData(customerGraphData['CSEGMENT2'], ["A", "B", "C"]);
    locationGraphData['LSEGMENT2'] = sortData(locationGraphData['LSEGMENT2'], ["A", "B", "C"]);

    itemGraphData['ISEGMENT3'] = sortData(itemGraphData['ISEGMENT3'], ["LOW_VOLATILITY", "MEDIUM_VOLATILITY", "HIGH_VOLATILITY"]);
    customerGraphData['CSEGMENT3'] = sortData(customerGraphData['CSEGMENT3'], ["LOW_VOLATILITY", "MEDIUM_VOLATILITY", "HIGH_VOLATILITY"]);
    locationGraphData['LSEGMENT3'] = sortData(locationGraphData['LSEGMENT3'], ["LOW_VOLATILITY", "MEDIUM_VOLATILITY", "HIGH_VOLATILITY"]);

    itemGraphData['ISEGMENT5'] = sortData(itemGraphData['ISEGMENT5'], ["LOW_SPORADICITY", "MEDIUM_SPORADICITY", "HIGH_SPORADICITY"]);
    customerGraphData['CSEGMENT5'] = sortData(customerGraphData['CSEGMENT5'], ["LOW_SPORADICITY", "MEDIUM_SPORADICITY", "HIGH_SPORADICITY"]);
    locationGraphData['LSEGMENT5'] = sortData(locationGraphData['LSEGMENT5'], ["LOW_SPORADICITY", "MEDIUM_SPORADICITY", "HIGH_SPORADICITY"]);


    //growth and trend
    const short_term_product_growth_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "SHORT-TERM GROWTH").length;
    const long_term_product_growth_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "LONG-TERM GROWTH").length;

    const short_term_location_growth_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "SHORT-TERM GROWTH").length;
    const long_term_location_growth_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "LONG-TERM GROWTH").length;

    const short_term_customer_growth_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "SHORT-TERM GROWTH").length;
    const long_term_customer_growth_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "LONG-TERM GROWTH").length;


    const short_term_product_growth = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "SHORT-TERM GROWTH")
    const top_5_short_term_product = short_term_product_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const long_term_product_growth = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "LONG-TERM GROWTH")
    const top_5_long_term_product = long_term_product_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const short_term_location_growth = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "SHORT-TERM GROWTH")
    const top_5_short_term_location = short_term_location_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const long_term_location_growth = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "LONG-TERM GROWTH")
    const top_5_long_term_location = long_term_location_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);


    const short_term_customer_growth = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "SHORT-TERM GROWTH")
    const top_5_short_term_customer = short_term_customer_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const long_term_customer_growth = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "LONG-TERM GROWTH")
    const top_5_long_term_customer = long_term_customer_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);


    //decline
    const short_term_product_decline_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "SHORT-TERM DECLINE").length;
    const long_term_product_decline_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "LONG-TERM DECLINE").length;

    const short_term_location_decline_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "SHORT-TERM DECLINE").length;
    const long_term_location_decline_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "LONG-TERM DECLINE").length;

    const short_term_customer_decline_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "SHORT-TERM DECLINE").length;
    const long_term_customer_decline_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "LONG-TERM DECLINE").length;

    // Short-Term and Long-Term Product Decline (Bottom 5)
    const short_term_product_decline = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "SHORT-TERM DECLINE")
    const bottom_5_short_term_product = short_term_product_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Long-Term Product Decline (Bottom 5)
    const long_term_product_decline = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "LONG-TERM DECLINE")
    const bottom_5_long_term_product = long_term_product_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Short-Term Location Decline (Bottom 5)
    const short_term_location_decline = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "SHORT-TERM DECLINE")
    const bottom_5_short_term_location = short_term_location_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Long-Term Location Decline (Bottom 5)
    const long_term_location_decline = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "LONG-TERM DECLINE")
    const bottom_5_long_term_location = long_term_location_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Short-Term Customer Decline (Bottom 5)
    const short_term_customer_decline = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "SHORT-TERM DECLINE")
    const bottom_5_short_term_customer = short_term_customer_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Long-Term Customer Decline (Bottom 5)
    const long_term_customer_decline = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "LONG-TERM DECLINE")
    const bottom_5_long_term_customer = long_term_customer_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);


    //volatility

    // Function to get counts and top 5 entities for a given volatility segment and category
    const getVolatilityData = (data, category, segment) => {
        const items = data[category].filter(item => item.volatility_segment === segment);
        const count = items.length;
        const top5 = items.sort((a, b) => b.volume - a.volume).slice(0, 5);
        return { count, top5 };
    };

    // Products
    const { count: low_volatile_product_count, top5: top_5_low_volatile_products } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Products', 'LOW_VOLATILITY'
    );
    const { count: med_volatile_product_count, top5: top_5_med_volatile_products } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Products', 'MEDIUM_VOLATILITY'
    );
    const { count: high_volatile_product_count, top5: top_5_high_volatile_products } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Products', 'HIGH_VOLATILITY'
    );

    // Locations
    const { count: low_volatile_location_count, top5: top_5_low_volatile_locations } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Locations', 'LOW_VOLATILITY'
    );
    const { count: med_volatile_location_count, top5: top_5_med_volatile_locations } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Locations', 'MEDIUM_VOLATILITY'
    );
    const { count: high_volatile_location_count, top5: top_5_high_volatile_locations } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Locations', 'HIGH_VOLATILITY'
    );

    // Customers
    const { count: low_volatile_customer_count, top5: top_5_low_volatile_customers } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Customers', 'LOW_VOLATILITY'
    );
    const { count: med_volatile_customer_count, top5: top_5_med_volatile_customers } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Customers', 'MEDIUM_VOLATILITY'
    );
    const { count: high_volatile_customer_count, top5: top_5_high_volatile_customers } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Customers', 'HIGH_VOLATILITY'
    );



    //age

    // Reusable function to get counts and top 5 entities
    const getAgeData = (data, category, segmentKey, segmentValue) => {
        const items = data[category].filter(item => item[segmentKey] === segmentValue);
        const count = items.length;
        const top5 = items.sort((a, b) => b.volume - a.volume).slice(0, 5);
        return { count, top5 };
    };

    // Products
    const { count: new_age_product_count, top5: top_5_new_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'NEW_AGE'
    );
    const { count: mid_age_product_count, top5: top_5_mid_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'MID_AGE'
    );
    const { count: mid_mat_age_product_count, top5: top_5_mid_mat_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'MID_MATURED_AGE'
    );
    const { count: long_age_product_count, top5: top_5_long_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'LONG_AGE'
    );

    // Locations
    const { count: new_age_location_count, top5: top_5_new_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'NEW_AGE'
    );
    const { count: mid_age_location_count, top5: top_5_mid_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'MID_AGE'
    );
    const { count: mid_mat_age_location_count, top5: top_5_mid_mat_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'MID_MATURED_AGE'
    );
    const { count: long_age_location_count, top5: top_5_long_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'LONG_AGE'
    );

    // Customers
    const { count: new_age_customer_count, top5: top_5_new_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'NEW_AGE'
    );
    const { count: mid_age_customer_count, top5: top_5_mid_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'MID_AGE'
    );
    const { count: mid_mat_age_customer_count, top5: top_5_mid_mat_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'MID_MATURED_AGE'
    );
    const { count: long_age_customer_count, top5: top_5_long_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'LONG_AGE'
    );



    const removeDuplicates = (data) => {
        return {
          Products: [
            ...new Map(data.Products.map((item) => [item.ITEM, item])).values(),
          ],
          Customers: [
            ...new Map(data.Customers.map((customer) => [customer.CUSTOMER, customer])).values(),
          ],
          Locations: [
            ...new Map(data.Locations.map((location) => [location.LOCATION, location])).values(),
          ],
        };
      };
      demandReport['Phased_Out'] = removeDuplicates(demandReport['Phased_Out'])
    //phased out 
    const phased_out_products = demandReport['Phased_Out']['Products']
    const phased_out_location = demandReport['Phased_Out']['Locations']
    const phased_out_customer = demandReport['Phased_Out']['Customers']



    //new entities 
    const new_products = demandReport['New_Entities']['Products']
    const new_location = demandReport['New_Entities']['Locations']
    const new_customer = demandReport['New_Entities']['Customers']


    //sporadicity
    const low_sp_product_count = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "LOW_SPORADICITY").length
    const low_sp_product = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "LOW_SPORADICITY")
    const top_5_low_sp_product = low_sp_product
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);



    const med_sp_product_count = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "MEDIUM_SPORADICITY").length;
    const med_sp_product = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "MEDIUM_SPORADICITY");
    const top_5_med_sp_product = med_sp_product
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const high_sp_product_count = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "HIGH_SPORADICITY").length;
    const high_sp_product = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "HIGH_SPORADICITY");
    const top_5_high_sp_product = high_sp_product
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);


    const low_sp_location_count = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "LOW_SPORADICITY").length;
    const low_sp_location = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "LOW_SPORADICITY");
    const top_5_low_sp_location = low_sp_location
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const med_sp_location_count = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "MEDIUM_SPORADICITY").length;
    const med_sp_location = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "MEDIUM_SPORADICITY");
    const top_5_med_sp_location = med_sp_location
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const high_sp_location_count = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "HIGH_SPORADICITY").length;
    const high_sp_location = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "HIGH_SPORADICITY");
    const top_5_high_sp_location = high_sp_location
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);


    const low_sp_customer_count = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "LOW_SPORADICITY").length;
    const low_sp_customer = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "LOW_SPORADICITY");
    const top_5_low_sp_customer = low_sp_customer
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const med_sp_customer_count = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "MEDIUM_SPORADICITY").length;
    const med_sp_customer = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "MEDIUM_SPORADICITY");
    const top_5_med_sp_customer = med_sp_customer
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const high_sp_customer_count = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "HIGH_SPORADICITY").length;
    const high_sp_customer = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "HIGH_SPORADICITY");
    const top_5_high_sp_customer = high_sp_customer
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);




    // // Retrieve demandReport from localStorage
    // const demandReportString = localStorage.getItem("demandReport");

    // let demandReport = null;

    // if (demandReportString) {
    //     try {
    //         // Parse the stored string into a JSON object
    //         demandReport = JSON.parse(demandReportString);
    //         console.log(demandReport);
    //     } catch (error) {
    //         console.error("Error parsing demandReport from localStorage:", error);
    //     }
    // } else {
    //     console.warn("No demandReport found in localStorage");
    // }

    const [visibleSections, setVisibleSections] = useState({
        yearOverYearGrowth: false,
        abcClassification: false,
        growthAndDecline: false,
        demandVariability: false,
        maturityAnalysis: false,
        productLifecycle: false,
        orderFrequency: false,
    });
    return (
        <>
            {
                demandReport !== null &&
                <div className='w-full flex flex-col text-sm mt-3 h-full overflow-y-scroll scroll text-slate-500'>
                    <div className='bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md '>
                        <div className='flex gap-2 items-center  mb-2'>

                            <h3 className='text-base font-semibold text-[#4d8bf5]'>Year-over-year growth across business</h3>
                            <span className='cursor-pointer relative  transition-all duration-300 ease-in-out hover:text-[#4d8bf5]' onClick={() => setVisibleSections(prev => ({ ...prev, yearOverYearGrowth: true }))}>
                                <BsInfoCircleFill />
                                {
                                    visibleSections.yearOverYearGrowth &&
                                    <InsightsSection visibleSections={visibleSections} setVisibleSections={setVisibleSections} />
                                }
                            </span>
                        </div>

                        <p>The average yearly growth is <span className={`${demandReport['Year_Over_Growth_Text'] >= 0 ? "text-green-500" : "text-red-500"}`}>{Number(demandReport['Year_Over_Growth_Text']).toFixed(2)} %</span></p>
                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>

                            <GrowthLineGraph data={demandReport['Year_Over_Growth_Data']} />
                            <GrowthChart demandReport={demandReport} />
                            <TargetAchievementPie demandReport={demandReport} />
                        </div>
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>

                        {/* <h3 className='text-base font-semibold mb-2 text-[#4d8bf5]'>Highest growing product(s), location(s) and customer(s) </h3>

                        <div className='grid grid-cols-3 gap-2'>
                            <div>

                                <div className='grid grid-cols-2 bg-white '>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Products</p>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Sales</p>
                                    {
                                        demandReport['Top_Growing_Entities']['Products'].map((data) => (
                                            <>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['ITEM']}</p>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['total_sales']}</p>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            <div>
                                <div className='grid grid-cols-2 bg-white '>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Locations</p>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Sales</p>
                                    {
                                        demandReport['Top_Growing_Entities']['Locations'].map((data) => (
                                            <>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['LOCATION']}</p>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['total_sales']}</p>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            <div>
                                <div className='grid grid-cols-2 bg-white '>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Customers</p>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Sales</p>
                                    {
                                        demandReport['Top_Growing_Entities']['Customers'].map((data) => (
                                            <>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['CUSTOMER']}</p>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['total_sales']}</p>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div> */}
                        <div className='flex gap-2 items-center  mb-2'>

                            <h3 className='text-base font-semibold my-2 text-[#4d8bf5]'>ABC classification by product, location and customer</h3>
                            <span onClick={() => setVisibleSections(prev => ({ ...prev, abcClassification: true }))} className='cursor-pointer relative  transition-all duration-300 ease-in-out hover:text-[#4d8bf5]' >
                                <BsInfoCircleFill />
                                {
                                    visibleSections.abcClassification &&
                                    <InsightsSection visibleSections={visibleSections} setVisibleSections={setVisibleSections} />
                                }
                            </span>
                        </div>
                        <p className='mt-2'>A Pareto analysis of your sales history shows that <span className='font-bold'>{pareto_product_count}</span> product(s) contribute to 80% of your sales</p>
                        {/* <p className='font-bold'>Side Bar Graph Showing the top Products and their contribution to sales </p> */}

                        <p className='mt-2'>and <span className='font-bold'>{pareto_location_count}</span> location(s) represent 80% of the total volume</p>
                        {/* <p className='font-bold'>Side Bar Graph Showing the top Customers and their contribution to sales </p> */}


                        <p className='mt-2'>whereas <span className='font-bold'>{pareto_customer_count}</span> customer(s) buy 80% of the total volume</p>
                        {/* <p className='font-bold'>Side Bar Graph Showing the top locations and their contribution to sales </p> */}
                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT2']} tableName={"Product volume"} />
                            <DemandGraph data={locationGraphData['LSEGMENT2']} tableName={"Location volume"} />
                            <DemandGraph data={customerGraphData['CSEGMENT2']} tableName={"Customer volume"} />
                        </div>

                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <div className='flex gap-2 items-center  mb-2'>

                            <h3 className='text-base font-semibold  text-[#4d8bf5]'>Growth and decline across products, locations, and customers</h3>
                            <span onClick={() => setVisibleSections(prev => ({ ...prev, growthAndDecline: true }))} className='cursor-pointer relative  transition-all duration-300 ease-in-out hover:text-[#4d8bf5]' >
                                <BsInfoCircleFill />
                                {
                                    visibleSections.growthAndDecline &&
                                    <InsightsSection visibleSections={visibleSections} setVisibleSections={setVisibleSections} />
                                }
                            </span>
                        </div>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Growing patterns</h6>
                        {
                            !(short_term_product_growth_count === 0 && long_term_product_growth_count === 0) &&
                            <>
                                <p><span className='font-bold'>{short_term_product_growth_count}</span> product(s) show a short-term growth and <span className='font-bold'>{long_term_product_growth_count}</span> show long-term growth in the sales history</p>
                                <p>These are the top short-term and long-term growing product(s):</p>
                                <div>
                                    <div className='grid grid-cols-2 gap-4 w-fit '>
                                        {
                                            short_term_product_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2 '>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_short_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>

                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        {
                                            long_term_product_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2 '>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_long_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {
                            !(short_term_location_growth_count === 0 && long_term_location_growth_count === 0) &&
                            <>
                                <p className='mt-2'><span className='font-bold'>{short_term_location_growth_count}</span> location(s) show short-term growth and <span className='font-bold'>{long_term_location_growth_count}</span> show long-term growth in the sales history</p>
                                <p>These are the top short-term and long-term growing location(s):</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_location_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_short_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_location_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_long_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {
                            !(short_term_customer_growth_count === 0 && long_term_customer_growth_count === 0) &&
                            <>
                                <p className='mt-2'><span className='font-bold'>{short_term_customer_growth_count}</span> customer(s) show short-term growth and <span className='font-bold'>{long_term_customer_growth_count}</span> show long-term growth in the sales history</p>
                                <p>These are the top short-term and long-term growing customer(s):</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_customer_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_short_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_customer_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_long_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }

                        {/* </div> */}
                        {/* <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-base font-semibold mb-2 text-[#4d8bf5]'>Decline</h3> */}
                        <h6 className='text-base font-semibold text-slate-600 mt-4'>Declining patterns</h6>

                        {
                            !(short_term_product_decline_count === 0 && long_term_product_decline_count === 0) &&
                            <>
                                <p className='mt-2'><span className='font-bold'>{short_term_product_decline_count}</span> product(s) show a short-term decline and <span className='font-bold'>{long_term_product_decline_count}</span> show long-term decline in the sales history</p>
                                <p>These are the top short-term and long-term declining product(s):</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_product_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_short_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_product_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_long_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {
                            !(short_term_location_decline_count === 0 && long_term_location_decline_count === 0) &&
                            <>
                                <p className='mt-2'><span className='font-bold'>{short_term_location_decline_count}</span> location(s) show a short-term decline and <span className='font-bold'>{long_term_location_decline_count}</span> show long-term decline in the sales history</p>
                                <p>These are the top short-term and long-term declining location(s):</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_location_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_short_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_location_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_long_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>

                            </>
                        }
                        {
                            !(short_term_customer_decline_count === 0 && long_term_customer_decline_count === 0) &&
                            <>
                                <p className='mt-2'><span className='font-bold'>{short_term_customer_decline_count}</span> customer(s) show a short-term decline and <span className='font-bold'>{long_term_customer_decline_count}</span> show long-term decline in the sales history</p>
                                <p>These are the top short-term and long-term declining customer(s)</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_customer_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_short_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_customer_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_long_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }

                        <div className='grid grid-cols-3 w-full gap-4 mt-4 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT6']} tableName={"Product trend"} />
                            <DemandGraph data={locationGraphData['LSEGMENT6']} tableName={"Location trend"} />
                            <DemandGraph data={customerGraphData['CSEGMENT6']} tableName={"Customer trend"} />
                        </div>

                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <div className='flex gap-2 items-center  mb-2'>

                            <h3 className='text-base font-semibold  text-[#4d8bf5]'>Demand variability</h3>
                            <span onClick={() => setVisibleSections(prev => ({ ...prev, demandVariability: true }))} className='cursor-pointer relative  transition-all duration-300 ease-in-out hover:text-[#4d8bf5]' >
                                <BsInfoCircleFill />
                                {
                                    visibleSections.demandVariability &&
                                    <InsightsSection visibleSections={visibleSections} setVisibleSections={setVisibleSections} />
                                }
                            </span>
                        </div>
                        <p className=''>Demand variability/volatility is a measure to showcase how regularly or irregularly products are bought, customers order, or locations are shipped/sold from. </p>

                        <p>Based on your data, <span className='font-bold'>{high_volatile_product_count}</span> product(s) seem to have high volatile sales pattern, <span className='font-bold'>{med_volatile_product_count}</span> product(s) are in the mid-volatility range and <span className='font-bold'>{low_volatile_product_count}</span> product(s) are in the low-volatility range.  </p>
                        {
                            !(high_volatile_product_count === 0 && med_volatile_product_count === 0 && low_volatile_product_count === 0) &&
                            <p className=''>Below are product(s) with the highest sales in each of these categories:</p>
                        }

                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_volatile_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High volatile product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_volatile_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_volatile_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid volatile product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_volatile_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_volatile_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low volatile product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_volatile_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>


                        <p className='mt-2'>Bases on you data, <span className='font-bold'>{high_volatile_location_count}</span> location(s) are sold from irregularly, <span className='font-bold'>{med_volatile_location_count}</span> location(s) are sold from relatively regularly and <span className='font-bold'>{low_volatile_location_count}</span> location(s) are sold from regularly</p>
                        {
                            !(high_volatile_location_count === 0 && med_volatile_location_count === 0 && low_volatile_location_count === 0) &&
                            <p>Below are top location(s) by volume for each of these categories:</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_volatile_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High volatile location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_volatile_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_volatile_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid volatile location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_volatile_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_volatile_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low volatile Location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_volatile_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>

                        <p className='mt-2'>Based on your data, <span className='font-bold'>{high_volatile_customer_count}</span> customer(s) seems to order irregularly, <span className='font-bold'>{med_volatile_customer_count}</span> customer(s) order relatively regularly and <span className='font-bold'>{low_volatile_customer_count}</span> customer(s) order regularly.</p>
                        {
                            !(high_volatile_customer_count === 0 && med_volatile_customer_count === 0 && low_volatile_customer_count === 0) &&
                            <p>Below are top customer(s) by volume for each of these categories:</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_volatile_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High volatile customer(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_volatile_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_volatile_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid volatile customer(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_volatile_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_volatile_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low volatile customer(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_volatile_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>


                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT3']} tableName={"Product volatility"} />
                            <DemandGraph data={locationGraphData['LSEGMENT3']} tableName={"Location volatility"} />
                            <DemandGraph data={customerGraphData['CSEGMENT3']} tableName={"Customer volatility"} />
                        </div>
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <div className='flex gap-2 items-center  mb-2'>
                            <h3 className='text-base font-semibold text-[#4d8bf5]'>Order frequency distribution </h3>
                            <span onClick={() => setVisibleSections(prev => ({ ...prev, orderFrequency: true }))} className='cursor-pointer relative  transition-all duration-300 ease-in-out hover:text-[#4d8bf5]' >
                                <BsInfoCircleFill />
                                {
                                    visibleSections.orderFrequency &&
                                    <InsightsSection visibleSections={visibleSections} setVisibleSections={setVisibleSections} />
                                }
                            </span>
                        </div>
                        <p>Order frequency shows how regularly a product, location, or customer records sales activity. For example, if a product sells in alternate months, its sales frequency is 50%, as it sells half the time. </p>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Products</h6>
                        <p className='mt-1'>Based on your data, <span className='font-bold'>{high_sp_product_count}</span> product(s) have a high order frequency, whereas <span className='font-bold'>{med_sp_product_count}</span> product(s) have medium order frequency and <span className='font-bold'>{low_sp_product_count}</span> product(s) have low order frequency</p>
                        {
                            !(high_sp_product_count === 0 && med_sp_product_count === 0 && low_sp_product_count === 0) &&
                            <p>Below are top product(s) by volume for each of these categories:</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_sp_product.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High sporadicity product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_sp_product.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_sp_product.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Medium sporadicity product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_sp_product.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_sp_product.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low sporadicity product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_sp_product.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Locations</h6>
                        <p className='mt-1'>Based on your data, <span className='font-bold'>{high_sp_location_count}</span> locations(s) have a high order frequency, whereas <span className='font-bold'>{med_sp_location_count}</span> location(s) have medium order frequency and <span className='font-bold'>{low_sp_location_count}</span> location(s) have low order frequency</p>
                        {
                            !(high_sp_location_count === 0 && med_sp_location_count === 0 && low_sp_location_count === 0) &&
                            <p>Below are top location(s) by volume for each of these categories:</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_sp_location.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High sporadicity location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_sp_location.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_med_sp_location.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Medium sporadicity location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_sp_location.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_low_sp_location.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low sporadicity location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_sp_location.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Customers</h6>
                        <p className='mt-1'>Based on your data, <span className='font-bold'>{high_sp_customer_count}</span> customer(s) have a high order frequency, whereas <span className='font-bold'>{med_sp_customer_count}</span> customer(s) have medium order frequency and <span className='font-bold'>{low_sp_customer_count}</span> customer(s) have low order frequency</p>
                        {
                            !(high_sp_customer_count === 0 && med_sp_customer_count === 0 && low_sp_customer_count === 0) &&
                            <p>Below are top customer(s) of each category</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_sp_customer.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High sporadicity Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_sp_customer.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_med_sp_customer.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid sporadicity Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_sp_customer.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_low_sp_customer.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low sporadicity Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_sp_customer.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>


                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT5']} tableName={"Product sporadicity"} />
                            <DemandGraph data={locationGraphData['LSEGMENT5']} tableName={"Location sporadicity"} />
                            <DemandGraph data={customerGraphData['CSEGMENT5']} tableName={"Customer sporadicity"} />
                        </div>
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <div className='flex gap-2 items-center  mb-2'>

                            <h3 className='text-base font-semibold  text-[#4d8bf5]'>Maturity of the product portfolio, location service history, and customer base </h3>
                            <span onClick={() => setVisibleSections(prev => ({ ...prev, maturityAnalysis: true }))} className='cursor-pointer relative  transition-all duration-300 ease-in-out hover:text-[#4d8bf5]' >
                                <BsInfoCircleFill />
                                {
                                    visibleSections.maturityAnalysis &&
                                    <InsightsSection visibleSections={visibleSections} setVisibleSections={setVisibleSections} />
                                }
                            </span>
                        </div>
                        <p>The maturity showcases how long a product has been selling, how long a location has been shipping or selling products, and how long a customer has been ordering from you.  </p>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Products</h6>
                        <p>Based on your data, <span className='font-bold'>{long_age_product_count}</span> product(s) are mature, meaning they have more than 25 months of sales history,
                            <br />whereas <span className='font-bold'>{mid_mat_age_product_count}</span> product(s) are relatively mature, meaning they have between 13-25 months of sales history
                            <br />and <span className='font-bold'>{ mid_age_product_count + new_age_product_count}</span> product(s) are relatively new, meaning they have less than 13 months of sales history
                            {/* <br />and <span className='font-bold'>{new_age_product_count}</span> product(s) are new in the market, meaning they have less than 3 months of sales history */}
                        </p>
                        {
                            !(long_age_product_count === 0 && mid_age_product_count && mid_mat_age_product_count && new_age_product_count) &&
                            <p>Below are the top product(s) by volume for each of these categories:</p>
                        }
                        <div className='grid grid-cols-4 gap-1'>
                            {
                                [...top_5_mid_age_products, ...top_5_new_age_products].length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>New product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {[...top_5_mid_age_products,...top_5_new_age_products].map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {/* {
                                top_5_mid_age_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid age product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_age_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            } */}
                            {
                                top_5_mid_mat_age_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid matured age product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_mat_age_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_long_age_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Matured product(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_long_age_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Locations</h6>
                        <p>Based on your data <span className='font-bold'>{long_age_location_count}</span> location(s) are established, meaning they have more than 25 months of service history,
                            <br />whereas <span className='font-bold'>{mid_mat_age_location_count}</span> location(s) are emerging, meaning they have between 13-25 months of service history
                            <br />and <span className='font-bold'>{mid_age_location_count + new_age_location_count}</span> location(s) are new, meaning they have between 3-13 months of service history
                            {/* <br />and <span className='font-bold'>{new_age_location_count}</span> location(s) are very new, meaning they have less than 3 months of service history */}
                        </p>
                        {
                            !(long_age_location_count === 0 && mid_age_location_count && mid_mat_age_location_count && new_age_location_count) &&
                            <p>Below are the top location(s) by volume for each of these categories:</p>
                        }
                        <div className='grid grid-cols-4 gap-1'>
                            {
                                [...top_5_mid_age_locations, ...top_5_new_age_locations].length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>New location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {[...top_5_mid_age_locations,...top_5_new_age_locations].map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {/* {
                                top_5_mid_age_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid age location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_age_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            } */}
                            {
                                top_5_mid_mat_age_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Emerging location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_mat_age_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_long_age_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Established location(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_long_age_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Customers</h6>
                        <p>Based on your data, <span className='font-bold'>{long_age_customer_count}</span> customer(s) are loyal, meaning they have ordered for more than 25 months,
                            <br />whereas <span className='font-bold'>{mid_mat_age_customer_count}</span> customer(s) are growing, meaning they have ordered between 13-25 months
                            <br />and <span className='font-bold'>{mid_age_customer_count + new_age_customer_count}</span> customers are new, meaning they have less than 13 months of order history
                            {/* <br />and <span className='font-bold'>{new_age_customer_count}</span> customer(s) are very new, meaning they have less than 3 months of order history */}
                        </p>
                        {
                            !(long_age_customer_count === 0 && mid_age_customer_count && mid_mat_age_customer_count && new_age_customer_count) &&
                            <p>Below are the top customer(s) by volume for each of these categories:</p>
                        }
                        <div className='grid grid-cols-4 gap-1'>
                            {/* {
                                top_5_new_age_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>New age customer(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_new_age_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            } */}
                            {
                                [...top_5_mid_age_customers, ...top_5_new_age_customers].length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>New customer(s)</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {[...top_5_mid_age_customers, ...top_5_new_age_customers].map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_mid_mat_age_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Growing customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_mat_age_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_long_age_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Loyal customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_long_age_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>



                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT1']} tableName={"Product age"} />
                            <DemandGraph data={locationGraphData['LSEGMENT1']} tableName={"Location age"} />
                            <DemandGraph data={customerGraphData['CSEGMENT1']} tableName={"Customer age"} />
                        </div>
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <div className='flex gap-2 items-center  mb-2'>

                            <h3 className='text-base font-semibold text-[#4d8bf5]'>Product lifecycle management </h3>
                            <span onClick={() => setVisibleSections(prev => ({ ...prev, productLifecycle: true }))} className='cursor-pointer relative  transition-all duration-300 ease-in-out hover:text-[#4d8bf5]' >
                                <BsInfoCircleFill />
                                {
                                    visibleSections.productLifecycle &&
                                    <InsightsSection visibleSections={visibleSections} setVisibleSections={setVisibleSections} />
                                }
                            </span>
                        </div>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Discontinued</h6>
                        <p>This section highlights products, locations, and customers that are no longer active or have been phased out in the current year. It provides a snapshot of transitions that impact your portfolio. </p>
                        {phased_out_products.length === 0 && phased_out_customer.length === 0 && phased_out_location.length === 0 &&
                            <p>There are no product(s), location(s) or customer(s) which are phased out this year</p>
                        }
                        {
                            !(phased_out_products.length === 0 && phased_out_customer.length === 0 && phased_out_location.length === 0) &&
                            <div className='grid grid-cols-3 gap-2'>
                                {phased_out_products.length !== 0 &&
                                    <div className=''>
                                        <p>Product(s) that have been phased out this year:</p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                             {
                                                phased_out_products.length !== 0 && phased_out_products.map((data) => (
                                                    <p>{data['ITEM']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    phased_out_location.length !== 0 &&
                                    <div className=''>

                                        <p>Location(s) that have been phased out this year:</p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                             {
                                                phased_out_location.length !== 0 && phased_out_location.map((data) => (
                                                    <p>{data['LOCATION']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    phased_out_customer.length !== 0 &&
                                    <div className=''>

                                        <p>Customer(s) that have been phased out this year:</p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                             {
                                                phased_out_customer.length !== 0 && phased_out_customer.map((data) => (
                                                    <p>{data['CUSTOMER']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        }
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>New</h6>
                        <p>This section highlights products, locations, and customers that have been introduced in the current year. It provides insights into the additions to your portfolio and their potential impact on growth and operations.</p>


                        {new_products.length === 0 && new_location.length === 0 && new_customer.length === 0 &&
                            <p>There are no new product(s), location(s) or customer(s) which are added this year</p>
                        }
                        {
                            !(new_products.length === 0 && new_location.length === 0 && new_customer.length === 0) &&
                            <div className='grid grid-cols-3 gap-2'>
                                {
                                    new_products.length !== 0 &&
                                    <div>
                                        <p>Product(s) that have been added this year:</p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                            {
                                                new_products.length !== 0 && new_products.map((data) => (
                                                    <p>{data['ITEM']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    new_location.length !== 0 &&

                                    <div>
                                        <p>Location(s) that have been opened this year:</p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>
                                                {
                                                    new_location.length !== 0 && new_location.map((data) => (
                                                        <p>{data['LOCATION']}</p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    new_customer.length !== 0 &&

                                    <div>
                                        <p>Customer(s) that have been acquired this year:</p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>
                                                {
                                                    new_customer.length !== 0 && new_customer.map((data) => (
                                                        <p>{data['CUSTOMER']}</p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }


                    </div>


                </div>
            }
        </>
    )
}

export default Summary