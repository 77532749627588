import React, { useState, useRef } from 'react'
import { faSignal, faTachometer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterButton from '../filterButton';
import { FilterOutlined, DownloadOutlined, UploadOutlined, PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import BarGraph from './BarGraphPerformance';
import LineGraph from './LineGraphPerformance';
import performanceTableData from "../../data/performanceTable.json"
import Overlay from '../checkboxOverlay';
import InsightsCard from './InsightsCard';
import InsightsOverlay from './InsightsOverlay';
import { FiDownload, FiUpload } from 'react-icons/fi';
// import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { Select, Tag } from 'antd';
import PerformanceTable from './PerformanceTable';
import { TailSpin } from 'react-loader-spinner';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useBase } from '../Layout/baseContext/BaseContext';




const biasData = {
  Honda: {
    January: 10,
    February: -5,
    March: 2,
    April: 4,
    May: -3,
    June: 5,
    July: -2,
    August: 3,
    September: -1,
    October: 6,
    November: -4,
    December: 7
  },
  Audi: {
    January: -5,
    February: 8,
    March: -3,
    April: 6,
    May: -2,
    June: 4,
    July: -1,
    August: 7,
    September: -3,
    October: 5,
    November: -6,
    December: 8
  },
  Suzuki: {
    January: -2,
    February: 3,
    March: 5,
    April: -1,
    May: 2,
    June: 6,
    July: -3,
    August: 4,
    September: -2,
    October: 3,
    November: -4,
    December: 5
  }
};

const Performance = ({
  columnsShown,
  setColumnShown,
  selectedATTs,
  setSelectedATTs,
  calculateMetrics,
  performanceResult,
  lineGraphData,
  intervalType,
  setIntervalType,
  tableLoading,
  getAllPerformanceData,
  currentGraph,
  setCurrentGraph,
  errType,
  agg_level,
  setAgg_level,
  setErrType,
  uiMappingData,
  // getPerformanceData,
  performanceData,
  prefBarGrapLoading,
  tdateVar,
  startDate,
  setStartDate,
  intervalList,
  perFields,
  setPerFields,
  additionalFields,
  performanceLoading,
  locationFilters,
  productFilters,
  customerFilters
}) => {

  const [areaOptions] = useState([{
    "text": "Overview",
    "icon": <FontAwesomeIcon icon={faSignal} />
  },
  {
    "text": "KPI Insights",
    "icon": <FontAwesomeIcon icon={faTachometer} />
  }]);

  const [insightsColumns, setInsightColumns] = useState(new Map([
    ["Error by Segments", true],
    ["Products with High Variability", true],
    ["Error by Product Maturity", true],
    ["Top 5 Customers", true],
    ["Top 5 Locations", true],
    ["Top 5 Brands", false],
    ["Misc 1 option", false]
  ]))
  const [selectedArea, setSelectedArea] = useState(areaOptions[0]["text"] || null)
  const [tempAggLevel, setTempAggLevel] = useState(agg_level);

  const [checkBoxOverLay, setCheckBoxOverlay] = useState(false);

  const handleLevelChange = (value) => {
    const mandatoryFields = ["TDATE", "FDATE"];
    const newAggLevel = [...new Set([...mandatoryFields, ...value])];
    setTempAggLevel(newAggLevel);
  };
  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      // Update agg_level only when the dropdown is closed
      setAgg_level(tempAggLevel);
    }
  };

  const tempTableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tempTableRef.current,
    filename: 'Users table',
    sheet: 'Users'
  })
  const { perfPage } = useBase()

  if (perfPage === 0) {
    return (
      <h1>No Data</h1>
    )
  }
  // Custom tag render to keep placeholder always visible
  const customTagRender = () => {
    return (
      <p className='pl-2'>
        Aggregation Level
      </p>
    );
  };

  return (

    <>
      {
        performanceLoading || tableLoading ?
          <div className='h-full flex justify-center items-center'><TailSpin
            visible={true}
            height="40"
            width="40"
            color="#2B3674"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          /></div>
          :
          <div className=' space-y-2 w-full h-full flex flex-col overflow-scroll'>
            <div className='flex flex-row justify-start space-x-4 whitespace-nowrap'>
              <div className='flex flex-row w-fit bg-white shadow-sm rounded-full px-2 text-xs py-1.5'>
                {areaOptions.map((item) => {
                  return (
                    <div
                      className={`text-left flex flex-row items-center space-x-1 px-2 py-1 rounded-full transition-all ease-in-out duration-300 cursor-pointer  ${item["text"] === selectedArea ? 'text-white bg-blue-500 font-semibold' : 'text-gray-500 bg-white'}`}
                      onClick={() => setSelectedArea(item["text"])}
                    >
                      {selectedArea === item["text"] ? item["icon"] : null}<p>{item["text"]}</p>
                    </div>
                  )
                })}
              </div>
              <div className='flex flex-row items-center w-full justify-between text-sm'>
                <div className='flex flex-row items-center space-x-2'>
                  <p>Forecast values Y-axis</p>
                  <div className='flex gap-1 justify-between w-full'>
                    <Select
                      className='tdate-select'
                      defaultValue="ape"
                      placeholder="Error Type"
                      style={{
                        width: "8rem",
                        margin: "0",
                      }}
                      // value={formData.type}
                      onChange={(value) => { setErrType(value) }}
                      maxTagCount='responsive'
                      options={[
                        { value: 'ape', label: 'APE' },
                        { value: 'bias', label: 'Bias' },
                        { value: 'mape', label: 'MAPE' },
                      ]}
                    />
                  </div>
                  <p>and X-axis</p>
                  <div className='flex gap-1 justify-between w-full'>
                    <Select
                      className='tdate-select'
                      placeholder="FDATE"
                      style={{
                        width: "8rem",
                        margin: "0",
                      }}
                      // value={formData.type}
                      onChange={(value) => setIntervalType(value)}
                      maxTagCount='responsive'
                      options={[
                        { value: 'FDATE', label: 'FDATE' },
                        { value: 'TDATE', label: 'TDATE' },
                        // { value: 'FMONTH', label: 'FMONTH' },
                      ]}
                    />
                  </div>
                  <div className='flex gap-1 justify-between w-full'>
                    <Select
                      className='tdate-select'
                      placeholder="Aggregation Level"
                      style={{
                        width: "10rem",
                        margin: "0",
                      }}
                      mode='multiple'
                      maxTagCount='responsive'
                      options={[
                        { value: 'ITEM', label: 'ITEM' },
                        { value: 'LOCATION', label: 'LOCATION' },
                        { value: 'CUSTOMER', label: 'CUSTOMER' },
                        { value: 'TDATE', label: 'TDATE', disabled: true },
                        { value: 'FDATE', label: 'FDATE', disabled: true },
                      ]}
                      value={tempAggLevel}
                      onChange={(value) => handleLevelChange(value)}
                      onDropdownVisibleChange={handleDropdownVisibleChange}
                      tagRender={customTagRender} // Use custom render for tags
                    />
                  </div>
                </div>
                <div className='flex flex-row items-center space-x-4'>
                  <div onClick={onDownload} className="flex flex-row  items-center border border-slate-300 hover:bg-slate-100 p-2 rounded-full cursor-pointer text-slate-400 font-bold text-lg" data-tooltip-id="my-tooltip" data-tooltip-content="Download">
                    <FiDownload />
                  </div>
                  {
                    selectedArea === areaOptions[1]["text"] ?
                      <div className="flex flex-row space-x-2 items-center cursor-pointer" onClick={() => { setCheckBoxOverlay(true) }}>
                        <EditOutlined />
                        <p className='text-gray-700'>Edit</p>
                      </div> : null
                  }
                </div>
              </div>
            </div>
            {
              selectedArea === areaOptions[0]["text"] ?
                <>
                  <div className='flex flex-row space-x-3 max-h-72 h-72 w-full justify-between'>
                    {prefBarGrapLoading ?
                      <div className='h-72 w-full flex justify-center items-center'><TailSpin
                      visible={true}
                      height="40"
                      width="40"
                      color="#2B3674"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                    /></div>
                    :
                    <>
                    <BarGraph data={currentGraph} />
                    <LineGraph data={lineGraphData} />
                    </>
                  }
                  </div>
                  <PerformanceTable
                    columnsShown={columnsShown}
                    setColumnShown={setColumnShown}
                    selectedATTs={selectedATTs}
                    setSelectedATTs={setSelectedATTs}
                    calculateMetrics={calculateMetrics}
                    setCurrentGraph={setCurrentGraph}
                    performanceResult={performanceResult}
                    intervalType={intervalType}
                    errType={errType}
                    tempTableRef={tempTableRef}
                    uiMappingData={uiMappingData}
                    // getPerformanceData={getPerformanceData}
                    performanceData={performanceData}
                    tdateVar={tdateVar}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    intervalList={intervalList}
                    perFields={perFields}
                    setPerFields={setPerFields}
                    additionalFields={additionalFields}
                    performanceLoading={performanceLoading}
                    locationFilters={locationFilters}
                    productFilters={productFilters}
                    customerFilters={customerFilters}
                  />
                  {checkBoxOverLay === true ? <Overlay onSubmit={() => { }} onClose={() => setCheckBoxOverlay(false)} /> : null}
                </> :
                <div className='flex flex-col space-y-2'>
                  <div className='grid lg:grid-cols-4 grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-6 h-full'>
                    {[...insightsColumns.keys()].map((insight) => {
                      if (insightsColumns.get(insight) === true)
                        return <InsightsCard insightName={insight} />
                    })}
                  </div>
                  {checkBoxOverLay === true ? <InsightsOverlay insights={insightsColumns} setInsights={setInsightColumns} onSubmit={() => { }} onClose={() => setCheckBoxOverlay(false)} /> : null}
                </div>
            }
            <Tooltip id="my-tooltip" />
          </div>
      }
    </>
  )
}

export default Performance