import React, { useEffect, useState } from 'react';
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext';
import { FaArrowDownLong } from "react-icons/fa6";
import { Button, Pagination, message } from 'antd';
import DFUGraph from './DFUGraph';
import DFUForm from './DFUForm';
import api from '../../../api';

const DFUAlertsTable = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const {
        getDFUCorrectedHistory,
        tableHeaders,
        DFUTableData,
        setsortDFUParam,
        sortDFUParam,
        showDFUGraph,
        setShowDFUGraph,
        handleDFUResolveButton,
        DFUGraphIndex,
        selectedArea
    } = useAlert();

    const [formValues, setFormValues] = useState({
        changedQuantity: '',
        classification: '',
        eventCategory: '',
        eventName: '',
    });

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;

    // Paginated data
    const paginatedData = DFUTableData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    console.log("--------DFUTableData----in page----",DFUTableData);
    console.log("--------paginatedData----in page----",paginatedData);

    useEffect(() => {
        setCurrentPage(1)
    },[DFUTableData])

    const DFUResolveHandle = async () => {
        try {
            const formData = { ...formValues, DFU: showDFUGraph.dfu, SDATE: showDFUGraph.sdate };
            await api.post("/api/dfu-history-corrected/", formData);
            messageApi.success("Update successful");
            getDFUCorrectedHistory();
        } catch (e) {
            console.error(e);
        }
    };

    const DFUResolveHandleWithoutChanges = async () => {
        try {
            const formData = { changedQuantity: showDFUGraph.sqty_lfu, DFU: showDFUGraph.dfu, SDATE: showDFUGraph.sdate };
            await api.post("/api/dfu-history-corrected/", formData);
            messageApi.success("Update successful");
            getDFUCorrectedHistory();
        } catch (e) {
            console.error(e);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setShowDFUGraph(null); // Reset graph on page change
    };

    return (
        <div className='flex flex-col h-full'>
            <div className="relative h-full w-full ">
                <div className="absolute flex flex-col h-full w-full ">

                    <div className="scroll overflow-x-hidden shadow-col  overflow-y-scroll flex pr-2 flex-col h-full w-full">
                        {contextHolder}

                        <table className='w-full whitespace-nowrap text-xs shadow-col'>
                            <thead className='sticky top-0 z-10 bg-slate-100 shadow-class shadow-col border'>
                                <tr className='font-bold whitespace-normal border shadow-col'>
                                    {tableHeaders.map((item, index) => {
                                        if (item === "LFU") return null; // Skip rendering for LFU
                                        if (item === "User Resolved Quantity" && !["Partially Resolved", "Resolved Exceptions"].includes(selectedArea)) return null;

                                        if (item === "Action") {
                                            return (
                                                <td key={index} className='text-center px-3 py-1 border-gray-200'>
                                                    {item}
                                                </td>
                                            );
                                        }
                                        if (["Actuals", "Engine Resolved Quantity", "User Resolved Quantity"].includes(item)) {
                                            return (
                                                <td key={index} className='text-center px-3 py-1 border-gray-200'>
                                                    <div onClick={() => setsortDFUParam(item)} className={`flex gap-1 items-center cursor-pointer hover:text-indigo-500 ${sortDFUParam === item ? "text-indigo-500" : ""}`}>
                                                        <p>{item}</p>
                                                        <i><FaArrowDownLong /></i>
                                                    </div>
                                                </td>
                                            );
                                        }
                                        return (
                                            <td key={index} className='px-3 py-1 border-gray-200'>
                                                {item}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.map((item, index) => {
                                    if (item.STATUS === 0 || (item.STATUS === 1 && ["Partially Resolved", "Resolved Exceptions"].includes(selectedArea))) {
                                        const rowindex = (currentPage - 1) * itemsPerPage + index; // Calculate actual index
                                        return (
                                            <>
                                                <tr>
                                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{item.DFU}</td>
                                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{item.SDATE}</td>
                                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{Number.isInteger(item.SQTY_LFU) ? item.SQTY_LFU : Number(item.SQTY_LFU).toFixed(2)}</td>
                                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>
                                                        {Number.isInteger(item.SQTY_CORRECTED_ENGINE) ? item.SQTY_CORRECTED_ENGINE : Number(item.SQTY_CORRECTED_ENGINE).toFixed(2)}
                                                    </td>
                                                    {["Partially Resolved", "Resolved Exceptions"].includes(selectedArea) && (
                                                        <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>
                                                            {item.SQTY_CORRECTED_USER ? item.SQTY_CORRECTED_USER : 0}
                                                        </td>
                                                    )}                                        <td className={`text-center px-3 py-2 text-xs ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} text-blue-500 border border-gray-200 cursor-pointer`}>
                                                        <Button onClick={() => handleDFUResolveButton(item, index)} className='p-0 text-xs h-0 ' type='link'>Resolve</Button>
                                                    </td>
                                                </tr>
                                                {showDFUGraph !== null && showDFUGraph.dfu === item.DFU && DFUGraphIndex === index && (
                                                    <tr className={`${true ? "bg-indigo-50" : ""}`}>
                                                        <td colSpan={10000}>
                                                            <div className='flex flex-row  gap-2 p-1 py-3 w-full h-full'>
                                                                {/* <LineChart alertsGraphData={alertsGraphData} /> */}
                                                                <DFUGraph />
                                                                <div className='flex flex-col justify-between w-1/2 space-y-4 bg-white px-5 py-4 rounded-xl box-shadow'>
                                                                    <div className='flex flex-col space-y-4'>
                                                                        <h2 className='text-xl font-semibold text-bodyTextBlue'>Training Opportunity for {showDFUGraph.sdate}</h2>
                                                                        {/* <h5 className="text-slate-400">Current Selection shows 10343 units more than expected. The remaining unresolved quantity is 10361.</h5> */}
                                                                        <DFUForm formValues={formValues} setFormValues={setFormValues} />
                                                                        {/* <DynamicForm changed_quantity={["Vendor Sales"]} changed_reason={["Low Sales"]} /> */}
                                                                    </div>
                                                                    <div className='flex flex-col space-y-4 w-full'>
                                                                        <h3 className='font-semibold text-slate-400'>*The Resolution Quantity will be disaggregated based on underlying deviation quantities.</h3>
                                                                        <div className="flex flex-row items-center justify-between py-2 w-full ">
                                                                            <div>
                                                                                <Button onClick={() => DFUResolveHandleWithoutChanges()} className='border border-blue-500' type='link'>Resolve without any changes</Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button onClick={() => setShowDFUGraph(null)} type='link'>Cancel</Button>
                                                                                <Button className='' type='primary' onClick={() => DFUResolveHandle()}>Resolve</Button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )
                                    }

                                    return null;
                                })}
                            </tbody>
                        </table>

                        {/* Pagination */}
                    </div>
                </div>
            </div>
            <div className='mt-2 bg-slate-100 border border-slate-300 rounded-md p-1 max-w-fit'>
                <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={DFUTableData.length}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </div>
        </div>
    );
};

export default DFUAlertsTable;
