import React, { useEffect, useRef, useState } from 'react'
import { useBase } from '../../Layout/baseContext/BaseContext'
import { TailSpin } from 'react-loader-spinner';
import { Checkbox, Tooltip } from 'antd';
import { LuInfo } from "react-icons/lu";
import api from '../../../api';
import { FaAngleDown } from "react-icons/fa6";


const TaskListUI = () => {
    const { cellTaskData, setCellTaskData,
        taskLoading, setTaskLoading, showSidePanel } = useBase()

    const [info, setInfo] = useState(null)


    const extractDateTime = (datetimeString) => {
        const dateObj = new Date(datetimeString);

        // Extract date in YYYY-MM-DD format
        const datePart = dateObj.toISOString().split('T')[0];

        // Extract hour and minute in HH:MM format
        const hours = String(dateObj.getUTCHours()).padStart(2, '0');
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const timePart = `${hours}:${minutes}`;

        return { date: datePart, time: timePart };
    };

    const updateStatus = async (task_detail_id, is_completed) => {
        try {
            const result = api.post("api/taskStatus/", {
                TASK_DETAIL_ID: task_detail_id,
                IS_COMPLETED: is_completed
            })
        }
        catch (e) {
            console.log(e);
        }
    }
    return (
        <div className='w-full h-full flex flex-col'>
            <h3 className='text-sm font-semibold px-1 pb-1 flex items-center'>
                Task list
            </h3>
            <div className='h-full w-full'>
                {
                    taskLoading ?
                        <div className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"} flex justify-center items-center`}><TailSpin
                            visible={true}
                            height="20"
                            width="20"
                            color="#2B3674"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /></div>
                        :
                        <div className='w-full h-full flex flex-col'>
                            {
                                cellTaskData && cellTaskData.length === 0 ?
                                    <p className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"} flex text-slate-500 text rounded-md justify-center items-center pb-7`}>No tasks</p>
                                    :
                                    <div className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"}  relative h-full w-full`}>
                                        <div className='absolute h-full w-full'>


                                            <div className={`  h-full rounded-md flex flex-col gap-1 pb-1 overflow-y-scroll scroll w-full`}>



                                                {cellTaskData.map((item) => (
                                                    <div className="flex flex-col w-full border-b pb-1 relative">
                                                        <div className="flex justify-between items-center w-full">
                                                            {/* Checkbox with Tooltip */}
                                                            <Tooltip
                                                                title={item.IS_COMPLETED ? "Mark as incomplete" : "Mark as completed"}
                                                                placement="top"
                                                            >
                                                                <Checkbox
                                                                    key={item.TASK_DETAIL_ID}
                                                                    className=""
                                                                    onChange={(e) => updateStatus(item.TASK_DETAIL_ID, e.target.checked)}
                                                                    defaultChecked={item.IS_COMPLETED}
                                                                >
                                                                    {item.TASK_NAME}
                                                                    {item.DUE_DATE_TIME && (
                                                                        <div className="text-red-500 pr-1 text-[10px] leading-[0.5rem]">
                                                                            {extractDateTime(item.DUE_DATE_TIME).date} {extractDateTime(item.DUE_DATE_TIME).time}
                                                                        </div>
                                                                    )}
                                                                </Checkbox>
                                                            </Tooltip>
                                                            <div className="flex gap-2 items-center justify-end">
                                                                <p
                                                                    className={`rounded-lg px-2 py-[0.15rem] capitalize ${item.PRIORITY === "high"
                                                                            ? "bg-red-100 text-red-600"
                                                                            : item.PRIORITY === "medium"
                                                                                ? "bg-orange-100 text-orange-600"
                                                                                : item.PRIORITY === "low"
                                                                                    ? "bg-green-100 text-green-600"
                                                                                    : "bg-gray-200 text-gray-900"
                                                                        }`}
                                                                >
                                                                    {item.PRIORITY} priority
                                                                </p>
                                                                {/* Expand/Collapse Icon */}
                                                                <div
                                                                    onClick={() => setInfo((prev) => (prev === item.TASK_DETAIL_ID ? null : item.TASK_DETAIL_ID))}
                                                                    className="pr-2 text-slate-500 text-base hover:text-blue-500 cursor-pointer"
                                                                >
                                                                    <FaAngleDown />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Task Details */}
                                                        <div
                                                            className={`w-full flex flex-col transition-all duration-400 ease-in-out pl-6 overflow-hidden ${info === item.TASK_DETAIL_ID ? "max-h-[100px] overflow-y-scroll" : "max-h-0"
                                                                }`}
                                                        >
                                                            {item.TASK_DESC && (
                                                                <p>
                                                                    <span className="font-semibold">Description</span>: {item.TASK_DESC}
                                                                </p>
                                                            )}
                                                            {item.ASSIGNED_TO && (
                                                                <p>
                                                                    <span className="font-semibold">Assigned to</span>: {item.ASSIGNED_TO}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                }
            </div>
            {/* <Tooltip id="my-tooltip" /> */}

        </div>
    )
}

export default TaskListUI