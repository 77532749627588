import { Button } from 'antd';
import React from 'react'
import { IoAddOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineDeleteOutline } from "react-icons/md";

import { useBase } from '../baseContext/BaseContext';


const ProfilePopUp = () => {
    const { favs, handleShowDefaultView, setNewActiveFav, defaultView,deleteFav,delFavLoading,setIsFetchingPlan } = useBase();
    return (
        <div className="flex flex-col gap-2 absolute top-10 right-0 min-w-64 bg-white box-shadow rounded-lg p-2 text-bodyTextBlue">
            <div className='rounded-md border-b'>
                <div className='flex justify-between pl-3 pr-1 items-center py-1'>
                    <h1 className='text-base font-semibold'>{localStorage.getItem('username')}</h1>
                    <Button
                        className='p-2 h-6 mb-1 bg-[#ea4335] hover:!bg-red-600'
                        type='primary'
                        onClick={() => {
                            localStorage.clear(); // Clears all data from localStorage
                            window.location.reload(); // Reloads the page
                        }}
                    >
                        <span>Log out</span>
                    </Button>

                </div>
            </div>

            <div className='rounded-md bg-slate-100'>
                <div className='flex justify-between px-3 items-center border-b border-b-slate-300 py-1'>
                    <h1 className='text-base font-semibold'>Favourites</h1>
                    {/* <Button className='p-0 h-0' type='link'>
                        <span className='!p-0 !m-0'><IoAddOutline /></span>
                        <span>Add</span>
                    </Button> */}
                </div>
                <ul className='text-sm p-1'>
                    {
                        favs.length === 0 ?
                            <div className='flex justify-center items-center py-5'>
                                <p className='text-slate-400'>No Favourites</p>
                            </div>

                            :
                            <>

                                <li onClick={(() => handleShowDefaultView())} className='flex p-2 mb-1 rounded-md gap-2 items-center capitalize cursor-pointer text-slate-500 hover:bg-white bg-slate-200'>
                                    <p className='text-green-500'>{defaultView ? <FaCheck /> : ""}</p>
                                    <p>Default View</p>
                                </li>
                                {

                                    favs.map((item, index) => (
                                        <li  key={index} className='flex rounded-md justify-between items-center gap-1 capitalize  text-slate-500 '>
                                            <div className='flex gap-2 hover:bg-white cursor-pointer p-2 w-full rounded-md items-center' onClick={() => setNewActiveFav(item.id)}>
                                                <p className='text-green-500'>{item.ISACTIVE && !defaultView ? <FaCheck /> : ""}</p>
                                                <p>{item.NAME}</p>
                                            </div>
                                            <Button loading={delFavLoading === item.id} type='link' onClick={() => deleteFav(item)}  className='text-[#ea4335] min-w-9 hover:!bg-red-100 hover:!text-[#ea4335] p-2 rounded-md cursor-pointer'>
                                                {
                                                    !(delFavLoading === item.id)  &&
                                                    <MdOutlineDeleteOutline/>
                                                }
                                            </Button>
                                        </li>
                                    ))
                                }
                            </>
                    }

                </ul>
            </div>
            {/* <div className='rounded-md bg-slate-100'>
                <div className='flex justify-between px-3 items-center border-b border-b-slate-300 py-1'>
                    <h1 className='text-base font-semibold'>Favourites</h1>
                    <Button className='p-0 h-0' type='link'>
                        <span className='!p-0 !m-0'><IoAddOutline /></span>
                        <span>Add</span>
                    </Button>
                </div>
                <ul className='text-sm p-1'>
                    {
                        favs.length === 0 ?
                        <div className='flex justify-center items-center py-5'>
                            <p className='text-slate-400'>No Favourites</p>
                        </div>
                    
                    :
                        favs.map((item, index) => (
                            <li key={index} className='flex px-2 rounded-md justify-between items-center py-1 capitalize cursor-pointer text-slate-500 hover:bg-white'>
                                <p>{item.NAME}</p>
                                <p className='text-green-500'>{item.ISACTIVE ? <FaCheck/> : ""}</p>
                            </li>
                        ))
                    }
                  
                </ul>
            </div> */}
        </div>
    )
}

export default ProfilePopUp